import { getFieldsForCard } from 'core/components/InfoPanel'
import React from 'react'
import { renderDetailRow } from './entity-info-card'
import Text from 'core/elements/Text'
import { isNilOrEmpty } from 'utils/fp'
import { formatDate, durationBetweenDates } from 'utils/misc'
import { BadgeVariant } from 'pf9-ui-components/built/elements/badge/Badge'

export const getRow = (fields, item, classNames = {}, wrap = false) => {
  const selectedFields = getFieldsForCard(fields, item)
  return renderDetailRow(selectedFields, classNames, wrap)
}

export const getRows = (fields, item, classNames = {}) => ({
  left: getRow(fields.left, item, classNames, fields.wrap),
  right: getRow(fields.right, item, classNames, fields.wrap),
})

export const renderAge = (age, entity) => {
  if (!age || isNilOrEmpty(entity)) return ''
  return (
    <>
      <Text variant="caption1">{age}</Text>
      <Text variant="body2">{`(${formatDate(entity?.creationTimestamp)})`}</Text>
    </>
  )
}

export const renderAgeFromTimestamp = (timestamp, entity) => {
  if (!timestamp || isNilOrEmpty(entity)) return ''
  const age = durationBetweenDates({ labels: ['d'] })(timestamp)
  return (
    <>
      <Text variant="caption1">{age}</Text>
      <Text variant="body2">{`(${formatDate(timestamp)})`}</Text>
    </>
  )
}

export const convertCpuUsageValueToCores = (usageValue) => {
  if (!usageValue) return 0

  if (usageValue.includes('n')) {
    // Convert usage value from nanocores to cores
    return parseInt(usageValue) / 1_000_000_000 // 1 core = 1,000,000,000 nanocores
  } else if (usageValue.includes('u')) {
    // Convert usage value from microcores to cores
    return parseInt(usageValue) / 1_000_000 // 1 core = 1,000,000 microcores
  } else if (usageValue.includes('m')) {
    // Convert usage value from millicores to cores
    return parseInt(usageValue) / 1_000 // 1 core = 1,000 millicores
  }

  // Return as-is if already in cores
  return parseInt(usageValue)
}

const convertCpuUsageValue = (usageValue) => {
  if (!usageValue) return 0
  if (usageValue.includes('n')) {
    // Convert usage value from nanocores to millicores
    return parseInt(usageValue) * 0.000001
  }
  return parseInt(usageValue)
}

const convertMemoryUsageValue = (usageValue) => {
  if (!usageValue) return 0
  if (usageValue.includes('Ki')) {
    // Convert usage value from Ki to Mi
    return parseInt(usageValue) / 1024
  }
  return parseInt(usageValue)
}

export const getContainerCpuUsage = (value) => {
  if (!value) return 'N/A'
  return `${convertCpuUsageValue(value).toFixed(2)}m`
}

export const getContainerStatus = (state) => {
  if (isNilOrEmpty(state)) return { variant: 'unknown' as const, label: 'Unknown' }
  let status: BadgeVariant = 'success'

  switch (state) {
    case 'Terminated':
      status = 'error'
      break
    case 'Waiting':
      status = 'unknown'
      break
    case 'Pending':
      break
  }
  return { variant: status }
}

export const getContainerMemoryUsage = (value) => {
  if (!value) return 'N/A'
  return `${convertMemoryUsageValue(value).toFixed(2)}m`
}
