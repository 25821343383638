import React, { useState } from 'react'
import useReactRouter from 'use-react-router'
import { useSelector } from 'react-redux'
import { isNilOrEmpty } from 'utils/fp'
import SimpleLink from 'core/components/SimpleLink'
import useListAction from 'core/hooks/useListAction'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { routes } from 'core/utils/routes'
import EllipsisText from '../common/EllipsisText'
import { listKaapiClusters } from '../kaapi/clusters/actions'
import { kaapiClustersOverviewSelector } from '../kaapi/clusters/selectors'
import EditClusterNodeGroupsModal from './EditClusterNodeGroupsModal'
import { listTablePrefs } from 'app/constants'
import DocumentMeta from 'core/components/DocumentMeta'
import { createGridStatusCell, StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'
import Grid, { GridViewColumn } from 'core/elements/grid/Grid'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { IMachineDeploymentSelector } from '../kaapi/machine-deployment/model'

export type NodeGroupSelector = IMachineDeploymentSelector

const usePrefParams = createUsePrefParamsHook('Machine Deployments', listTablePrefs)

const Phase = {
  Provisioning: 'Provisioning',
  Provisioned: 'Provisioned',
  Running: 'Running',
  Failed: 'Failed',
  ScalingUp: 'ScalingUp',
  ScalingDown: 'ScalingDown',
  Deleting: 'Deleting',
  Unknown: 'Unknown',
}

const getPhaseStatus = (phase): StatusCellModel => {
  switch (phase) {
    case Phase.Running:
    case Phase.Provisioned:
      return { variant: 'success', label: phase }
    case Phase.ScalingUp:
    case Phase.ScalingDown:
    case Phase.Provisioning:
      return { variant: 'warning', label: phase }
    case Phase.Failed:
    case Phase.Deleting:
      return { variant: 'error', label: phase }
    default:
      return { variant: 'unknown', label: Phase.Unknown }
  }
}
const cellFormatFn = (value) => (isNilOrEmpty(value) ? '-' : value)
const tableColumnWidth = 200
export default function ListClusterNodeGroups() {
  const { history, match } = useReactRouter()
  const { cluster } = match.params
  const [selectedGroup, setSelectedGroup] = useState()
  const [showEditGroupModal, setShowEditGroupModal] = useState<boolean>(false)
  const columns: GridViewColumn<any>[] = [
    {
      key: 'matadata.name',
      label: 'Name',
      width: tableColumnWidth,
      CellComponent: (props) => {
        return (
          <EllipsisText
            title={props?.item?.metadata?.name}
            maxWidth={200}
            showTooltip
            variant="body2"
          >
            <SimpleLink
              src=""
              onClick={() =>
                history.push(routes.kubernetes.manage.capacityAndHealth.path({ cluster }))
              }
            >
              {props?.item?.metadata?.name}
            </SimpleLink>
          </EllipsisText>
        )
      },
    },
    {
      key: 'status.phase',
      label: 'Status',
      CellComponent: createGridStatusCell({
        dataFn: (phase): StatusCellModel => {
          return getPhaseStatus(phase)
        },
      }),
    },
    {
      key: 'nodes',
      label: 'Nodes',
    },
    {
      key: 'strategy',
      label: 'Strategy',
    },
    {
      key: 'maxSurge',
      label: 'Max Surge Type',
    },
    {
      key: 'maxSurgeValue',
      label: 'Max Surge Value',
      formatFn: cellFormatFn,
    },
    {
      key: 'maxUnavailable',
      label: 'Max Unavailable Type',
      formatFn: cellFormatFn,
    },
    {
      key: 'maxUnavailableValue',
      label: 'Max Unavailable Value',
      formatFn: cellFormatFn,
    },
  ]
  const rowMenuItems = [
    {
      label: '',
      icon: 'edit',
      width: 50,
      handleClick: (props) => {
        setSelectedGroup(props)
        setShowEditGroupModal(true)
      },
      refreshAfterSuccess: true,
    },
    // {
    //   label: 'Delete',
    //   icon: 'trash-alt',
    //   BatchActionButton: getGridDialogButton(DeleteNodeGroupsDialog),
    // },
  ]

  // Fetch machine deployments
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences('k8sPluginGlobalParams')
  const { loading: loadingKaapiClusters, reload: reloadKaapiClusters } = useListAction(
    listKaapiClusters,
  )
  const clusters = useSelector(kaapiClustersOverviewSelector)
  const clusterData = clusters?.find((clusterData) => clusterData.metadata.name === cluster)
  const machineDeployments = clusterData?.machineDeployments || []

  return (
    <>
      <DocumentMeta title="Capi Node Groups Overview" />
      <div>
        {showEditGroupModal && (
          <EditClusterNodeGroupsModal
            onClose={() => setShowEditGroupModal(false)}
            rows={[selectedGroup]}
            onUpgradeScreen={true}
          />
        )}
        <Grid<any, any>
          uniqueIdentifier="uid"
          loading={loadingKaapiClusters}
          data={machineDeployments}
          columns={columns}
          rowMenuItems={rowMenuItems}
          disableToolbar
          disableRowSelection={true}
        />
      </div>
    </>
  )
}
