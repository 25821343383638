import { Selector } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { INamespaceSelector } from './model'

export const namespacesSelector: Selector<RootState, INamespaceSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.Namespaces>(DataKeys.Namespaces),
  (rawNamespaces) => {
    return rawNamespaces.map((ns) => {
      return {
        ...ns,
        id: ns.metadata.uid,
        clusterName: null,
        name: ns.metadata.name
      }
    })
  },
)
