import React from 'react'
import ExternalLink from 'core/components/ExternalLink'
import { DOCUMENT_LINKS } from 'k8s/components/constants'

/**
 * @summary Custom hook to return matching image for k8s version
 * @param k8sVersion
 * @param setError
 * @param images
 * @returns matching image for provided k8s version OR null
 */
export const getK8sImage = ({ k8sVersion, setError, images }) => {
  // If k8sVersion not found return null
  if (!k8sVersion) {
    setError({
      title: 'Error creating cluster',
      message: 'Kubernetes version not available',
    })
    return null
  }

  // Only take major and minor version Eg. If 1.30.11 take 1.30
  const formattedk8sVersion = k8sVersion
    .split('.')
    .slice(0, 2)
    .join('.')

  const matchingImage = images?.find(
    (image) => image?.status === 'active' && image?.properties?.k8s_version === formattedk8sVersion,
  )

  // If matching image not found return null
  if (!matchingImage) {
    setError({
      title: 'Error creating cluster',
      message: (
        <>
          Image not available for selected Kubernetes version.{' '}
          <ExternalLink url={DOCUMENT_LINKS.K8S_IMAGE_MANAGEMENT}>Read More</ExternalLink>
        </>
      ),
    })
    return null
  }

  return matchingImage?.name
}
