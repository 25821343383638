import Card from 'core/elements/card'
import React, { FC } from 'react'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

export const Row = ({ label, value, lineClamp = undefined, ...rest }) => {
  const classes = useStyles()
  return (
    <div className={classes.row} {...rest}>
      <Text className={classes.rowHeader} variant="body2" component="span">
        {label}
      </Text>
      <Text
        className={classes.rowValue}
        variant="caption1"
        component={typeof value === 'string' ? 'span' : 'div'}
        lineClamp={lineClamp}
      >
        {value}
      </Text>
    </div>
  )
}

interface SummaryCardProps<T, Key> {
  title: string
  rows: SummaryRow<Key>[]
  data: T
  subtitle?: string
}

interface SummaryRow<Key> {
  key: Key
  label: string
}

export default function SummaryCard<T, Key extends keyof T = keyof T>({
  rows,
  title,
  subtitle,
  data,
  ...rest
}: SummaryCardProps<T, Key>) {
  const classes = useStyles()
  return (
    <Card withCustomBody {...rest}>
      <Text variant="subtitle2" className={classes.title}>
        {title}
      </Text>
      <Text variant="body2" className={classes.subtitle}>
        {subtitle}
      </Text>
      <div className={classes.rowContainer}>
        {rows?.map(({ key, label }, index) => (
          <React.Fragment key={index}>
            <Row label={label} value={data[key]} />
            {index !== rows?.length - 1 ? <div className={classes.divider}></div> : null}
          </React.Fragment>
        ))}
      </div>
    </Card>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    border: 'none',
    borderLeft: `1px dotted ${theme.components.input.frame.border}`,
    height: 50,
  },
  rowHeader: {
    display: 'flex',
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  rowContainer: {
    display: 'flex',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    justifyContent: 'space-around',
    background: theme.components.frame.background,
    borderRadius: theme.spacing(0.5),
  },

  row: {
    display: 'flex',
    flexFlow: 'column wrap',
    gap: theme.spacing(1),
  },
  rowValue: {
    marginLeft: theme.spacing(0.5),
    wordBreak: 'break-all',
    display: 'flex',
    gap: '4px',
    maxWidth: '400px',
  },

  title: {
    margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },

  subtitle: {
    margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
}))
