import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { findCluster } from 'k8s/util/helpers'
import {
  ICustomResourceDefinitionSelector,
  CustomResourceDefinitionVersion,
  ICustomResourcesSelector,
} from './model'
import { last } from 'ramda'
import { durationBetweenDates } from 'utils/misc'

export const customResourceDefinitionsSelector = createSharedSelector(
  getDataSelector<DataKeys.CustomResourceDefinitions>(
    DataKeys.CustomResourceDefinitions,
    ['clusterId'],
    ['clusterId'],
  ),
  getDataSelector<DataKeys.KaapiClusters>(DataKeys.KaapiClusters),
  (rawCustomResourceDefinitions, allClusters): ICustomResourceDefinitionSelector[] => {
    return rawCustomResourceDefinitions?.map((rawCustomResourceDefinition) => {
      const { clusterId, metadata, spec } = rawCustomResourceDefinition
      const clusterData = findCluster(allClusters, clusterId)
      return {
        ...rawCustomResourceDefinition,
        clusterName: clusterData?.metadata?.name,
        group: spec?.group,
        scope: spec?.scope,
        namespace: metadata?.namespace,
        latestVersion: last(spec?.versions as Array<CustomResourceDefinitionVersion>)?.name,
        creationTimestamp: metadata?.creationTimestamp,
        annotations: metadata?.annotations,
        labels: metadata?.labels,
        age: durationBetweenDates({ labels: ['d'] })(metadata?.creationTimestamp),
      }
    })
  },
)

export const createGroupOptionsSelector = createSharedSelector(
  getDataSelector<DataKeys.CustomResourceDefinitions>(
    DataKeys.CustomResourceDefinitions,
    ['clusterId'],
    ['clusterId'],
  ),
  (rawCustomResourceDefinitions): Array<{ label: string; value: string }> => {
    const distinctGroups = Array.from(
      new Set(rawCustomResourceDefinitions?.flatMap(({ spec }) => spec.group)),
    )

    const groupByOptions: Array<{ label: string; value: string }> = distinctGroups.map(
      (group: string) => {
        return {
          label: group,
          value: group,
        }
      },
    )

    return groupByOptions
  },
)

export const customResourcesSelector = createSharedSelector(
  getDataSelector<DataKeys.CustomResources>(DataKeys.CustomResources),
  getDataSelector<DataKeys.KaapiClusters>(DataKeys.KaapiClusters),
  (rawCustomResources, allClusters): ICustomResourcesSelector[] => {
    return rawCustomResources.map((rawCustomResource) => {
      const { clusterId, metadata, status, group, version, pluralName, id } = rawCustomResource
      const clusterData = findCluster(allClusters, clusterId)
      return {
        ...rawCustomResource,
        clusterName: clusterData?.metadata?.name,
        group,
        version,
        pluralName,
        id,
        phase: status?.phase,
        namespace: metadata?.namespace || 'default',
        creationTimestamp: metadata?.creationTimestamp,
        labels: metadata?.labels,
        age: durationBetweenDates({ labels: ['d'] })(metadata?.creationTimestamp),
      }
    })
  },
)
