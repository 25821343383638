import React, { useEffect, useState, useCallback } from 'react'
import { assoc } from 'ramda'
import { makeStyles } from '@material-ui/styles'

import ModalForm from 'core/elements/modal/ModalForm'
import { IConfigMapSelector } from 'k8s/components/resources/config-maps/selectors'
import TextField from 'core/components/validatedForm/TextField'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import Theme from 'core/themes/model'
import CopyToClipboard from 'core/components/CopyToClipboard'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { updateConfigMap } from 'k8s/components/resources/config-maps/actions'
import IconButton from 'core/elements/button/IconButton'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import { encodeStr, decodeStr } from 'utils/misc'

type Props = {
  open: boolean
  onClose: () => void
  configMapData: { key: string; value: string; type?: string }
  configMap: IConfigMapSelector
}

export default function ConfigMapsDataInfoModal({
  configMap,
  configMapData,
  open = false,
  onClose,
}: Props) {
  const classes = useStyles()
  const { update, updating: updatingConfigMap } = useUpdateAction(updateConfigMap)

  const [enableEdit, setEnableEdit] = useState(false)

  const [encoded, setEncoded] = useState(true)
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(configMapData?.value)
    setEnableEdit(false)
  }, [open])

  useEffect(() => {
    if (configMapData?.type === 'data') setEncoded(false)
    else if (configMapData?.type === 'binaryData') setEncoded(true)
  }, [configMapData?.type])

  const toggleEncode = () => {
    if (encoded) {
      setValue(decodeStr(value))
      setEncoded(false)
    } else {
      setValue(encodeStr(value))
      setEncoded(true)
    }
  }

  const handleConfigMapDataEdit = useCallback(async () => {
    const ops = []

    if (configMapData?.type === 'data') {
      const newValue = encoded ? decodeStr(value) : value
      const newData = assoc(configMapData?.key, newValue, configMap?.data)

      ops.push({
        op: 'replace',
        path: '/data',
        value: newData,
      })
    } else if (configMapData?.type === 'binaryData') {
      const newBinaryValue = encoded ? value : encodeStr(value)
      const newBinaryData = assoc(configMapData?.key, newBinaryValue, configMap?.binaryData)

      ops.push({
        op: 'replace',
        path: '/binaryData',
        value: newBinaryData,
      })
    }

    const { success } = await update({
      ...configMap,
      body: ops,
      requestType: 'patch',
      contentType: 'application/json-patch+json',
    })
    if (success) onClose()
  }, [enableEdit, encoded, value])

  return (
    <ModalForm
      open={open}
      title={`Key (${configMapData?.key})`}
      onClose={onClose}
      onSubmit={enableEdit ? handleConfigMapDataEdit : null}
      submitTitle={`Save Changes`}
      submitting={updatingConfigMap}
    >
      <div className={classes.container}>
        <CopyToClipboard copyText={configMapData.key} inline codeBlock={false}>
          <TextField id="keyInput" label="Key" value={configMapData.key} />
        </CopyToClipboard>

        <CodeMirror
          variant="light"
          label="Value"
          className={classes.codeMirror}
          id="value"
          showSearchBar
          showCopyButton
          options={{
            mode: null,
            lineNumbers: false,
            lineWrapping: true,
            readOnly: enableEdit ? false : true,
            cursorHeight: enableEdit ? 1 : 0,
          }}
          value={value}
          onChange={(val) => setValue(val)}
          extraActions={
            <>
              <ToggleSwitch
                // className={classes.switch}
                label="Edit"
                active={enableEdit}
                onClick={(active) => setEnableEdit(active)}
                disabled={configMap?.immutable}
              />
              <IconButton
                key="encodeDecode"
                icon={encoded ? 'eye-slash' : 'eye'}
                // color="inherit"
                onClick={toggleEncode}
              />
            </>
          }
        />
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  codeMirror: {
    '& .CodeMirror': {
      maxHeight: 'calc(100vh - 400px)',
      margin: theme.spacing(2),
      width: '712px',
    },
  },
}))
