import { createSelector } from '@reduxjs/toolkit'
import { allKey } from 'app/constants'
import { AppSelector } from 'app/store'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector, selectParamsFromProps } from 'core/utils/selectorHelpers'
import { persistentVolumeSelector } from 'k8s/components/resources/persistent-volume/selectors'
import DataKeys from 'k8s/DataKeys'
import { findClusterName } from 'k8s/util/helpers'
import { pipe, propEq } from 'ramda'
import { arrayIfEmpty, emptyArr, filterIf } from 'utils/fp'
import { durationBetweenDates } from 'utils/misc'
import { IPersistentVolumeClaimsSelector } from './model'
// import { podsByClusterIdAndNamespaceSelector } from 'k8s/components/pods/selectors'
// import { getPodsMounted } from './helpers'

export const persistentVolumeClaimsSelector: AppSelector<IPersistentVolumeClaimsSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.PersistentVolumeClaims>(DataKeys.PersistentVolumeClaims),
  persistentVolumeSelector,
  getDataSelector<DataKeys.KaapiClusters>(DataKeys.KaapiClusters),
  getDataSelector<DataKeys.StorageClasses>(DataKeys.StorageClasses),
  // podsByClusterIdAndNamespaceSelector,
  (rawPvcs, persistentVolumes, allKaapiClusters, storageClasses) => {
    const mappedPersistentVolumes = persistentVolumes.reduce((accum, pv) => {
      const claimRef = pv?.spec?.claimRef
      return {
        ...accum,
        [claimRef?.uid]: pv,
      }
    }, {})
    return rawPvcs.map((pvc) => {
      const { clusterId, namespace } = pvc
      // const associatedPods = podsByClusterIdAndNamespace?.[clusterId]?.[namespace] || emptyArr
      const storageClassName = pvc?.spec?.storageClassName
      const storageClass = storageClasses.find((sc) => sc.name === storageClassName)
      const creationTimestamp = pvc?.metadata?.creationTimestamp
      return {
        ...pvc,
        // podsMounted: getPodsMounted(pvc, associatedPods),
        labels: pvc?.metadata?.labels,
        accessModes: pvc?.spec?.accessModes,
        storageClassName,
        storageClass,
        volume: pvc?.spec?.volumeName,
        capacity: pvc?.status?.capacity,
        clusterName: findClusterName(allKaapiClusters, pvc.clusterId),
        type: pvc?.parameters?.type,
        creationTimestamp,
        age: durationBetweenDates({ labels: ['d'] })(creationTimestamp),
        persistentVolume: mappedPersistentVolumes[pvc?.id],
      }
    })
  },
)

export const makePersistentVolumeClaimSelector = (
  defaultParams = {} as SortConfig & { clusterId?: string; namespace?: string },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(persistentVolumeClaimsSelector, selectParams, (pvcs, params) => {
    const { clusterId, namespace, orderBy, orderDirection } = params
    return pipe<
      IPersistentVolumeClaimsSelector[],
      IPersistentVolumeClaimsSelector[],
      IPersistentVolumeClaimsSelector[],
      IPersistentVolumeClaimsSelector[],
      IPersistentVolumeClaimsSelector[]
    >(
      filterIf(clusterId && clusterId !== allKey, propEq('clusterId', clusterId)),
      filterIf(namespace && namespace !== allKey, propEq('namespace', namespace)),
      createSorter({ orderBy, orderDirection }),
      arrayIfEmpty,
    )(pvcs)
  })
}

export const pvcByPVSelector: AppSelector<Map<
  string,
  IPersistentVolumeClaimsSelector[]
>> = createSharedSelector(persistentVolumeClaimsSelector, (pvcs) => {
  const pvcByPv = new Map<string, IPersistentVolumeClaimsSelector[]>()
  for (const pvc of pvcs) {
    const volumeName = pvc?.spec?.volumeName
    if (volumeName) {
      const current = [...(pvcByPv.get(volumeName) || []), pvc]
      pvcByPv.set(volumeName, current)
    }
  }
  return pvcByPv
})
