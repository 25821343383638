import { allKey } from 'app/constants'
import { useAppSelector } from 'app/store'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import useListAction from 'core/hooks/useListAction'
import { listClusterRoles } from 'k8s/components/access-control/cluster-roles/actions'
import { clusterRolesSelector } from 'k8s/components/access-control/cluster-roles/selectors'
import { listRoles } from 'k8s/components/access-control/roles/actions'
import { rolesSelector } from 'k8s/components/access-control/roles/selectors'
import { prop, uniqBy } from 'ramda'
import React, { useMemo } from 'react'
import { projectAs } from 'utils/fp'

interface Props {
  clusterId?: string
  namespace?: string
  showAllRoleTypes?: boolean
}

export default function RolePicklist({
  clusterId = allKey,
  namespace = allKey,
  loading,
  onChange,
  showAllRoleTypes,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const { loading: rolesLoading } = useListAction(listRoles, {
    params: { clusterId, namespace },
    requiredParams: ['clusterId', 'namespace'],
  })
  const roles = useAppSelector(rolesSelector)

  const { loading: clusterRolesLoading } = useListAction(listClusterRoles, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })
  const clusterRoles = useAppSelector(clusterRolesSelector)

  const items = useMemo(() => {
    const _roles = projectAs(
      { label: 'pickerLabel', value: 'pickerValue' },
      uniqBy(prop('pickerValue'), roles),
    )
    const _clusterRoles = projectAs(
      { label: 'pickerLabel', value: 'pickerValue' },
      uniqBy(prop('pickerValue'), clusterRoles),
    )
    return showAllRoleTypes ? [..._roles, ..._clusterRoles] : _clusterRoles
  }, [roles, clusterRoles])

  return (
    <AsyncDropdown
      name="roleId"
      label="Role"
      selectFirst
      onChange={onChange}
      loading={loading || rolesLoading || clusterRolesLoading}
      items={items}
      {...rest}
    />
  )
}
