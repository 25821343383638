import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import DeleteAction from 'core/actions/DeleteAction'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'

const { kubernetes } = ApiClient.getInstance()

export const kaapiMachineActions = ActionsSet.make<DataKeys.KaapiMachines>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.KaapiMachines],
  cacheKey: DataKeys.KaapiMachines,
})

export const deleteKaapiMachines = kaapiMachineActions.add(
  new DeleteAction<DataKeys.KaapiMachines, { namespace: string; name: string }>(
    async ({ namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Kaapi Machines', { namespace, name })
      await kubernetes.deleteKaapiMachines({ namespace, name })
    },
  ),
)

export const listKaapiMachines = kaapiMachineActions.add(
  new ListAction<DataKeys.KaapiMachines>(async ({ namespace }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Kaapi Machines ', { namespace })
    return kubernetes.getMachines({ namespace })
  })
    .addDependency(DataKeys.KaapiMachineDeployments)
    .addDependency(DataKeys.KaapiNodes)
    .addDependency(DataKeys.KaapiMetricsNodes),
)
