import React, { useMemo } from 'react'
import Alert from 'core/components/Alert'
import NumberedStepLabel from 'core/components/validatedForm/NumberedStepLabel'
import CodeBlock from 'core/components/CodeBlock'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Text from 'core/elements/Text'
import CopyToClipboard from 'core/components/CopyToClipboard'
import { sessionStoreKey, SessionState } from 'core/session/sessionReducers'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { listUserTenants } from 'account/components/userManagement/tenants/new-actions'
import useListAction from 'core/hooks/useListAction'
import { userTenantsSelector } from 'account/components/userManagement/tenants/selectors'
import { getDomainName } from 'api-client/helpers'

export default function AddBYOHHosts() {
  const classes = useStyles()
  const selectSessionState = prop<string, SessionState>(sessionStoreKey)
  const session = useSelector(selectSessionState)
  const {
    features: { dex_client_secret },
  } = session
  const { getUserPrefs } = useScopedPreferences()
  const { currentTenant, currentTenantName } = getUserPrefs(session?.username)
  const fqdn = window.location.origin

  const { loading: loadingTenants } = useListAction(listUserTenants)
  const tenants = useSelector(userTenantsSelector)
  const tenant = useMemo(() => tenants.find((tenant) => tenant.id === currentTenant), [tenants])
  const domainName = getDomainName()

  return (
    <div className={classes.root}>
      <Text variant="h3">Add a New BYOH Host</Text>
      <div className={classes.section}>
        {/* <Text variant="subtitle2">Add a New BYOH Host</Text> */}
        <Alert
          title="In order to add your hosts, follow the steps below:"
          variant="primary"
          message={
            <div className={classes.tutorial}>
              <NumberedStepLabel
                step={1}
                title="Login to your host terminal"
                className={classes.tutorialStep}
              />
              <NumberedStepLabel
                step={2}
                title="Install the host agent by invoking below commands as root user:"
                className={classes.tutorialStep}
              />
              <div className={classes.codeBlocks}>
                <CopyToClipboard
                  copyText={`bash <(curl -s https://byohctl.s3.us-west-2.amazonaws.com/byohctl-setup)`}
                >
                  <CodeBlock>{`bash <(curl -s https://byohctl.s3.us-west-2.amazonaws.com/byohctl-setup)`}</CodeBlock>
                </CopyToClipboard>
                <br />
                <CopyToClipboard
                  copyText={`sudo byohctl onboard -f ${fqdn} -d ${domainName} -u ${session?.username} -p <password> --client-token ${dex_client_secret} -t ${currentTenantName}`}
                >
                  <CodeBlock>{`sudo byohctl onboard -f ${fqdn} -d ${domainName} -u ${session?.username} -p <password> --client-token ${dex_client_secret} -t ${currentTenantName}`}</CodeBlock>
                </CopyToClipboard>
                <br />
                <Text variant="caption1">{`Replace the <password> with your password`}</Text>
              </div>
              <br />
              <Text variant="body2">Here is your info for quick use:</Text>
              <Text component="div" variant="body2">
                Account URL:{' '}
                <CopyToClipboard copyText={window.location.origin} codeBlock={false}>
                  <span className={classes.linkText}>{window.location.origin}</span>
                </CopyToClipboard>
              </Text>
              <Text component="div" variant="body2">
                Username:{' '}
                <CopyToClipboard copyText={session?.username} codeBlock={false}>
                  <span className={classes.linkText}>{session?.username}</span>
                </CopyToClipboard>
              </Text>
              <Text component="div" variant="body2">
                Domain:{' '}
                <CopyToClipboard copyText={domainName} codeBlock={false}>
                  <span className={classes.linkText}>{domainName}</span>
                </CopyToClipboard>
              </Text>
              <Text component="div" variant="body2">
                Tenant:{' '}
                <CopyToClipboard copyText={tenant?.name} codeBlock={false}>
                  <span className={classes.linkText}>{tenant?.name}</span>
                </CopyToClipboard>
              </Text>
              <Text component="div" variant="body2">
                client-token:{' '}
                <CopyToClipboard copyText={dex_client_secret} codeBlock={false}>
                  <span className={classes.linkText}>{dex_client_secret}</span>
                </CopyToClipboard>
              </Text>
            </div>
          }
        />
        {/* <Text variant="body2">
          Once the host agent is installed, you will see the host in your list of{' '}
          <SimpleLink src={routes.openstack.hosts.path()}>Cluster Hosts</SimpleLink> waiting to be
          assigned a role.
        </Text> */}
        <Text variant="body2">
          <b>NOTE:</b> The host agent package is personalized for your account. Do not share it with
          anyone outside your organization.
        </Text>
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  tutorial: {
    display: 'grid',
    gridGap: theme.spacing(1),
  },
  tutorialStep: {
    '& .title': {
      ...theme.typography.body2,
    },
  },
  codeBlocks: {
    paddingLeft: 40,
  },
  root: {
    display: 'grid',
    gap: 24,
  },
  section: {
    display: 'grid',
    gap: 12,
  },
  list: {
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    paddingInlineStart: 25,
  },
  linkText: {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
}))
