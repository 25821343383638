import React, { FC } from 'react'
import useReactRouter from 'use-react-router'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { switchCase } from 'utils/fp'
import { ClusterCloudPlatforms } from 'app/constants'
import generateTestId from 'utils/test-helpers'
import { CloudProviders } from './model'

const useStyles = makeStyles<Theme, Partial<Props>>((theme) => ({
  root: {
    filter: ({ disabled }) => (disabled ? 'grayscale(100%)' : null),
    opacity: ({ disabled }) => (disabled ? 0.7 : 1),
    padding: theme.spacing(1.5, 0, 0.5, 0),
    userSelect: 'none',
    textAlign: 'center',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
    border: ({ active }) =>
      active ? `1px solid ${theme.palette.blue[500]}` : `1px solid transparent`,
    borderRadius: 4,
    backgroundColor: ({ active }) => (active ? theme.palette.blue[100] : theme.palette.grey['000']),
    width: 240,
    height: 124,
    '& img': {
      maxWidth: 160,
      maxHeight: 100,
    },
    '&:hover': {
      // margin: ({ disabled }) => (!disabled ? 0 : 1),
      border: ({ disabled }) => (!disabled ? '1px solid #4aa3df' : '1px solid #999'),
    },
  },
  label: {
    marginTop: theme.spacing(1),
    color: theme.palette.grey[700],
  },
}))

const iconSizes = { small: '', medium: '@2x', large: '@3x' }
const iconSize = iconSizes.small
const rootPath = '/ui/images/icon-cloudproviders'
const icons = switchCase(
  {
    [CloudProviders.Aws]: `${rootPath}/cloudaws-default${iconSize}.png`,
    [ClusterCloudPlatforms.EKS]: `${rootPath}/cloudaws-default${iconSize}.png`,
    [CloudProviders.Azure]: `${rootPath}/cloudazure-default${iconSize}.png`,
    [ClusterCloudPlatforms.AKS]: `${rootPath}/cloudazure-default${iconSize}.png`,
    [CloudProviders.Gcp]: `${rootPath}/cloudgoogle-default${iconSize}.png`,
    [ClusterCloudPlatforms.GKE]: `${rootPath}/cloudgoogle-default${iconSize}.png`,
    [CloudProviders.VirtualMachine]: `${rootPath}/vm-default${iconSize}.png`,
    [CloudProviders.PhysicalMachine]: `${rootPath}/physical-default${iconSize}.png`,
    kubevirt: '/ui/images/kubevirt.png',
  },
  '', // TODO: design an icon for a missing provider
)

const labels = switchCase(
  {
    [CloudProviders.Aws]: 'Amazon Web Services',
    [ClusterCloudPlatforms.EKS]: 'Amazon EKS',
    [CloudProviders.Azure]: 'Microsoft Azure',
    [ClusterCloudPlatforms.AKS]: 'Microsoft Azure AKS',
    [CloudProviders.Gcp]: 'Google Cloud',
    [ClusterCloudPlatforms.GKE]: 'Google GKE',
    [CloudProviders.VirtualMachine]: 'BareOS Virtual Machines',
    [CloudProviders.PhysicalMachine]: 'BareOS Physical Servers',
    kubevirt: 'Kubevirt',
  },
  '',
)

const CloudProviderCard: FC<Props> = (props) => {
  const {
    type,
    disabled,
    image = icons(type),
    label = labels(type),
    src,
    onClick,
    active,
    asCard = true,
  } = props
  const classes = useStyles(props)
  const { history } = useReactRouter()
  const handleClick = () => {
    if (disabled || active) return
    if (onClick) return onClick(type)
    history.push(src)
  }
  if (!image) {
    return <MissingCloudProviderCard />
  }
  if (!asCard) {
    return <img alt={type} src={image} />
  }
  return (
    <div className={classes.root} onClick={handleClick}>
      <div className={classes.logoContainer}>
        <img alt={type} src={image} />
      </div>
      <Text
        data-testid={generateTestId(label, 'cloud', 'provider')}
        className={classes.label}
        variant="caption4"
      >
        {label}
      </Text>
    </div>
  )
}

export const MissingCloudProviderCard = ({ text = 'No Provider', caption = '' }) => {
  const classes = useStyles({ disabled: true, active: true })
  return (
    <div className={classes.root}>
      <div className={classes.logoContainer}>
        <Text variant="body1">{text}</Text>
      </div>
      <Text className={classes.label} variant="caption4">
        {caption}
      </Text>
    </div>
  )
}

interface Props {
  type: CloudProviders
  src?: string
  onClick?: (type: CloudProviders) => any
  active?: boolean
  image?: string
  label?: string
  disabled?: boolean
  asCard?: boolean
}

export default CloudProviderCard
