import React, { useEffect, useMemo } from 'react'
import SimpleLink from 'core/components/SimpleLink'
import Text from 'core/elements/Text'
import { routes } from 'core/utils/routes'
import useReactRouter from 'use-react-router'
import { isKaapiClusterHealthy } from '../dashboard/helpers'
import InfoTooltip from 'core/components/InfoTooltip'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { isReadonlyUser } from 'core/utils/helpers'

const useStyles = makeStyles((theme: Theme) => ({
  root: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px' },
  disableSelection: {
    cursor: 'not-allowed',
    '& > span': {
      color: theme.palette.grey[300],
    },
  },
}))

// Cell component for K8s Version and display upgrade status
const K8sVersionCellComponent = ({ item, supportedK8sVersions }) => {
  const classes = useStyles({})
  const { history } = useReactRouter()

  const { upgradeFailed, upgrading, metadata, version } = item || {}
  const clusterName = metadata?.name
  const isClusterHealthy = isKaapiClusterHealthy(item)
  const { isLatestVersion } = useMemo(() => {
    const latestVersion = supportedK8sVersions[supportedK8sVersions.length - 1]
    const isLatestVersion = version === latestVersion || item?.status?.phase === 'Deleting'
    return { latestVersion, isLatestVersion }
  }, [supportedK8sVersions, version])

  const handleSeeMoreClick = () => {
    history.push(routes.kubernetes.manage.upgradeStatus.path({ cluster: clusterName }))
  }

  const handleUpgradeClick = () => {
    history.push(routes.kubernetes.manage.clusterUpgrade.path({ cluster: clusterName }))
  }

  const renderContent = () => {
    // For BYOH clusters, display only version and hide the upgrade options
    if (item?.isBYOHCluster) {
      return <Text variant="body2">{version}</Text>
    }

    if (upgradeFailed) {
      return (
        <div className={classes.root}>
          <Text variant="body2">Failed</Text>
          <SimpleLink src="" onClick={handleSeeMoreClick}>
            See more
          </SimpleLink>
        </div>
      )
    }

    if (upgrading) {
      return (
        <div className={classes.root}>
          <Text variant="body2">Upgrading</Text>
          <SimpleLink src="" onClick={handleSeeMoreClick}>
            See more
          </SimpleLink>
        </div>
      )
    }

    if (!isLatestVersion) {
      return (
        <div className={classes.root}>
          <Text variant="body2">{item?.hostedControlPlane?.spec?.version}</Text>
          {!isReadonlyUser() && (
            <>
              {isClusterHealthy ? (
                <SimpleLink src="" onClick={handleUpgradeClick}>
                  Upgrade
                </SimpleLink>
              ) : (
                <InfoTooltip info="Upgrades can only be performed when cluster status is healthy.">
                  <SimpleLink textDecoration="none" className={classes.disableSelection} src="">
                    Upgrade
                  </SimpleLink>
                </InfoTooltip>
              )}
            </>
          )}
        </div>
      )
    }

    return <Text variant="body2">{item?.hostedControlPlane?.spec?.version}</Text>
  }

  return <div className={classes.root}>{renderContent()}</div>
}

export default K8sVersionCellComponent
