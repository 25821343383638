import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { prop, isEmpty } from 'ramda'
import { makeStyles } from '@material-ui/styles'
import jsYaml from 'js-yaml'
import useReactRouter from 'use-react-router'

import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import DocumentMeta from 'core/components/DocumentMeta'
import ApiClient from 'api-client/ApiClient'
import { pathStrOr } from 'utils/fp'
import { routes } from 'core/utils/routes'
import useListAction from 'core/hooks/useListAction'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { customResourceDefinitionsSelector } from 'k8s/components/resources/custom-resource-definitions/selectors'
import EntityYamlPage from 'k8s/components/common/entity/entity-yaml-page'
import OverviewActions from 'core/elements/overview/OverviewActions'
import { listCustomResourceDefinitions, updateCustomResourceDefinition } from '../actions'
import CustomResourceDefinitionsOverview from './CustomResourceDefinitionsOverview'
import DeleteCustomResourceDefinitionDialog from '../DeleteCustomResourceDefinitionDialog'
import CustomResourceList from './custom-resources/CustomResourcesList'
import { clientStoreKey, ClientState } from 'core/client/clientReducers'

import {
  ICustomResourceDefinitionDetailsPageTabs,
  ICustomResourceDefinitionSelector,
} from '../model'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { K8sPluginGlobalPrefs } from 'k8s/model'

const { capi } = ApiClient.getInstance()

const actions = {
  rightActions: [
    {
      label: 'Delete',
      icon: 'trash-alt',
      DialogComponent: DeleteCustomResourceDefinitionDialog,
    },
  ],
}

// This is control the overflow of YAML Editor due to lengthy field values in yaml
const useStyles = makeStyles(() => ({
  codeMirrorStylingExpanded: {
    '& .CodeMirror-scroll': {
      width: `calc(100vw - 326px) !important`,
    },
  },
  codeMirrorStylingCollapsed: {
    '& .CodeMirror-scroll': {
      width: `calc(100vw - 112px) !important`,
    },
  },
}))

const CustomResourceDefinitionsDetailsPage = () => {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id } = match.params
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterId = k8sPluginGlobalPerfs?.clusterId
  const { loading, reload } = useListAction(listCustomResourceDefinitions, {
    params: { clusterId },
    initialLoadingState: true,
  })

  const { frame: { sidebarState = 'expanded' } = {} } = useSelector(
    prop<string, ClientState>(clientStoreKey),
  )
  const sidenavExpanded = sidebarState === 'expanded'
  const classes = useStyles({})

  const customResourceDefinitions: ICustomResourceDefinitionSelector[] = useSelector(
    customResourceDefinitionsSelector,
  )
  const customResourceDefinition = useMemo(
    () =>
      customResourceDefinitions.find(
        (customResourceDefinition) => customResourceDefinition?.id === id,
      ),
    [id, customResourceDefinitions],
  )

  const { update, updating } = useUpdateAction(updateCustomResourceDefinition)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: customResourceDefinition?.clusterName || clusterId,
        id: customResourceDefinition?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [customResourceDefinition?.clusterName, customResourceDefinition?.name, id, clusterId])

  const getCustomResourceDefinitionYaml = useCallback(async () => {
    if (isEmpty(customResourceDefinition)) return undefined
    return capi.getCustomResourceDefinition(
      customResourceDefinition?.clusterId,
      customResourceDefinition?.name,
    )
  }, [customResourceDefinition])

  const handleCustomResourceDefinitionUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const name = pathStrOr('', 'metadata.name', body)
    await update({
      id,
      clusterId,
      name,
      body,
    })
  }
  useEffect(() => {
    if (clusterId) {
      reload(true)
    }
  }, [clusterId])

  // Hide Delete Button when showing custom resource list
  const hideDeleteButton = window.location.pathname.includes('/custom-resources')

  return (
    <>
      <DocumentMeta title="Custom Resource Definition Overview" breadcrumbs />
      {!hideDeleteButton && (
        <div style={{ padding: '8px' }}>
          <OverviewActions actions={actions} entity={customResourceDefinition} />
        </div>
      )}
      <Tabs route={routes.kubernetes.resources.customResourceDefinitions.details}>
        <Tab label="Overview" value={ICustomResourceDefinitionDetailsPageTabs.Overview}>
          <CustomResourceDefinitionsOverview
            customResourceDefinition={customResourceDefinition}
            loading={loading}
            reload={reload}
          />
        </Tab>
        <Tab label="YAML" value={ICustomResourceDefinitionDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Config Map"
            entityName={customResourceDefinition?.name}
            getYamlFn={getCustomResourceDefinitionYaml}
            handleUpdate={handleCustomResourceDefinitionUpdate}
            loading={loading || updating}
            className={
              sidenavExpanded
                ? classes.codeMirrorStylingExpanded
                : classes.codeMirrorStylingCollapsed
            }
          />
        </Tab>
        <Tab
          label="Custom Resources"
          value={ICustomResourceDefinitionDetailsPageTabs.CustomResources}
        >
          <CustomResourceList />
        </Tab>
      </Tabs>
    </>
  )
}

export default CustomResourceDefinitionsDetailsPage
