import { makeStyles } from '@material-ui/styles'
import React, { useCallback, useEffect, useState } from 'react'

import { RootState, useAppSelector } from 'app/store'
import Dropdown from 'core/elements/dropdown'
import ModalForm from 'pf9-ui-components/built/elements/modal/ModalForm'
import TextField from 'pf9-ui-components/built/components/validatedForm/TextField'
import DropdownField from 'pf9-ui-components/built/components/validatedForm/DropdownField'
import ToggleSwitchField from 'pf9-ui-components/built/components/validatedForm/ToggleSwitchField'
import QuantitySelector from 'pf9-ui-components/built/components/QuantitySelector'
import Text from 'pf9-ui-components/built/elements/Text'

import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import Theme from 'core/themes/model'
import { validators } from 'core/utils/fieldValidators'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'
import { listKaapiClusters } from '../kaapi/clusters/actions'
import { updateKaapiMachineDeployment } from '../kaapi/machine-deployment/actions'
import { middleLeft } from 'core/elements/menu/defaults'
import { listFlavors } from 'app/plugins/openstack/components/flavors/actions'
import { flavorsSelector } from 'app/plugins/openstack/components/flavors/selectors'
import { humanReadableSize } from 'app/plugins/openstack/helpers'
import Progress from 'pf9-ui-components/built/components/progress/Progress'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'

interface StyleProps {
  autoscalingEnabled: boolean
}

export const enum NodeUpdateStrategyTypes {
  Count = 'count',
  Percentage = 'percent',
}
const rollingUpdateTypeOptions = [
  { label: 'Count', value: NodeUpdateStrategyTypes.Count },
  { label: 'Percentage', value: NodeUpdateStrategyTypes.Percentage },
]

const convertToPercentageStr = (value: string | number) => {
  if (!value) return value
  return value.toString() + '%'
}

//TODO: Need to refactor as its not working with getParamsUpdater
// function parsePercentageValue(value) {
//   if (typeof value === 'string' && value.includes('%')) {
//     return parseFloat(value.replace('%', ''))
//   } else if (typeof value === 'number') {
//     return value
//   }
//   return null // Default case for invalid values
// }

const useStyles = makeStyles<Theme, Partial<StyleProps>>((theme) => ({
  fields: {
    display: 'grid',
    gridGap: theme.spacing(4),
  },
  section: {
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderBottom: `solid 1px ${theme.components.card.border}`,
  },
  maxNodesText: ({ autoscalingEnabled }) => ({
    color: autoscalingEnabled ? theme.palette.text.primary : theme.palette.text.disabled,
    cursor: autoscalingEnabled ? 'pointer' : 'not-allowed',
    pointerEvents: autoscalingEnabled ? 'auto' : 'none',
  }),
  maxNodesSelector: ({ autoscalingEnabled }) => ({
    cursor: autoscalingEnabled ? 'pointer' : 'not-allowed',
    pointerEvents: autoscalingEnabled ? 'auto' : 'none',
  }),
}))

interface Props {
  rows: any[]
  onClose: () => void
  onUpgradeScreen?: boolean
}

const getValidationRules = (
  type: string,
  params: { maxSurgeValue: number; maxUnavailableValue: number; nodes: number },
) => {
  if (type === 'percent') {
    return [
      params.maxSurgeValue === 0 && params.maxUnavailableValue === 0
        ? validators.rangeValueWithLable(
            params.maxSurgeValue,
            params.maxUnavailableValue,
            'Max Surge Value',
            'Max Unavailable Value',
          )
        : validators.rangeValue(0, 100),
    ]
  } else {
    return [
      params.maxSurgeValue === 0 && params.maxUnavailableValue === 0
        ? validators.rangeValueWithLable(
            params.maxSurgeValue,
            params.maxUnavailableValue,
            'Max Surge Value',
            'Max Unavailable Value',
          )
        : validators.rangeValue(0, params.nodes),
    ]
  }
}

export default function EditClusterNodeGroupsModal({
  rows: [nodeGroupDetails],
  onClose,
  onUpgradeScreen = false,
}: Props) {
  const { activeKaapiTenant: namespace } = useSelector<RootState, SessionState>(
    prop(sessionStoreKey),
  )
  const defaultParams = {
    name: nodeGroupDetails?.name,
    nodes: nodeGroupDetails?.nodes,
    strategy: nodeGroupDetails?.strategy,
    replicas: nodeGroupDetails?.spec?.replicas,
    autoscalingEnabled: nodeGroupDetails?.spec?.autoscaling === 'Enabled',
    autoscalingMaxNodes: nodeGroupDetails?.spec?.autoscalingMaxNodes || 1,
    maxSurgeType: nodeGroupDetails?.maxSurge,
    maxSurgeValue:
      typeof nodeGroupDetails?.maxSurgeValue === 'string' &&
      nodeGroupDetails?.maxSurgeValue?.includes('%')
        ? parseFloat(nodeGroupDetails?.maxSurgeValue?.replace('%', ''))
        : typeof nodeGroupDetails?.maxSurgeValue === 'number'
        ? nodeGroupDetails?.maxSurgeValue
        : null,
    maxUnavailableType: nodeGroupDetails?.maxUnavailable,
    maxUnavailableValue:
      typeof nodeGroupDetails?.maxUnavailableValue === 'string' &&
      nodeGroupDetails?.maxUnavailableValue?.includes('%')
        ? parseFloat(nodeGroupDetails?.maxUnavailableValue?.replace('%', ''))
        : typeof nodeGroupDetails?.maxUnavailableValue === 'number'
        ? nodeGroupDetails?.maxUnavailableValue
        : null,
  }

  const [maxSurgeType, setMaxSurgeType] = useState(
    defaultParams?.maxSurgeType?.toLowerCase() || NodeUpdateStrategyTypes.Count,
  )
  const [maxUnavailableType, setMaxUnavailableType] = useState(
    defaultParams?.maxUnavailableType?.toLowerCase() || NodeUpdateStrategyTypes.Count,
  )
  const [autoscalingError, setAutoscalingError] = useState('')

  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)
  const { update, updating, error, reset } = useUpdateAction(updateKaapiMachineDeployment)
  const { reload: reloadKaapiClusters } = useListAction(listKaapiClusters)

  const classes = useStyles({ autoscalingEnabled: params.autoscalingEnabled })

  // VM Flavors
  const { loading: loadingVmFlavors } = useListAction(listFlavors)
  const vmFlavors = useAppSelector(flavorsSelector)

  const nodegroupFlavor = vmFlavors?.find(
    (vmFlavor) =>
      vmFlavor?.name === nodeGroupDetails?.openStackMachineTemplate?.spec?.template?.spec?.flavor,
  )

  const submitForm = useCallback(
    async (values) => {
      const body = [
        {
          op: 'replace',
          path: '/spec/strategy/type',
          value: values.nodesUpdateStrategy,
        },
        {
          op: 'replace',
          path: '/spec/strategy/rollingUpdate/maxSurge',
          value:
            values.maxSurgeType === 'percent'
              ? convertToPercentageStr(values.maxSurgeValue)
              : values.maxSurgeValue,
        },
        {
          op: 'replace',
          path: '/spec/strategy/rollingUpdate/maxUnavailable',
          value:
            values.maxUnavailableType === 'percent'
              ? convertToPercentageStr(values.maxUnavailableValue)
              : values.maxUnavailableValue,
        },
      ]

      if (!onUpgradeScreen) {
        // Update Relicas
        body.push({
          op: 'replace',
          path: '/spec/replicas',
          value: params.replicas,
        })

        // Enabling autoscaling
        if (params.autoscalingEnabled) {
          body.push({
            op: 'replace',
            path: '/metadata/annotations',
            value: {
              ...nodeGroupDetails?.metadata?.annotations,
              'cluster.x-k8s.io/cluster-api-autoscaler-node-group-max-size': params.autoscalingMaxNodes.toString(),
              'cluster.x-k8s.io/cluster-api-autoscaler-node-group-min-size': params.replicas.toString(),
            },
          })
        }

        // Disable autosacaling
        if (nodeGroupDetails?.spec?.autoscaling === 'Enabled' && !params.autoscalingEnabled) {
          const annotations = JSON.parse(JSON.stringify(nodeGroupDetails?.metadata?.annotations))
          delete annotations['cluster.x-k8s.io/cluster-api-autoscaler-node-group-max-size']
          delete annotations['cluster.x-k8s.io/cluster-api-autoscaler-node-group-min-size']
          body.push({
            op: 'replace',
            path: '/metadata/annotations',
            value: {
              ...annotations,
            },
          })
        }
      }

      const { success } = await update({ namespace, name: params.name, customBody: body })

      if (success) {
        onClose()
        reloadKaapiClusters(true)
      }
    },
    [params, nodeGroupDetails, onUpgradeScreen],
  )

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    onClose()
  }

  useEffect(() => {
    if (params?.autoscalingEnabled && params?.autoscalingMaxNodes < params?.replicas) {
      setAutoscalingError(
        'The value must be greater than or equal to the selected amount of instance types.',
      )
    } else {
      setAutoscalingError('')
    }
  }, [params?.autoscalingMaxNodes, params?.replicas, params?.autoscalingEnabled])

  return (
    <ModalForm
      open
      title={`Edit`}
      entityName={params.name}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Save Changes`}
      disableSubmit={!!autoscalingError}
      maxWidth={464}
    >
      <div>
        {/* Hide the below field for k8s upgrade screens */}
        {!onUpgradeScreen && (
          <div>
            <div className={classes.section}>
              <Progress loading={loadingVmFlavors}>
                <Text
                  variant="body1"
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <strong>{nodegroupFlavor?.name}</strong>
                </Text>
                <Text
                  variant="body2"
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  (vCPUs: <strong>{nodegroupFlavor?.vcpus} cores</strong> / Memory:{' '}
                  <strong>
                    {humanReadableSize(nodegroupFlavor?.ram * 1024 * 1024 * 1024, false)} GiB
                  </strong>{' '}
                  / Storage:{' '}
                  <strong>
                    {humanReadableSize(nodegroupFlavor?.disk * 1024 * 1024 * 1024, false)} GiB
                  </strong>
                  )
                </Text>
                <br />
                <Text
                  variant="body2"
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  Desired Nodes
                </Text>
                <QuantitySelector
                  id="replica"
                  value={params.replicas}
                  onChange={(value) => updateParams({ replicas: value })}
                  min={1}
                />
              </Progress>
            </div>
            <div className={classes.section}>
              <ToggleSwitchField
                id="autoscaling"
                label="Automatically scale this Node Group"
                value={params.autoscalingEnabled}
                onChange={(value) => updateParams({ autoscalingEnabled: value })}
              />
              <br />
              <Text
                variant="body2"
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
                className={classes.maxNodesText}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                Max Nodes
              </Text>
              <QuantitySelector
                id="autoscalingMaxNodes"
                disabled={!params.autoscalingEnabled}
                value={params.autoscalingMaxNodes}
                onChange={(value) => updateParams({ autoscalingMaxNodes: value })}
                className={classes.maxNodesSelector}
                min={params.replicas}
              />
              {autoscalingError && <ErrorMessage>{autoscalingError}</ErrorMessage>}
            </div>
          </div>
        )}

        <div className={classes.fields}>
          <DropdownField
            DropdownComponent={Dropdown}
            id="nodesUpdateStrategy"
            label="Strategy"
            value="RollingUpdate"
            disabled
            items={[{ label: 'Rolling Update', value: 'RollingUpdate' }]}
            info={
              <>
                A <b>rolling update</b> allows a Node Group update to take place with zero or
                controlled number of nodes to be down.
              </>
            }
            align={middleLeft.align}
          />
          <DropdownField
            DropdownComponent={Dropdown}
            id="maxSurgeType"
            label="Max Surge Type"
            value={maxSurgeType}
            items={rollingUpdateTypeOptions}
            onChange={(value) => {
              setMaxSurgeType(value)
            }}
            info={
              <>
                The maximum number of machines that can be scheduled above the desired number of
                nodes.
                <br /> Value can be an absolute number (ex: 5) or a percentage of desired Nodes (ex:
                10%).
                <br /> This can not be 0 if MaxUnavailable is 0.
                <br /> Absolute number is calculated from percentage by rounding up.
                <br /> Defaults to 1.
              </>
            }
            align={middleLeft.align}
          />
          <TextField
            crossOrigin={undefined}
            id="maxSurgeValue"
            label="Max Surge Value"
            onChange={getParamsUpdater('maxSurgeValue')}
            value={params.maxSurgeValue}
            type="number"
            required
            icon={maxSurgeType === 'percent' ? 'percent' : ''}
            iconProps={{ placement: 'end' }}
            validations={getValidationRules(maxSurgeType, params)}
            info={
              <>
                Maximum number/percentage of nodes that can be scaled up above the desired Node
                Group replica count.
                <br /> Applicable values:
                <br /> Minimum = 0<br /> Maximum = Any number / percentage
                <br /> Default = 0
              </>
            }
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
            enterKeyHint={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
          <DropdownField
            DropdownComponent={Dropdown}
            id="maxUnavailableType"
            label="Max Unavailable Type"
            value={maxUnavailableType}
            items={rollingUpdateTypeOptions}
            onChange={(value) => {
              setMaxUnavailableType(value)
            }}
            info={
              <>
                The maximum number of nodes that can be unavailable during the update.
                <br /> Value can be an absolute number (ex: 5) or a percentage of desired nodes (ex:
                10%).
                <br /> Absolute number is calculated from percentage by rounding down.
                <br /> This cannot be 0 if MaxSurge is 0.
                <br /> Defaults to 0.
              </>
            }
            align={middleLeft.align}
          />
          <TextField
            crossOrigin={undefined}
            id="maxUnavailableValue"
            label="Max Unavailable Value"
            onChange={getParamsUpdater('maxUnavailableValue')}
            value={params.maxUnavailableValue}
            type="number"
            icon={maxUnavailableType === 'percent' ? 'percent' : ''}
            iconProps={{ placement: 'end' }}
            required
            validations={getValidationRules(maxUnavailableType, params)}
            info={
              <>
                Maximum number/percentage of nodes that can unavailable.
                <br /> Applicable values:
                <br /> Minimum = 0<br /> Maximum = Node group replica count / 100 %<br /> Default =
                0
              </>
            }
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
            enterKeyHint={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        </div>
      </div>
    </ModalForm>
  )
}
