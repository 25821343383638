import React, { useEffect, useState } from 'react'
import { TopHeaderActionPortal } from 'core/elements/header/portals'
import ExternalLink from 'core/components/ExternalLink'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import ApiClient from 'api-client/ApiClient'
import { Route } from 'core/plugins/route'
import Tooltip from 'core/elements/tooltip'
import { DOCUMENT_LINKS } from './constants'

const useStyles = makeStyles<Theme>((theme) => ({
  icon: {
    color: theme.palette.error.main,
    marginRight: 8,
  },
  link: {
    color: theme.palette.error.main,
    '& > span': {
      color: theme.palette.error.main,
    },
  },
}))

export default function TrustCAAlert() {
  const { capi } = ApiClient.getInstance()
  const classes = useStyles()
  const [needToValidateCert, setNeedToValidateCert] = useState<boolean>(false)
  const { prefs } = useScopedPreferences('k8sPluginGlobalParams')
  const route = Route.getCurrentRoute()
  const showClusterDropdown = route?.defaultParams?.showClusterDropdown === 'true'

  useEffect(() => {
    setNeedToValidateCert(false)
    const asyncCall = async () => {
      try {
        await capi.checkWorkloadClusterEndpoint()
        setNeedToValidateCert(false)
      } catch (err) {
        if (err?.err?.message === 'Network Error') {
          setNeedToValidateCert(true)
        }
      }
    }

    if (prefs?.cluster_endpoint && prefs?.cluster) {
      asyncCall()
    }

    // Add a eventlistener to recheck again when user returns to the tab
    window.addEventListener('focus', asyncCall)
    return () => window.removeEventListener('focus', asyncCall)
  }, [prefs?.cluster_endpoint, prefs?.cluster])

  if (
    !showClusterDropdown ||
    !needToValidateCert ||
    !prefs?.cluster_endpoint ||
    !prefs?.cluster ||
    ['clustersDashboard'].includes(route?.id) // Hide the alert for cluster dashboard page
  ) {
    return null
  }

  return (
    <TopHeaderActionPortal>
      <Tooltip
        message={
          <>
            You need to trust the cluster end point self-signed certificate for the UI to show
            cluster specific data.{' '}
            <ExternalLink url={DOCUMENT_LINKS.K8S_ACCEPT_CERTIFICATE_AUTHORITY}>
              More info
            </ExternalLink>
          </>
        }
        align={{ vertical: 'bottom', horizontal: 'middle' }}
        offset={{ vertical: 10, horizontal: 10 }}
      >
        <ExternalLink
          url={prefs?.cluster_endpoint}
          textVariant="subtitle2"
          className={classes.link}
        >
          <FontAwesomeIcon className={classes.icon}>exclamation-circle</FontAwesomeIcon>
          Action Required: Trust Cluster Endpoint Certificate
        </ExternalLink>
      </Tooltip>
    </TopHeaderActionPortal>
  )
}
