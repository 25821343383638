import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Text from 'core/elements/Text'
import HintTooltip from 'core/elements/tooltip/HintTooltip'
import FontAwesomeIcon from 'pf9-ui-components/built/components/FontAwesomeIcon'
import DropdownField from 'pf9-ui-components/built/components/validatedForm/DropdownField'
import TextField from 'pf9-ui-components/built/components/validatedForm/TextField'
import ToggleSwitchField from 'pf9-ui-components/built/components/validatedForm/ToggleSwitchField'
import Badge from 'pf9-ui-components/built/elements/badge/Badge'
import AsyncDropdown from 'pf9-ui-components/built/elements/dropdown/AsyncDropdown'
import Tooltip from 'pf9-ui-components/built/elements/tooltip/Tooltip'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import React, { useMemo } from 'react'

interface AddonFieldProps {
  id: string
  label: string
  description?: string
  params: Record<any, any>
  getParamsUpdater: (value: any) => void
  disabled?: boolean
  badgeText?: string
  tooltipMsg?: string
  info?: string
  customTooltipInfoBody?: React.ReactNode
  hideToggle?: boolean
}

const AddonField = ({
  id,
  label,
  description,
  params,
  getParamsUpdater,
  disabled,
  children,
  badgeText,
  tooltipMsg,
  info,
  customTooltipInfoBody,
  hideToggle,
}: React.PropsWithChildren<AddonFieldProps>) => {
  const classes = useAddonFieldStyles()

  const ToggleComponent = !hideToggle ? (
    <ToggleSwitchField
      id={id}
      value={params[id]}
      onChange={getParamsUpdater(id)}
      disabled={disabled}
    />
  ) : null

  return (
    <div className={classes.addonContainer}>
      {tooltipMsg ? (
        <Tooltip message={tooltipMsg}>{ToggleComponent}</Tooltip>
      ) : (
        <>{ToggleComponent}</>
      )}
      <div>
        <div className={classes?.label}>
          <Text variant="caption1">{label}</Text>
          {info ||
            (customTooltipInfoBody && (
              <Tooltip customBody={customTooltipInfoBody} message={info}>
                <FontAwesomeIcon>circle-info</FontAwesomeIcon>
              </Tooltip>
            ))}
        </div>
        {badgeText && <Badge className={classes.badge} text={badgeText} variant="default" />}
      </div>
      <div className={classes.desciptionAndOptions}>
        <Text variant="body2">{description}</Text>
        {children}
      </div>
    </div>
  )
}

const useAddonFieldStyles = makeStyles<Theme>((theme) => ({
  addonContainer: {
    display: 'grid',
    gridTemplateColumns: 'max-content 131px 1fr',
    gridGap: theme.spacing(4),
    alignItems: 'start',
  },
  desciptionAndOptions: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridGap: theme.spacing(3),
  },
  label: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: theme.spacing(1),
  },
  badge: {
    marginTop: theme.spacing(2),
    backgroundColor: '#ECFBFA',
    border: '1px solid #ECFBFA',
  },
}))

interface CloudspadceConfigurationFieldsProps {
  params: Record<any, any>
  getParamsUpdater: (key: string) => (value: any) => void
  k8sVersions: string[]
}

export default function ClusterConfigFields({
  params,
  getParamsUpdater,
  k8sVersions,
}: CloudspadceConfigurationFieldsProps) {
  const classes = useStyles()

  const k8sVersionOptions = useMemo(
    () => k8sVersions?.map((version) => ({ label: version, value: version })),
    [k8sVersions],
  )

  const handleEnableAddonsChange = (enable) => {
    getParamsUpdater('enableAddons')(enable)

    // Default to enabling coreDNS and Calico when enabling addons
    getParamsUpdater('coreDns')(enable)
    getParamsUpdater('calico')(enable)
  }

  return (
    <div className={classes.clusterConfigFields}>
      <div className={classes.k8sVersion}>
        <Text variant="caption1">Kubernetes Version</Text>
        <DropdownField
          id="k8sVersion"
          DropdownComponent={AsyncDropdown}
          items={k8sVersionOptions}
          value={params?.k8sVersion}
          onChange={getParamsUpdater('k8sVersion')}
          required
          selectFirst
        />
      </div>
      <hr className={classes.hr} />
      <AddonField
        id="enableK8sFlags"
        label="Kubernetes Flags"
        description="Customize kube-api flags, Controller Manager and Scheduler Flags using cluster API."
        params={params}
        getParamsUpdater={getParamsUpdater}
      >
        {params?.enableK8sFlags && (
          <div className={classes.textFields}>
            <TextField
              id="apiServerFlags"
              label="API Server Flags"
              value={params?.apiServerFlags}
              onChange={getParamsUpdater('apiServerFlags')}
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
              enterKeyHint={undefined}
              info="Enter a comma separated list of supported kube-apiserver. Eg. --request-timeout=2m0s,--kubelet-timeout=20s"
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              crossOrigin={undefined}
            />
            <TextField
              id="schedulerFlags"
              label="Scheduler Flags"
              value={params?.schedulerFlags}
              onChange={getParamsUpdater('schedulerFlags')}
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
              enterKeyHint={undefined}
              info="Enter a comma separated list of supported kube-scheduler flags. Eg. --kube-api-burst=120,--log_file_max_size=3000"
              crossOrigin={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            />
            <TextField
              id="controllerManagerFlags"
              label="Controller Manager Flags"
              value={params?.controllerManagerFlags}
              onChange={getParamsUpdater('controllerManagerFlags')}
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
              enterKeyHint={undefined}
              info="Enter a comma separated list of supported kube-controller-manager flags. Eg. --large-cluster-size-threshold=60,--concurrent-statefulset-syncs=10"
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              crossOrigin={undefined}
            />
          </div>
        )}
      </AddonField>
      <hr className={classes.hr} />
      <AddonField
        id="enableAddons"
        label="Add-Ons"
        description="Customize add-ons using cluster API add-on configuration manifests."
        params={params}
        getParamsUpdater={() => (value) => {}}
        // getParamsUpdater={() => (value) => handleEnableAddonsChange(value)}
        hideToggle
      >
        {params?.enableAddons && (
          <div className={classes.addonsFieldContainer}>
            <div className={classes.column}>
              <div className={classes.addonCategoryHeader}>
                <Text component="figure" variant="caption1" className={classes.numberCircle}>
                  1
                </Text>
                <Text variant="body2">Core Add-ons</Text>
              </div>
              <div className={clsx(classes.column, classes.coreAddons)}>
                <Tooltip
                  message="Disabling CoreDNS is currently not supported"
                  align={{ vertical: 'middle', horizontal: 'left' }}
                >
                  <ToggleSwitchField
                    id="coreDns"
                    label="CoreDNS"
                    value={params?.coreDns}
                    // onChange={getParamsUpdater('coreDns')}
                    className={classes.disabledRadioButton}
                  />
                </Tooltip>
                <Tooltip
                  message="Disabling Calico is currently not supported"
                  align={{ vertical: 'middle', horizontal: 'left' }}
                >
                  <ToggleSwitchField
                    id="calico"
                    label="Calico"
                    value={params?.calico}
                    // onChange={getParamsUpdater('calico')}
                    className={classes.disabledRadioButton}
                  />
                </Tooltip>
              </div>
            </div>
            <div className={classes.column}>
              <div className={classes.addonCategoryHeader}>
                <Text component="figure" variant="caption1" className={classes.numberCircle}>
                  2
                </Text>
                <Text variant="body2">Other Add-ons</Text>
              </div>
              <div className={classes.column}>
                <div className={classes.column2}>
                  <ToggleSwitchField
                    id="metallb"
                    label="MetalLB"
                    value={params?.metallb}
                    onChange={getParamsUpdater('metallb')}
                  />
                  <HintTooltip
                    message="Enabling MetalLB will disable the default OpenStack LoadBalancer. If disabled, the OpenStack LoadBalancer will be used instead."
                    align={{ vertical: 'bottom', horizontal: 'middle' }}
                    offset={{ vertical: 12, horizontal: 0 }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </AddonField>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  clusterConfigFields: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(3),
    margin: theme.spacing(2, 0, 4, 0),
  },
  hr: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    margin: theme.spacing(2, 3, 1, 0),
  },
  k8sVersion: {
    display: 'grid',
    gridTemplateColumns: '131px 1fr',
    gridGap: theme.spacing(4),
    marginLeft: theme.spacing(8),
  },
  textFields: {
    display: 'grid',
    gridTemplateColumns: '400px',
    gridGap: theme.spacing(2),
  },
  addonsFieldContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 185px)',
    gridGap: theme.spacing(4),
  },
  column: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateRows: 'max-content',
  },
  column2: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
  },
  addonCategoryHeader: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    gridGap: theme.spacing(2),
    marginBottom: theme.spacing(1),
    alignItems: 'center',
  },
  coreAddons: {
    borderRight: `1px solid ${theme.components.card.border}`,
  },
  numberCircle: {
    borderRadius: '50%',
    width: 24,
    height: 24,
    display: 'inline-grid',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.components.wizard.multiStep.bubbleBackground,
    border: `1px solid ${theme.components.wizard.multiStep.bubbleBorder}`,
  },
  disabledRadioButton: {
    cursor: 'not-allowed',
  },
}))
