import Alert from 'core/components/Alert'
import SimpleLink from 'core/components/SimpleLink'
import Text from 'core/elements/Text'
import Theme from 'core/themes/model'
import React from 'react'
import { makeStyles } from '@material-ui/styles'

export const NodeGroupsNotFullyHealthyAlert = ({ redirectLink }: { redirectLink?: () => void }) => {
  return (
    <Alert title="Node groups are not fully healthy" variant="primary">
      <Text variant="body2">
        Upgrade can still be performed but in order to prevent any problems that may arise after
        improvement, it is recommended to only upgrade fully healthy Node Groups.
      </Text>
      <SimpleLink onClick={redirectLink}>Check Node Groups Health Status</SimpleLink>
    </Alert>
  )
}

export const UnhealthyNodeGroupsAlert = ({ redirectLink }: { redirectLink?: () => void }) => {
  return (
    <Alert title="Incorrect node groups health status" variant="error">
      <Text variant="body2">
        Upgrade cannot be performed because of the bad health status of some node groups.
      </Text>
      <SimpleLink onClick={redirectLink}>Check Node Groups Health Status</SimpleLink>
    </Alert>
  )
}

export const DepreciatedVersionAlert = ({
  deprecatedVersion = '1.21',
  date = '2022-02-28',
  newVersion = '1.23',
}) => {
  return (
    <Alert title={`This cluster uses Kubernetes ${deprecatedVersion} version`} variant="primary">
      <Text variant="body2">
        Kubernetes <b>{deprecatedVersion}</b> has been deprecated as of <b>{date}</b>
      </Text>
      <Text variant="body2">
        Cluster should be upgraded to at least <b>{newVersion}</b> version
      </Text>
    </Alert>
  )
}

export const ExtendedVersionAlert = ({
  olderVersion = '1.21',
  date = '2022-02-28',
  newVersion = '1.23',
}) => {
  return (
    <Alert title={`This cluster uses Kubernetes ${olderVersion} version`} variant="warning">
      <Text variant="body2">
        Kubernetes <b>{olderVersion}</b> has crossed the extended support duration as of{' '}
        <b>{date}</b>
      </Text>
      <Text variant="body2">
        Cluster is on unsupported version and should be upgraded to at least <b>{newVersion}</b>{' '}
        version
      </Text>
    </Alert>
  )
}

export const EndOfLifeVersionAlert = ({
  oldVersion = '1.21',
  date = '2022-02-28',
  newVersion = '1.24',
}) => {
  return (
    <Alert title={`This cluster uses Kubernetes ${oldVersion} version`} variant="error">
      <Text variant="body2">
        Kubernetes <b>{oldVersion}</b> has reached End of Life as of <b>{date}</b>
      </Text>
      <Text variant="body2">
        Cluster should be upgraded to at least <b>{newVersion}</b> version
      </Text>
    </Alert>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  clusterName: {
    fontWeight: 'normal',
  },
  currAndLatestVersions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}))
