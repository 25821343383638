import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { useAppSelector } from 'app/store'
import { ArrayElement } from 'core/actions/Action'
import InferActionParams from 'core/actions/InferActionParams'
import DocumentMeta from 'core/components/DocumentMeta'
import DateCell from 'core/components/listTable/cells/DateCell'
import ListContainer from 'core/containers/ListContainer'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import usePluginRouter from 'core/hooks/usePluginRouter'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { routes } from 'core/utils/routes'
import DataKeys from 'k8s/DataKeys'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import { pick } from 'ramda'
import React, { useEffect } from 'react'
import AddRolePage from './AddRolePage'
import { deleteRole, listRoles } from './actions'
import { rolesSelector } from './selectors'
import UpdateRolePage from './UpdateRolePage'
import useNamespaceFilterDropdown from 'k8s/components/useNamespaceFilterDropdown'

type ModelDataKey = DataKeys.KubeRoles
type SelectorModel = ArrayElement<ReturnType<typeof rolesSelector>>
type ActionParams = InferActionParams<typeof listRoles>

type Params = ActionParams & {
  namespace?: string
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  healthyClusters: true,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Roles', listTablePrefs)

const searchTargets = ['name', 'namespace']

const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'name', label: 'Name' },
  { key: 'namespace', label: 'Namespace' },
  { key: 'created', label: 'Created', render: (value) => <DateCell value={value} /> },
]

export default function RolesListPage() {
  const { allParams: params, getParamsUpdater, updateGlobalParams } = useGlobalParams(
    usePrefParams,
    defaultParams,
  )
  const { currentPluginId } = usePluginRouter()
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterId = k8sPluginGlobalPerfs?.clusterId || ''
  const clusterName = k8sPluginGlobalPerfs?.cluster || ''

  const { loading, message, reload } = useListAction(listRoles, {
    params: { clusterId },
    requiredParams,
  })

  const data = useAppSelector(rolesSelector)

  const { Component, selectedNs } = useNamespaceFilterDropdown()

  const filteredRoles = data?.filter((item) => selectedNs?.includes(item?.namespace)) || []

  useEffect(() => {
    if (clusterId) {
      reload(true)
    }
  }, [clusterId])

  return (
    <>
      <DocumentMeta title="Roles" />
      <AddRolePage />
      <UpdateRolePage />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.KubeRoles}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        data={filteredRoles}
        getParamsUpdater={getParamsUpdater}
        addUrl={routes.kubernetes.accessControl.addRoles.path({
          plugin: currentPluginId,
          cluster: clusterName,
        })}
        addText="Add Role"
        editUrl={(item, id) =>
          routes.kubernetes.accessControl.editRoles.path({
            cluster: clusterName,
            roleId: id,
            plugin: currentPluginId,
          })
        }
        deleteAction={deleteRole}
        extraToolbarContent={Component}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
