import { flatten } from 'ramda'

import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { someAsync } from 'utils/async'
import Bugsnag from 'utils/bugsnag'
import { ensureArray, pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'

const { capi } = ApiClient.getInstance()

const secretActions = ActionsSet.make<DataKeys.Secrets>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.Secrets],
  cacheKey: DataKeys.Secrets,
})

export const listSecrets = secretActions.add(
  new ListAction<DataKeys.Secrets, { clusterId: string; namespace?: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Secrets', params)
    const { namespace, clusterId } = params
    return someAsync(ensureArray(namespace).map((name) => capi.getSecrets(clusterId, name))).then(
      flatten,
    )
  }),
)

export const createSecret = secretActions.add(
  new CreateAction<DataKeys.Secrets, { clusterId: string; namespace: string; body: any }>(
    async ({ clusterId, namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new Secret', {
        clusterId,
        namespace,
        body,
      })
      const createdSecret = await capi.createSecret(clusterId, namespace, body)
      trackEvent('Create New Secret', {
        clusterId,
        namespace,
        name: pathStr('metadata.name', createdSecret),
      })
      return createdSecret
    },
  ),
)

export const updateSecret = secretActions.add(
  new UpdateAction<
    DataKeys.Secrets,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Secret', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn = requestType === 'patch' ? capi.patchSecret : capi.updateSecret
    const updatedSecret = await updateFn({
      clusterId,
      namespace,
      name,
      body,
      contentType,
    })
    trackEvent('Update Secret', { clusterId, namespace, name })
    return updatedSecret
  }),
)

export const deleteSecret = secretActions.add(
  new DeleteAction<DataKeys.Secrets, { clusterId: string; namespace: string; name: string }>(
    async ({ clusterId, namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Secret', {
        clusterId,
        namespace,
        name,
      })
      await capi.deleteSecret(clusterId, namespace, name)
    },
  ),
)
