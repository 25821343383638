export const createKaapiSecretBody = ({ name, cloudYaml, namespace, type = 'Opaque' }) => {
  return {
    apiVersion: 'v1',
    kind: 'Secret',
    data: {
      ['clouds.yaml']: cloudYaml,
    },
    metadata: {
      name,
      namespace,
    },
    type,
  }
}
