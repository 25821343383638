import ApiClient from 'api-client/ApiClient'
import { RoleRef } from 'api-client/capi.model'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import { trackEvent } from 'utils/tracking'

const { capi } = ApiClient.getInstance()

const uniqueIdentifier = 'metadata.uid'

export const roleBindingActions = ActionsSet.make<DataKeys.RoleBindings>({
  uniqueIdentifier,
  entityName: entityNamesByKey[DataKeys.RoleBindings],
  cacheKey: DataKeys.RoleBindings,
})

export const listRoleBindings = roleBindingActions.add(
  new ListAction<DataKeys.RoleBindings, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get role bindings', params)
    return capi.getRoleBindings(params.clusterId)
  }),
)

export const createRoleBinding = roleBindingActions.add(
  new CreateAction<
    DataKeys.RoleBindings,
    {
      clusterId: string
      namespace: string
      name: string
      users: string[]
      groups: string[]
      role: string
    }
  >(async (data) => {
    const { clusterId, namespace, name } = data
    Bugsnag.leaveBreadcrumb('Attempting to create role binding', { clusterId, namespace, name })
    const users = data.users.map((user) => ({
      kind: 'User',
      name: user,
      apiGroup: 'rbac.authorization.k8s.io',
    }))

    const groups = data.groups.map((group) => ({
      kind: 'Group',
      name: group,
      apiGroup: 'rbac.authorization.k8s.io',
    }))

    const subjects = [...users, ...groups]

    const [roleType, ...rest] = data.role.split(':')
    const roleName = rest.join(':')
    const body = {
      kind: 'RoleBinding',
      metadata: {
        name: data.name,
        namespace: data.namespace,
      },
      subjects,
      roleRef: {
        kind: roleType,
        name: roleName,
        apiGroup: 'rbac.authorization.k8s.io',
      },
    }
    trackEvent('Create Role Binding', { clusterId, namespace, name })
    return capi.createRoleBinding(data.clusterId, data.namespace, body)
  }),
)

export const updateRoleBinding = roleBindingActions.add(
  new UpdateAction<
    DataKeys.RoleBindings,
    {
      clusterId: string
      namespace: string
      name: string
      users: string[]
      groups: string[]
      roleRef: RoleRef
    }
  >(async (data) => {
    const { clusterId, namespace, name } = data
    Bugsnag.leaveBreadcrumb('Attempting to update role binding', { clusterId, namespace, name })
    const users = data.users.map((user) => ({
      kind: 'User',
      name: user,
      apiGroup: 'rbac.authorization.k8s.io',
    }))

    const groups = data.groups.map((group) => ({
      kind: 'Group',
      name: group,
      apiGroup: 'rbac.authorization.k8s.io',
    }))

    const subjects = [...users, ...groups]

    const body = {
      kind: 'RoleBinding',
      metadata: {
        name: data.name,
      },
      subjects,
      roleRef: data.roleRef,
    }
    trackEvent('Update Role Binding', { clusterId, namespace, name })
    return capi.updateRoleBinding(data.clusterId, data.namespace, data.name, body)
  }),
)

export const deleteRoleBinding = roleBindingActions.add(
  new DeleteAction<DataKeys.RoleBindings>(async ({ clusterId, namespace, name, id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete role binding', { clusterId, namespace, name, id })
    await capi.deleteRoleBinding(clusterId, namespace, name)
    trackEvent('Delete Role Binding', { clusterId, namespace, name, id })
  }),
)
