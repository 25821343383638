import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { whereEq } from 'ramda'
import { arrayIfEmpty } from 'utils/fp'

export const apiGroupsSelector = createSharedSelector(
  getDataSelector<DataKeys.ApiGroups>(DataKeys.ApiGroups),
  getDataSelector<DataKeys.ApiResources>(DataKeys.ApiResources),
  (apiGroups, apiResources) => {
    return arrayIfEmpty([
      ...(apiGroups.length
        ? [
            {
              name: 'core',
              groupVersion: 'core',
            },
          ]
        : []),
      ...apiGroups.map((apiGroup) => {
        const {
          clusterId,
          name,
          preferredVersion: { groupVersion },
        } = apiGroup
        return {
          name,
          groupVersion,
          clusterId,
          resources: apiResources.filter(whereEq({ clusterId, apiGroup: groupVersion })),
        }
      }),
    ])
  },
)
