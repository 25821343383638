import React from 'react'
import { ISecretSelector } from '../model'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { updateSecret } from '../actions'

type Props = {
  open: boolean
  onClose: () => void
  secret: ISecretSelector
  reload: (refetch: boolean) => void
}

export default function SecretImmutabilityConfirmationDialog({
  secret,
  open = false,
  onClose,
  reload,
}: Props) {
  const { update, updating: updatingSecret } = useUpdateAction(updateSecret)

  const updateImmutable = async () => {
    const body = { ...secret, ...{ immutable: true } }
    const params = {
      body,
      clusterId: secret.clusterId,
      name: secret.name,
      namespace: secret.namespace,
    }

    const { error } = await update(params)
    if (error) console.error(error)
    if (!error) reload(true)
    onClose()
  }

  return (
    <ConfirmationDialog
      title="Enable Immutability?"
      text="This change is not revertable."
      confirmText="Enable"
      onConfirm={updateImmutable}
      onCancel={onClose}
      loading={updatingSecret}
      open={open}
    />
  )
}
