import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Card from 'core/elements/card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import InfoCard from 'k8s/components/common/entity/info-card'
import Progress from 'core/components/progress/Progress'
import { renderAgeFromTimestamp } from 'k8s/components/common/entity/helpers'
import Text from 'core/elements/Text'
import InfoBlocks from 'core/components/entity-page/InfoBlocks'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import SimpleLink from 'core/components/SimpleLink'
import { routes } from 'core/utils/routes'
import { IPersistentVolumeDetailsPageTabs } from 'k8s/components/resources/persistent-volume/model'
import usePluginRouter from 'core/hooks/usePluginRouter'
import useListAction from 'core/hooks/useListAction'
import { storageClassSelector } from 'k8s/components/resources/storage-classes/selectors'
import { listStorageClasses } from 'k8s/components/resources/storage-classes/new-actions'
import { useAppSelector } from 'app/store'
import { conditionsColumns, sortByTransitionTime } from '../helpers'
import Grid from 'core/elements/grid'
import { sort } from 'ramda'
import { renderPvcStatus } from 'k8s/components/resources/persistent-volume-claims/helpers'

interface StyleProps {
  status?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  status: {
    display: 'inline-flex',
    gap: 8,
    alignItems: 'center',
  },
  statusCircle: {
    color: ({ status }) => theme.components.badge[status]?.color,
  },
}))

const PvLink = ({ pv }) => {
  const { currentPluginId } = usePluginRouter()

  return (
    <SimpleLink
      textVariant="body2"
      src={routes.kubernetes.resources.persistentVolumes.details.path({
        cluster: pv?.clusterName,
        id: pv?.id,
        tab: IPersistentVolumeDetailsPageTabs.Overview,
      })}
    >
      {pv?.name}
    </SimpleLink>
  )
}

const renderPv = (value) => {
  return <PvLink pv={value} />
}

const StorageClassLink = ({ name, item }) => {
  useListAction(listStorageClasses, {
    params: {
      clusterId: item?.clusterId,
    },
    requiredParams: ['clusterId'],
  })
  const data = useAppSelector(storageClassSelector)
  const storageClass = data?.find((item) => item?.name === name)

  return (
    <SimpleLink
      textVariant="body2"
      //TODO: update this once storage classes pages are added
      src={routes.kubernetes.resources.storageClasses.details.path({
        cluster: storageClass?.clusterName,
        id: storageClass?.id,
      })}
    >
      {name}
    </SimpleLink>
  )
}

export const renderStorageClass = (value, item) => {
  return <StorageClassLink name={value} item={item} />
}

const renderOwnerReference = (value) => {
  const owner = value?.[0]
  return owner ? `${owner?.name} (${owner?.kind})` : 'N/A'
}

const metadataFields = [
  {
    id: 'status.phase',
    title: 'Phase',
    render: (value) => renderPvcStatus(value),
  },
  {
    id: 'spec.resources.requests.storage',
    title: 'Requested Capacity',
  },
  {
    id: 'capacity.storage',
    title: 'Capacity',
    required: true,
    render: (value) => (value ? value : '-'),
  },
  {
    id: 'accessModes',
    title: 'Access Modes',
    render: (value) => value?.join(', '),
  },
  {
    id: 'spec.volumeMode',
    title: 'Volume Mode',
  },
  {
    id: 'spec.storageClassName',
    title: 'Storage Class',
    render: renderStorageClass,
  },
  {
    id: 'persistentVolume',
    title: 'Persistent Volume',
    required: true,
    render: (value) => (value ? renderPv(value) : '-'),
  },
  {
    id: 'creationTimestamp',
    title: 'Created',
    required: true,
    render: renderAgeFromTimestamp,
  },
  {
    id: 'metadata.ownerReferences',
    title: 'Owner Reference',
    render: renderOwnerReference,
    required: true,
  },
]

export default function Overview({ persistentVolumeClaim, loading }) {
  const classes = useStyles({})

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, persistentVolumeClaim)
  }, [persistentVolumeClaim])

  const overviewData = useMemo(
    () => [
      {
        label: 'Cluster',
        value: persistentVolumeClaim?.clusterName || '-',
      },
      {
        label: 'Namespace',
        value: persistentVolumeClaim?.namespace || '-',
      },
      {
        label: 'Age',
        value: persistentVolumeClaim?.age || '-',
      },
    ],
    [persistentVolumeClaim],
  )

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <Card title={persistentVolumeClaim?.name}>
            <Text variant="body2">Persistent Volume Claim</Text>
            <InfoBlocks data={overviewData} />
          </Card>
          <InfoCard
            items={metadata}
            title="Properties"
            footer={
              <LabelsAndAnnotationsSection
                entity={persistentVolumeClaim}
                resourceType="persistentVolumeClaim"
              />
            }
          />
        </div>
        <div className={classes.column}>
          <Card title="Status Conditions" withCustomBody>
            <Grid
              uniqueIdentifier="type"
              data={sort(sortByTransitionTime, persistentVolumeClaim?.status?.conditions || [])}
              columns={conditionsColumns}
              loading={loading}
              disableSorting
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}
