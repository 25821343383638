export enum CloudProviders {
  Aws = 'aws',
  Azure = 'azure',
  Gcp = 'gke',
  BareOS = 'local',
  PhysicalMachine = 'physical',
  VirtualMachine = 'virtual',
}

export enum ClusterCreateTypes {
  OneClick = 'one-click',
  Custom = 'custom',
  Capi = 'capi',
  EksCapi = 'eks-capi',
  SingleMaster = 'single-master',
  MultiMaster = 'multi-master',
}

export enum ClusterCreateTypeNames {
  'one-click' = 'One-Click',
  'custom' = 'Advanced Cluster Setup',
  'capi' = 'New Generation AWS Cluster',
  'eks-capi' = 'New Generation EKS Cluster',
  'single-master' = 'Single Master',
  'multi-master' = 'Multi-Master',
  'standard-cluster' = 'Standard Cluster',
}
