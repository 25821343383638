import { IGenericResource } from 'api-client/qbert.model'
import { IPersistentVolumeClaimsSelector } from '../../resources/persistent-volume-claims/model'
import { IPersistentVolumeSelector } from '../../resources/persistent-volume/model'

export enum CloudProviders {
  Aws = 'aws',
  Azure = 'azure',
  Gcp = 'gke',
  BareOS = 'local',
  PhysicalMachine = 'physical',
  VirtualMachine = 'virtual',
}

export interface IStorageClassSelector extends IGenericResource<StorageClassesItem> {
  clusterName: string
  isDefault: boolean
  kind: string
  persistentVolumes: IPersistentVolumeSelector[]
  persistentVolumeClaims: IPersistentVolumeClaimsSelector[]
  labels: StorageClassesItem['metadata']['labels']
  annotations: StorageClassesItem['metadata']['annotations']
  age: string
}

export interface StorageClassesResponse {
  kind: string
  apiVersion: string
  metadata: StorageClassesResponseMetadata
  items: StorageClassesItem[]
}

export interface StorageClassesItem {
  kind?: string
  apiVersion?: string
  metadata: ItemMetadata
  provisioner: string
  parameters?: { [key: string]: any }
  reclaimPolicy: string
  volumeBindingMode: string
  allowVolumeExpansion?: boolean
}

interface ItemMetadata {
  name: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
  labels: Record<string, string>
  annotations: Record<string, string>
}

interface StorageClassesResponseMetadata {
  resourceVersion: string
}

export enum IStoragePageTabs {
  CSI = 'csi-drivers',
  PV = 'persistent-volumes',
  PVC = 'persistent-volume-claims',
  StorageClasses = 'storage-classes',
  StorageProviders = 'storage-providers',
}

export enum IStorageDetailsPageTabs {
  Overview = 'overview',
  Events = 'events',
  Yaml = 'yaml',
}

export interface IEventSelector extends EventsItem {
  id: string
  name: string
}

export interface EventsItem {
  metadata: ItemMetadata
  eventTime: null | string
  reason: string
  regarding: Regarding
  note: string
  type: string
  deprecatedSource: DeprecatedSource
  deprecatedFirstTimestamp: string
  deprecatedLastTimestamp: string
  deprecatedCount?: number
}

interface DeprecatedSource {
  component?: string
  host?: string
}
interface ItemMetadata {
  name: string
  namespace: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
}

interface Regarding {
  kind: string
  namespace: string
  name: string
  uid: string
  apiVersion: string
  resourceVersion: string
  fieldPath?: string
}
