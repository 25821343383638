import { useAppSelector } from 'app/store'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import useListAction from 'core/hooks/useListAction'
import React, { useMemo } from 'react'
import { projectAs } from 'utils/fp'
import { listStorageClasses } from './new-actions'
import { storageClassSelector } from './selectors'

interface Props {
  clusterId: string
}

export default function StorageClassesPicklist({
  clusterId,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  const { loading } = useListAction(listStorageClasses, { params: { clusterId } })
  const storageClasses = useAppSelector(storageClassSelector)
  const items = useMemo(() => {
    return [...projectAs({ label: 'name', value: 'name' }, storageClasses)]
  }, [storageClasses])

  return (
    <AsyncDropdown
      name="storageClass"
      label="Storage Class"
      items={items}
      loading={loading}
      showAll={false}
      formField
      {...rest}
    />
  )
}
