import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'ramda'
import jsYaml from 'js-yaml'
import useReactRouter from 'use-react-router'
import ApiClient from 'api-client/ApiClient'

import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import DocumentMeta from 'core/components/DocumentMeta'
import { pathStrOr } from 'utils/fp'
import { routes } from 'core/utils/routes'
import useListAction from 'core/hooks/useListAction'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listConfigMaps, updateConfigMap } from 'k8s/components/resources/config-maps/actions'
import {
  configMapsSelector,
  IConfigMapSelector,
  IConfigMapsTabs,
} from 'k8s/components/resources/config-maps/selectors'
import ConfigMapsOverview from './ConfigMapsOverview'
import EntityYamlPage from 'k8s/components/common/entity/entity-yaml-page'
import OverviewActions from 'core/elements/overview/OverviewActions'
import DeleteConfigMapDialog from '../DeleteConfigMapDialog'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import { isReadonlyUser } from 'core/utils/helpers'

const { capi } = ApiClient.getInstance()

const actions = {
  rightActions: [
    {
      label: 'Delete',
      icon: 'trash-alt',
      DialogComponent: DeleteConfigMapDialog,
    },
  ],
}

const ConfigMapsDetailsPage = () => {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id } = match.params
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterId = k8sPluginGlobalPerfs?.clusterId
  const { loading, reload } = useListAction(listConfigMaps, { params: { clusterId } })
  const configMaps: IConfigMapSelector[] = useSelector(configMapsSelector)
  const configMap = useMemo(() => configMaps.find((configMap) => configMap.id === id), [
    id,
    configMaps,
  ])
  const { update, updating } = useUpdateAction(updateConfigMap)
  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        cluster: configMap?.clusterName,
        id: configMap?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [configMap?.clusterName, configMap?.name, id, clusterId])

  const getConfigMapYaml = useCallback(async () => {
    if (isEmpty(configMap)) return undefined
    return capi.getConfigMap(configMap.clusterId, configMap.namespace, configMap.name)
  }, [configMap])

  const handleConfigMapTemplateUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const namespace = pathStrOr('', 'metadata.namespace', body)
    const name = pathStrOr('', 'metadata.name', body)
    await update({
      id,
      clusterId,
      namespace,
      name,
      body,
    })
  }
  useEffect(() => {
    if (clusterId) {
      reload(true)
    }
  }, [clusterId])
  return (
    <>
      <DocumentMeta title="Config Maps Overview" breadcrumbs />
      {!isReadonlyUser() && (
        <div>
          <OverviewActions actions={actions} entity={configMap} />
        </div>
      )}
      <Tabs route={routes.kubernetes.resources.configMaps.details}>
        <Tab label="Overview" value={IConfigMapsTabs.Overview}>
          <ConfigMapsOverview configMap={configMap} loading={loading} reload={reload} />
        </Tab>
        <Tab label="YAML" value={IConfigMapsTabs.Yaml}>
          <EntityYamlPage
            entityType="Config Map"
            entityName={configMap?.name}
            getYamlFn={getConfigMapYaml}
            handleUpdate={handleConfigMapTemplateUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}

export default ConfigMapsDetailsPage
