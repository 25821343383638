import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import DocumentMeta from 'core/components/DocumentMeta'
import Theme from 'core/themes/model'
import ListNodeGroups from './ListNodeGroups'
import ListWorkerNodes from './ListWorkerNodes'
import { HeaderTitlePortal } from 'core/elements/header/portals'
import Breadcrumbs from 'core/elements/breadcrumbs'
import StatusCardsHeader from 'k8s/components/dashboard/StatusCardsHeader'

const ClusterCapacityAndHealth = () => {
  const classes = useStyles({})
  return (
    <>
      <DocumentMeta title="Capacity And Health Dashboard" />
      <HeaderTitlePortal>
        <Breadcrumbs />
      </HeaderTitlePortal>
      <div className={classes.customGridContainer}>
        <StatusCardsHeader />
        <ListNodeGroups />
        <ListWorkerNodes />
      </div>
    </>
  )
}

export default ClusterCapacityAndHealth

const useStyles = makeStyles((theme: Theme) => ({
  customGridContainer: {
    display: 'grid',
    gap: '16px',
  },
}))
