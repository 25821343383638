import { makeStyles } from '@material-ui/styles'
import InfoBlocks from 'core/components/entity-page/InfoBlocks'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { getFieldsForCard } from 'core/components/InfoPanel'
import Progress from 'core/components/progress/Progress'
import SimpleLink from 'core/components/SimpleLink'
import Card from 'core/elements/card'
import Text from 'core/elements/Text'
import usePluginRouter from 'core/hooks/usePluginRouter'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import { renderAgeFromTimestamp } from 'k8s/components/common/entity/helpers'
import InfoCard from 'k8s/components/common/entity/info-card'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import { IPersistentVolumeClaimDetailsPageTabs } from 'k8s/components/resources/persistent-volume-claims/model'
import React, { useMemo } from 'react'
import { renderStorageClass } from '../../persistent-volume-claims/details/Overview'

interface StyleProps {
  status?: string
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  overview: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutoRows: 'max-content',
  },
  status: {
    display: 'inline-flex',
    gap: 8,
    alignItems: 'center',
  },
  statusCircle: {
    color: ({ status }) => theme.components.badge[status]?.color,
  },
}))

const statusColorMap = {
  Bound: 'success',
  Failed: 'error',
  Pending: 'warning',
  Available: 'unknown',
  Released: 'unknown',
}

const StatusCircleValue = ({ value, status }) => {
  const classes = useStyles({ status })
  return (
    <div className={classes.status}>
      <FontAwesomeIcon size="xs" className={classes.statusCircle} solid>
        circle
      </FontAwesomeIcon>
      <span>{value}</span>
    </div>
  )
}

const PvcLink = ({ claimRef, pv }) => {
  const { currentPluginId } = usePluginRouter()

  return (
    <SimpleLink
      textVariant="body2"
      src={routes.kubernetes.resources.persistentVolumeClaims.details.path({
        cluster: pv?.clusterName,
        id: claimRef?.uid,
        tab: IPersistentVolumeClaimDetailsPageTabs.Overview,
      })}
    >
      {claimRef?.name}
    </SimpleLink>
  )
}

const renderPvc = (value, item) => {
  return <PvcLink claimRef={value} pv={item} />
}

const metadataFields = [
  {
    id: 'status.phase',
    title: 'Phase',
    render: (value) => (
      <StatusCircleValue value={value} status={statusColorMap[value] || 'unknown'} />
    ),
  },
  {
    id: 'capacity.storage',
    title: 'Capacity',
  },
  {
    id: 'accessModes',
    title: 'Access Modes',
    render: (value) => value?.join(', '),
  },
  {
    id: 'spec.volumeMode',
    title: 'Volume Mode',
  },
  {
    id: 'reclaimPolicy',
    title: 'Reclaim Policy',
  },
  {
    id: 'spec.storageClassName',
    title: 'Storage Class',
    render: renderStorageClass,
  },
  {
    id: 'spec.claimRef',
    title: 'Persistent Volume Claim',
    render: renderPvc,
  },
  {
    id: 'creationTimestamp',
    title: 'Created',
    required: true,
    render: renderAgeFromTimestamp,
  },
]

export default function Overview({ persistentVolume, loading }) {
  const classes = useStyles({})

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, persistentVolume)
  }, [persistentVolume])

  const overviewData = useMemo(
    () => [
      {
        label: 'Cluster',
        value: persistentVolume?.clusterName,
      },
      {
        label: 'Age',
        value: persistentVolume?.age,
      },
    ],
    [persistentVolume],
  )

  return (
    <Progress loading={loading}>
      <div className={classes.overview}>
        <div className={classes.column}>
          <Card title={persistentVolume?.name}>
            <Text variant="body2">Persistent Volume</Text>
            <InfoBlocks data={overviewData} />
          </Card>
          <InfoCard
            items={metadata}
            title="Properties"
            footer={
              <LabelsAndAnnotationsSection
                entity={persistentVolume}
                resourceType="persistentVolume"
              />
            }
          />
        </div>
        <div className={classes.column}></div>
      </div>
    </Progress>
  )
}
