import React from 'react'
import { dissoc } from 'ramda'

import { ISecretSelector } from '../model'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { updateSecret } from '../actions'

type Props = {
  open: boolean
  onClose: () => void
  secretData: { key: string; value: string }
  secret: ISecretSelector
  reload: (refetch: boolean) => void
}

export default function DeleteSecretDataDialog({
  secret,
  secretData,
  open = false,
  onClose,
  reload,
}: Props) {
  const { update, updating: updatingSecret } = useUpdateAction(updateSecret)

  const handleSecretDataDelete = async () => {
    const newData = dissoc(secretData?.key, secret?.data)

    const ops = []
    ops.push({
      op: 'replace',
      path: '/data',
      value: newData,
    })

    const { success } = await update({
      ...secret,
      body: ops,
      requestType: 'patch',
      contentType: 'application/json-patch+json',
    })
    if (success) {
      reload(true)
      onClose()
    }
  }

  return (
    <ConfirmationDialog
      title={`Delete Key "${secretData?.key}" ?`}
      text={`This will permanently delete this Key-Value pair`}
      // confirmText="Enable"
      onConfirm={handleSecretDataDelete}
      onCancel={onClose}
      loading={updatingSecret}
      open={open}
    />
  )
}
