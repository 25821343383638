import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import ProgressBar from 'pf9-ui-components/built/components/progress/ProgressBar'
import Grid, { GridViewColumn } from 'pf9-ui-components/built/elements/grid/Grid'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import React, { useEffect, useState } from 'react'

interface TenantInfo {
  name: string
  cores: { in_use: number; limit: number }
  ram: { in_use: number; limit: number }
  storage: { in_use: number; limit: number }
}

const getCapacityBarColor = (percent) => {
  if (percent < 40) return '#3BA489'
  if (percent < 70) return '#FFD7A2'
  return '#F2696A'
}

// If limit is -1 OR -0.00, it means unlimited
const isUnlimited = (limit) => String(limit).includes('-')

const CapacityBar = ({ value }) => {
  if (!value || value?.in_use === undefined || value?.limit === undefined) return null
  const percent = isUnlimited(value?.limit) ? 0 : (value?.in_use / value?.limit) * 100
  const label = `${value?.in_use} / ${isUnlimited(value?.limit) ? 'unlimited' : value?.limit}`
  return (
    <div>
      <Text variant="body2">{label}</Text>
      <ProgressBar percent={percent} showPercent={false} color={getCapacityBarColor(percent)} />
    </div>
  )
}

function convertMiBToGB(mib) {
  if (mib === undefined) return undefined
  const gb = mib * 0.001024
  return gb?.toFixed(2)
}

const MemoryCapacityBar = ({ value }) => {
  const convertedValue = {
    in_use: convertMiBToGB(value?.in_use),
    limit: convertMiBToGB(value?.limit),
  }
  return <CapacityBar value={convertedValue} />
}

const columns: GridViewColumn<TenantInfo>[] = [
  { key: 'name', label: 'Tenant' },
  { key: 'cores', label: 'vCPUs Available', CellComponent: CapacityBar },
  { key: 'ram', label: 'Memory (Gb) Available', CellComponent: MemoryCapacityBar },
  { key: 'storage', label: 'Storage (Gb) Available', CellComponent: CapacityBar },
]

interface TenantTableProps {
  data: TenantInfo[]
  selectedTenantName: string
  onTenantSelection: (tenant: string) => void
  required?: boolean
  loading?: boolean
}

export default function TenantsTable({
  data,
  selectedTenantName,
  onTenantSelection,
  required = true,
  loading = false,
}: TenantTableProps) {
  const classes = useStyles()
  const [selectedTenants, setSelectedTenants] = useState([])

  const handleTenantSelection = (selectedItems) => {
    const hasPreviousSelection = selectedTenants?.length > 0
    // Reselect the previous selection if the user tries to deselect a required field
    if (selectedItems?.length === 0 && required && hasPreviousSelection) {
      setSelectedTenants([...selectedTenants])
    } else {
      setSelectedTenants(selectedItems)
      onTenantSelection(selectedItems[0]?.name)
    }
  }

  useEffect(() => {
    if (selectedTenants[0]?.name === selectedTenantName) return
    setSelectedTenants(data?.filter((cluster) => cluster?.name === selectedTenantName))
  }, [selectedTenantName, selectedTenants[0]?.name, data])

  return (
    <div className={classes.table}>
      <Grid
        uniqueIdentifier="name"
        columns={columns}
        data={data}
        orderBy="vcpus"
        orderDirection="asc"
        emptyContent="No data found"
        disableRowSelection={false}
        multiSelection={false}
        selectedItems={selectedTenants}
        onSelectChange={handleTenantSelection}
        loading={loading}
        disableToolbar
        hidePagination
      />
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  table: {
    '& .progress-root': {
      minHeight: 'max-content',
    },
  },
}))
