import { useLocation } from 'react-router-dom'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import useListAction from 'core/hooks/useListAction'
import { Route } from 'core/plugins/route'
import useScopedPreferences from 'core/session/useScopedPreferences'
import AsyncDropdown from 'pf9-ui-components/built/elements/dropdown/AsyncDropdown'
import { isEmpty } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { listKaapiClusters } from './kaapi/clusters/actions'
import { useSelector } from 'react-redux'
import { kaapiClustersSelector } from './kaapi/clusters/selectors'
import { getClusterEndpoint } from './dashboard/helpers'

interface Option {
  label: string
  value: string
}

const isValidOption = (selectedCluster, options) =>
  !!selectedCluster && !!options.find((c) => c.value === selectedCluster)

export const getClusterNameFromUrl = () => {
  const matches = window.location.pathname.match(/\/clusters\/([^/]+)/)
  return matches?.[1] || ''
}

export default function GlobalClusterDropdown({ className = '' }) {
  const classes = useStyles()
  const { prefs, updatePrefs } = useScopedPreferences('k8sPluginGlobalParams')
  const { history } = useReactRouter() // `location` is now replaced by `useLocation`
  const location = useLocation() // New hook to track location changes

  const { loading: loadingKaapiClusters } = useListAction(listKaapiClusters, {
    initialLoadingState: true,
  })
  const clusterList = useSelector(kaapiClustersSelector)

  // Convert the list of clusters to a list of options for the dropdown
  const options = useMemo(
    () =>
      clusterList?.map((cluster) => ({
        label: cluster?.metadata?.name,
        value: cluster?.metadata?.name,
      })),
    [clusterList],
  )

  // If no clusters available redirect user to dashboard
  useEffect(() => {
    if (loadingKaapiClusters || clusterList?.length) return

    history.push('/ui/kubernetes/dashboard/clusters')
  }, [clusterList, loadingKaapiClusters])

  // If the cluster is specified in the URL, we should update the global cluster name
  // Else choose first cluster form the options
  useEffect(() => {
    const route = Route.getCurrentRoute(location.pathname)
    if (isEmpty(options) || !clusterList?.length) return

    let cluster = getClusterNameFromUrl()
    if (!cluster || !options?.find((opt) => opt.label === cluster)) {
      cluster = options?.[0]?.value
    }

    const matchingCluster = clusterList?.find((c) => c.metadata.name === cluster)
    if (matchingCluster) {
      updatePrefs({
        cluster,
        cluster_endpoint: getClusterEndpoint(matchingCluster?.spec?.controlPlaneEndpoint?.host),
        clusterId: matchingCluster?.metadata?.uid,
      })
    } else {
      updatePrefs({
        cluster: '',
        cluster_endpoint: '',
        clusterId: '',
      })
    }
    // If user is on cluster URL update the URL
    if (route?.url?.includes(':cluster')) updateUrl(cluster)
  }, [location.pathname, clusterList, options])

  const updateUrl = (cluster) => {
    const route = Route.getCurrentRoute(location.pathname)
    // No need to update the URL if it already contains the cluster name
    if (
      !route?.url ||
      !route?.url?.includes(':cluster') ||
      window.location.pathname.includes(`/${cluster}/`) // Added `/` to find exact match
    ) {
      return
    }

    // Redirect
    history.push(route.path({ cluster }))
  }

  const handleOnChange = (cluster) => {
    const matchingCluster = clusterList?.find((c) => c?.metadata?.name === cluster)
    updatePrefs({
      cluster,
      cluster_endpoint: getClusterEndpoint(matchingCluster?.spec?.controlPlaneEndpoint?.host),
      clusterId: matchingCluster?.metadata?.uid,
    })
    updateUrl(cluster)
  }

  return (
    <AsyncDropdown
      className={clsx(classes.dropdown, className)}
      items={options}
      value={prefs.cluster}
      onChange={handleOnChange}
      compact={false}
      icon="server"
      disabled={clusterList.length === 0}
    />
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  dropdown: {
    width: '224px',
  },
}))
