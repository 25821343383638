import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { prop } from 'ramda'

import ExternalLink from 'core/components/ExternalLink'
import { getNextUpgradeVersion, isReadonlyUser } from 'core/utils/helpers'
import { routes } from 'core/utils/routes'
import { extractSupportedK8sVersions } from 'k8s/components/cluster/deployment/form-components/helpers'
import { ClusterUpgradeStatus } from 'k8s/components/kaapi/cluster-upgrade/model'
import { kaapiConfigMapsSelector } from 'k8s/components/kaapi/config-maps/selectors'
import Theme from 'core/themes/model'
import useListAction from 'core/hooks/useListAction'
import { listKaapiConfigMaps } from 'k8s/components/kaapi/config-maps/actions'
import { RootState } from 'app/store'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'

const AvailableUpgrade = ({ cluster, className = '' }) => {
  const classes = useStyles({})
  const { history } = useReactRouter()
  const { activeKaapiTenant: namespace } = useSelector<RootState, SessionState>(
    prop(sessionStoreKey),
  )

  useListAction(listKaapiConfigMaps, {
    params: {
      namespace,
    },
    requiredParams: ['namespace'],
  })

  const configMaps = useSelector(kaapiConfigMapsSelector)
  const supportedK8sVersions = useMemo(() => extractSupportedK8sVersions(configMaps), [
    configMaps,
  ]).sort()
  const currentVersion = cluster?.version
  const nextUpgradeVersion = useMemo(
    () => getNextUpgradeVersion(currentVersion, supportedK8sVersions),
    [currentVersion, supportedK8sVersions],
  )
  const clusterUpgradeJob = (cluster?.upgradeJobs || []).find(
    (job) => job.clusterName === cluster.metadata.name,
  )
  const isUpgradeCompleted =
    !clusterUpgradeJob || clusterUpgradeJob.phase === ClusterUpgradeStatus.Completed

  if (currentVersion === nextUpgradeVersion) {
    return null
  }

  return (
    <div className={clsx(classes.extraContent, className)}>
      {!isReadonlyUser() && (
        <ExternalLink
          url=""
          underline="always"
          onClick={() => {
            history.push(
              routes.kubernetes.manage.clusterUpgrade.path({
                cluster: cluster?.metadata?.name,
              }),
            )
          }}
        >
          Upgrade Available ({nextUpgradeVersion})
        </ExternalLink>
      )}
    </div>
  )
}

export default AvailableUpgrade

const useStyles = makeStyles((theme: Theme) => ({
  extraContent: {
    '& span': {
      fontWeight: 600,
    },
  },
}))
