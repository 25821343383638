import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import { rbacApiGroupsToRules } from 'k8s/components/access-control/api-groups/new-actions'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import { trackEvent } from 'utils/tracking'

const { capi } = ApiClient.getInstance()

const uniqueIdentifier = 'metadata.uid'

export const clusterRoleActions = ActionsSet.make<DataKeys.ClusterRoles>({
  uniqueIdentifier,
  entityName: entityNamesByKey[DataKeys.ClusterRoles],
  cacheKey: DataKeys.ClusterRoles,
})

export const listClusterRoles = clusterRoleActions.add(
  new ListAction<DataKeys.ClusterRoles>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get cluster roles', params)
    return capi.getClusterRoles(params.clusterId)
  }),
)

export const createClusterRole = clusterRoleActions.add(
  new CreateAction<DataKeys.ClusterRoles>(async (data) => {
    Bugsnag.leaveBreadcrumb('Attempting to create cluster role', {
      clusterId: data.clusterId,
      name: data.name,
    })
    const rules = rbacApiGroupsToRules(data.rbac)
    const body = {
      apiVersion: 'rbac.authorization.k8s.io/v1',
      kind: 'ClusterRole',
      metadata: {
        name: data.name,
      },
      rules,
    }
    trackEvent('Create Cluster Role', { clusterId: data.clusterId, name: data.name })
    return capi.createClusterRole(data.clusterId, body)
  }),
)

export const updateClusterRole = clusterRoleActions.add(
  new UpdateAction<DataKeys.ClusterRoles>(async (data) => {
    Bugsnag.leaveBreadcrumb('Attempting to update cluster role', {
      clusterId: data.clusterId,
      name: data.name,
    })
    const rules = rbacApiGroupsToRules(data.rbac)
    const body = {
      apiVersion: 'rbac.authorization.k8s.io/v1',
      kind: 'ClusterRole',
      metadata: {
        name: data.name,
      },
      rules,
    }
    trackEvent('Update Cluster Role', { clusterId: data.clusterId, name: data.name })
    return capi.updateClusterRole(data.clusterId, data.name, body)
  }),
)

export const deleteClusterRole = clusterRoleActions.add(
  new DeleteAction<DataKeys.ClusterRoles>(async ({ clusterId, name, id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete cluster role', { id, clusterId, name })
    trackEvent('Delete Cluster Role', { id, clusterId, name })
    await capi.deleteClusterRole(clusterId, name)
  }),
)
