import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { prop, isEmpty } from 'ramda'
import jsYaml from 'js-yaml'
import useReactRouter from 'use-react-router'

import { clientActions } from 'core/client/clientReducers'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import DocumentMeta from 'core/components/DocumentMeta'
import ApiClient from 'api-client/ApiClient'
import { pathStrOr } from 'utils/fp'
import { routes } from 'core/utils/routes'
import useListAction from 'core/hooks/useListAction'
import useUpdateAction from 'core/hooks/useUpdateAction'
import {
  listCustomResourceDefinitions,
  listCustomResources,
  updateCustomResource,
} from 'k8s/components/resources/custom-resource-definitions/actions'
import EntityYamlPage from 'k8s/components/common/entity/entity-yaml-page'
import OverviewActions from 'core/elements/overview/OverviewActions'
import CustomResourceOverview from './CustomResourceOverview'
import DeleteCustomResourceDialog from './DeleteCustomResourceDialog'
import { clientStoreKey, ClientState } from 'core/client/clientReducers'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { customResourceDefinitionsSelector, customResourcesSelector } from '../../selectors'
import {
  ICustomResourceDefinitionSelector,
  ICustomResourceDetailsPageTabs,
  ICustomResourcesSelector,
} from '../../model'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import useScopedPreferences from 'core/session/useScopedPreferences'

const { capi } = ApiClient.getInstance()

const actions = {
  rightActions: [
    {
      label: 'Delete',
      icon: 'trash-alt',
      DialogComponent: DeleteCustomResourceDialog,
    },
  ],
}

// This is to control the overflow of YAML Editor due to lengthy field values in yaml
const useStyles = makeStyles<Theme>((theme: Theme) => ({
  codeMirrorStylingExpanded: {
    '& .CodeMirror-scroll': {
      width: `calc(100vw - 326px) !important`,
    },
  },
  codeMirrorStylingCollapsed: {
    '& .CodeMirror-scroll': {
      width: `calc(100vw - 112px) !important`,
    },
  },
}))

const CustomResourceDetailsPage = () => {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id, name, cluster } = match.params
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterId = k8sPluginGlobalPerfs?.clusterId
  const { frame: { sidebarState = 'expanded' } = {} } = useSelector(
    prop<string, ClientState>(clientStoreKey),
  )
  const sidenavExpanded = sidebarState === 'expanded'
  const classes = useStyles({})

  const { loading: loadingCustomResourceDefinitions } = useListAction(
    listCustomResourceDefinitions,
    {
      params: { clusterId },
      initialLoadingState: true,
    },
  )
  const customResourceDefinitions: ICustomResourceDefinitionSelector[] = useSelector(
    customResourceDefinitionsSelector,
  )
  const customResourceDefinition = useMemo(
    () =>
      customResourceDefinitions.find(
        (customResourceDefinition) => customResourceDefinition?.id === id,
      ),
    [id, customResourceDefinitions],
  )

  const {
    group = '',
    latestVersion: version = '',
    spec: { names: { plural: pluralName } } = { names: { plural: '' } },
  } = customResourceDefinition || {}

  const { loading: loadingCustomResource } = useListAction(listCustomResources, {
    params: {
      clusterId,
      group: customResourceDefinition?.group,
      version: customResourceDefinition?.latestVersion,
      pluralName: customResourceDefinition?.spec?.names?.plural,
    },
    initialLoadingState: true,
  })

  const customResources: ICustomResourcesSelector[] = useSelectorWithParams(
    customResourcesSelector,
    {
      clusterId,
      group: customResourceDefinition?.group,
      version: customResourceDefinition?.latestVersion,
      pluralName: customResourceDefinition?.spec?.names?.plural,
    },
  )
  // const customResources: ICustomResourcesSelector[] = useAppSelector(customResourcesSelector)
  const customResource = useMemo(
    () => customResources.find((customResource) => customResource?.name === name),
    [name, customResources],
  )

  const { update, updating } = useUpdateAction(updateCustomResource)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: customResourceDefinition?.clusterName || clusterId,
        id: customResourceDefinition?.name || id,
        name: customResource?.name || name,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [
    customResourceDefinition?.clusterName,
    customResourceDefinition?.name,
    customResource?.name,
    id,
    clusterId,
    name,
  ])

  const getCustomResourceYaml = useCallback(async () => {
    if (isEmpty(customResource)) return undefined
    return capi.getCustomResourceByName({
      ...customResource,
      group: group,
      version: version,
      pluralName: pluralName,
    })
  }, [customResource])

  const handleCustomResourceUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const name = pathStrOr('', 'metadata.name', body)
    await update({
      ...customResource,
      group: group,
      version: version,
      pluralName: pluralName,
      name,
      body,
    })
  }

  return (
    <>
      <DocumentMeta title="Custom Resource Overview" breadcrumbs />
      <div>
        <OverviewActions actions={actions} entity={customResource} />
      </div>
      <Tabs route={routes.kubernetes.resources.customResourceDefinitions.customResourceDetails}>
        <Tab label="Overview" value={ICustomResourceDetailsPageTabs.Overview}>
          <CustomResourceOverview
            customResource={customResource}
            loading={loadingCustomResource || loadingCustomResourceDefinitions}
          />
        </Tab>
        <Tab label="YAML" value={ICustomResourceDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType={customResource?.kind}
            entityName={customResource?.name}
            getYamlFn={getCustomResourceYaml}
            handleUpdate={handleCustomResourceUpdate}
            loading={loadingCustomResource || loadingCustomResourceDefinitions || updating}
            className={
              sidenavExpanded
                ? classes.codeMirrorStylingExpanded
                : classes.codeMirrorStylingCollapsed
            }
          />
        </Tab>
      </Tabs>
    </>
  )
}

export default CustomResourceDetailsPage
