import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { pick } from 'ramda'

import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { ArrayElement } from 'core/actions/Action'
import InferActionParams from 'core/actions/InferActionParams'
import DocumentMeta from 'core/components/DocumentMeta'
import CreateButton from 'core/components/buttons/CreateButton'
import ListContainer from 'core/containers/ListContainer'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import { HeaderPrimaryActionPortal } from 'core/elements/header/portals'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import usePluginRouter from 'core/hooks/usePluginRouter'
import { routes } from 'core/utils/routes'
import DataKeys from 'k8s/DataKeys'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { durationBetweenDates } from 'utils/misc'
import AddSecretModal from './AddSecretModal'
import DeleteSecretDialog from './DeleteSecretDialog'
import { listSecrets } from './actions'
import { ISecretsDetailsPageTabs } from './model'
import { secretsSelector } from './selectors'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import useNamespaceFilterDropdown from 'k8s/components/useNamespaceFilterDropdown'
import { isReadonlyUser } from 'core/utils/helpers'

type ModelDataKey = DataKeys.Secrets
type SelectorModel = ArrayElement<ReturnType<typeof secretsSelector>>
type ActionParams = InferActionParams<typeof listSecrets>
type Params = ActionParams & {
  masterNodeClusters: boolean
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId', 'namespace']
const defaultParams: Params & SortingState = {
  clusterId: '',
  namespace: null,
  masterNodeClusters: true,
  healthyClusters: true,
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>('Secrets', listTablePrefs)

const searchTargets = ['name', 'clusterName']
//TODO:: Once BE Up need to test it without pluginId
export const getSecretColumns = (pluginId = 'kubernetes'): GridViewColumn<SelectorModel>[] => {
  return [
    {
      key: 'name',
      label: 'Name',
      width: 'medium',
      CellComponent: createGridLinkCell({
        routeToFn: ({ clusterName, id }) =>
          routes.kubernetes.resources.secrets.details.path({
            cluster: clusterName,
            id,
            tab: ISecretsDetailsPageTabs.Overview,
          }),
      }),
    },
    { key: 'namespace', label: 'Namespace' },
    { key: 'type', label: 'Type' },
    {
      key: 'created',
      label: 'Age',
      formatFn: (value) => durationBetweenDates({ labels: ['d'] })(value),
    },
    { key: 'dataSize', label: 'Size' },
    {
      key: 'labels',
      label: 'Labels',
      disableSorting: true,
      CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
    },
    {
      key: 'annotations',
      label: 'Annotations',
      disableSorting: true,
      CellComponent: createResourceLabelsCell({ type: 'table', separator: ': ' }),
    },
  ]
}

export default function SecretsListPage() {
  const { currentPluginId } = usePluginRouter()
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterId = k8sPluginGlobalPerfs?.clusterId || ''
  const clusterName = k8sPluginGlobalPerfs?.cluster || ''
  const { message, loading, reload } = useListAction(listSecrets, {
    params: { clusterId },
    requiredParams,
  })
  const secrets = useSelector(secretsSelector)
  const columns = getSecretColumns(currentPluginId)

  const [selectedSecret, setSelectedSecret] = useState<SelectorModel>(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (secret) => {
        setSelectedSecret(secret)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
    },
  ]
  const { Component, selectedNs } = useNamespaceFilterDropdown()
  const filteredCustomResources =
    secrets?.filter((item) => selectedNs?.includes(item?.namespace)) || []
  useEffect(() => {
    if (clusterId) {
      reload(true)
    }
  }, [clusterId])
  return (
    <>
      <DocumentMeta title="Secrets" />
      <AddSecretModal />
      {showDeleteDialog && (
        <DeleteSecretDialog onClose={() => setShowDeleteDialog(false)} rows={[selectedSecret]} />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.Secrets}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={filteredCustomResources}
        columns={columns}
        addUrl={routes.kubernetes.resources.secrets.add.path({
          cluster: clusterName,
        })}
        addText={'Add Secret'}
        getParamsUpdater={getParamsUpdater}
        rowMenuItems={rowMenuItems}
        showItemsCountInLabel
        showRowMenuForSingleRowActions
        extraToolbarContent={Component}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
