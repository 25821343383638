import { makeStyles } from '@material-ui/styles'
import { useAppSelector } from 'app/store'
import DocumentMeta from 'core/components/DocumentMeta'
import PresetField from 'core/components/PresetField'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ModalForm from 'core/elements/modal/ModalForm'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import usePluginRouter from 'core/hooks/usePluginRouter'
import useUpdateAction from 'core/hooks/useUpdateAction'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import GroupMultiSelect from 'k8s/components/common/GroupMultiSelect'
import UserMultiSelect from 'k8s/components/common/UserMultiSelect'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import { propEq } from 'ramda'
import React, { useCallback, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { emptyObj } from 'utils/fp'
import { listRoleBindings, updateRoleBinding } from './actions'
import { IRbacRoleBindingSelector } from './model'
import { roleBindingsSelector } from './selectors'

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: 16,
  },
}))

const defaultParams = {
  users: [],
  groups: [],
}

export default function UpdateRoleBindingPage() {
  const classes = useStyles({})
  const { match, history } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )

  const roleBindingId = match?.params?.id
  const clusterId = k8sPluginGlobalPerfs?.clusterId || ''
  const clusterName = k8sPluginGlobalPerfs?.cluster || ''

  const { loading } = useListAction(listRoleBindings, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })
  const roleBindings = useAppSelector(roleBindingsSelector)

  const roleBinding = useMemo(
    () => roleBindings.find(propEq('id', roleBindingId)) || (emptyObj as IRbacRoleBindingSelector),
    [roleBindings, roleBindingId],
  )

  const { getParamsUpdater } = useParams(defaultParams)

  const { update, updating, reset, error: errorUpdatingRoleBinding } = useUpdateAction(
    updateRoleBinding,
  )

  const submitForm = useCallback(
    async (params) => {
      const { success } = await update({ ...roleBinding, ...params })
      if (success) handleClose()
    },
    [roleBinding],
  )
  const handleClose = () => {
    reset()
    history.push(
      routes.kubernetes.accessControl.roleBindings.path({
        cluster: clusterName,
        plugin: currentPluginId,
      }),
    )
  }

  return (
    <>
      <DocumentMeta title="Update Role Binding" bodyClasses={['form-view']} />

      <ModalForm
        route={routes.kubernetes.accessControl.editRoleBindings}
        title="Edit Role Binding"
        onSubmit={submitForm}
        onClose={handleClose}
        loading={loading}
        submitting={updating}
        error={errorUpdatingRoleBinding}
        loadingMessage={loading ? 'Loading role binding...' : 'Submitting form...'}
        submitTitle="Update Role Binding"
      >
        <>
          <FormFieldSection title="Basic Details" step={1}>
            <PresetField label="Name" value={roleBinding.name} />
            <PresetField label="Namespace" value={roleBinding.namespace} />
            {roleBinding.roleRef && <PresetField label="Role" value={roleBinding.roleRef.name} />}
          </FormFieldSection>
          {roleBinding.users && (
            <FormFieldSection title="Assign Users to this Binding" step={2}>
              <UserMultiSelect
                id="users"
                tooltip="Select users to assign this role"
                onChange={getParamsUpdater('users')}
                initialValue={roleBinding.users}
              />
            </FormFieldSection>
          )}
          {roleBinding.groups && (
            <FormFieldSection title="Assign Groups to this Binding" step={3}>
              <GroupMultiSelect
                id="groups"
                tooltip="Select groups to assign this role"
                onChange={getParamsUpdater('groups')}
                initialValue={roleBinding.groups}
              />
            </FormFieldSection>
          )}
        </>
      </ModalForm>
    </>
  )
}
