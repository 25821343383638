import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { uniq } from 'ramda'
import Bugsnag from 'utils/bugsnag'

const { capi } = ApiClient.getInstance()

export const rbacApiGroupsToRules = (apiGroups) => {
  const rules = []
  Object.keys(apiGroups).map((apiGroupName) => {
    Object.keys(apiGroups[apiGroupName]).map((resourceName) => {
      const rule = {
        apiGroups: apiGroupName === 'core' ? [''] : [apiGroupName],
        resources: [resourceName],
        verbs: Object.keys(apiGroups[apiGroupName][resourceName]),
      }
      rules.push(rule)
    })
  })
  return rules
}

export const apiResourceActions = ActionsSet.make<DataKeys.ApiResources>({
  uniqueIdentifier: ['name', 'clusterId'],
  indexBy: ['clusterId', 'apiGroup'],
  entityName: entityNamesByKey[DataKeys.ApiResources],
  cacheKey: DataKeys.ApiResources,
})

export const listApiResources = apiResourceActions.add(
  new ListAction<DataKeys.ApiResources, { clusterId: string | string[]; apiGroup: string }>(
    async ({ clusterId, apiGroup }) => {
      Bugsnag.leaveBreadcrumb('Attempting to load API resources', { clusterId, apiGroup })
      try {
        const response = await capi.getApiResourcesList({ clusterId, apiGroup })
        return response.resources
      } catch (err) {
        console.log(err, 'error')
        return []
      }
    },
  ),
)

export const apiGroupActions = ActionsSet.make<DataKeys.ApiGroups>({
  uniqueIdentifier: ['name', 'clusterId'],
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.ApiGroups],
  cacheKey: DataKeys.ApiGroups,
})

export const listApiGroups = apiGroupActions.add(
  new ListAction<DataKeys.ApiGroups, { clusterId: string }>(async ({ clusterId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to load API groups', { clusterId })
    const response = await capi.getApiGroupList()
    const apiGroups = response.groups
    const groupVersions = uniq(apiGroups.map((apiGroup) => apiGroup.preferredVersion.groupVersion))
    await Promise.all(
      groupVersions.map((apiGroup) => listApiResources.call({ clusterId, apiGroup })),
    )
    return apiGroups
  }),
)
