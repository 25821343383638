import { makeStyles } from '@material-ui/styles'
import { pathOr, pick } from 'ramda'
import React, { useEffect, useMemo } from 'react'

import { listTablePrefs, TablePrefsParams, UserPreferences } from 'app/constants'
import { useAppSelector } from 'app/store'
import { ArrayElement } from 'core/actions/Action'
import InferActionParams from 'core/actions/InferActionParams'
import DocumentMeta from 'core/components/DocumentMeta'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import ListContainer from 'core/containers/ListContainer'
import { createGridStatusCell } from 'core/elements/grid/cells/GridStatusCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import Text from 'core/elements/Text'
import Tooltip from 'core/elements/tooltip'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Theme from 'core/themes/model'
import { isDeccoEnv } from 'core/utils/helpers'
import { routes } from 'core/utils/routes'
import DataKeys from 'k8s/DataKeys'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import { emptyObj } from 'utils/fp'
import SetAsDefaultDialog from './SetAsDefaultDialog'
import { renderLabelsAsBadges } from '../../common/entity/labels-and-annotations/helpers'
import { deleteNamespace, listNamespaces } from './actions'
import AddNamespaceForm from './AddNamespacePage'
import DeleteNamespaceDialog from './delete-dialog'
import { getNamespaceStatus } from './helpers'
import { namespacesSelector } from './selectors'

type ModelDataKey = DataKeys.Namespaces
type SelectorModel = ArrayElement<ReturnType<typeof namespacesSelector>>
type ActionParams = InferActionParams<typeof listNamespaces>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {
  masterNodeClusters: boolean
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']
const defaultParams: Params = {
  clusterId: null,
  masterNodeClusters: true,
  healthyClusters: true,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'Namespaces',
  listTablePrefs,
)

const canDeleteNameSpace = ([namespace]) => namespace?.status !== 'Terminating'

const useStyles = makeStyles((theme: Theme) => ({
  starIcon: {
    color: theme.palette.yellow.main,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,
  },
  namespaceName: {
    display: 'inline-grid',
    gridAutoColumns: 'minmax(min-content, max-content)',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: 8,
  },
}))

const NamespaceName = ({ item: namespace }) => {
  const classes = useStyles()
  const { prefs, getUserPrefs } = useScopedPreferences('defaults')
  const namespaceDefaults = prefs[UserPreferences.Namespace] || emptyObj
  const defaultNamespace = pathOr('', [namespace.clusterId, 'namespace'], namespaceDefaults)
  const isDefaultNamespace = namespace.name === defaultNamespace

  useEffect(() => {
    getUserPrefs(UserPreferences.Cluster)
  }, [])

  return (
    <div className={classes.namespaceName}>
      {isDefaultNamespace && (
        <Tooltip message="Default Namespace">
          <FontAwesomeIcon className={classes.starIcon}>star</FontAwesomeIcon>
        </Tooltip>
      )}
      <Text variant="body2">{namespace.name}</Text>
    </div>
  )
}

const searchTargets = ['name', 'clusterName']

const columns: GridViewColumn<SelectorModel>[] = [
  // Disable cell memoization since it prevents the hook within the cell to fetch the updated user prefs
  { key: 'name', label: 'Name', width: 'medium', CellComponent: NamespaceName, memoizeCell: false },
  {
    key: 'status.phase',
    label: 'Status',
    CellComponent: createGridStatusCell({
      dataFn: getNamespaceStatus,
    }),
  },
  {
    key: 'spec.finalizers',
    label: 'Finalizers',
    render: renderLabelsAsBadges({ variant: 'default', defaultValue: 'No Finalizers' }),
  },
  { key: 'created', label: 'Created', CellComponent: DateAndTime },
]

export default function NamespacesListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterId = k8sPluginGlobalPerfs?.clusterId || ''
  const clusterName = k8sPluginGlobalPerfs?.cluster || ''
  const { message, loading, reload } = useListAction(listNamespaces)
  const data = useAppSelector(namespacesSelector)

  const batchActions = useMemo(
    () => [
      {
        cond: (selected) => selected.length === 1,
        icon: 'star',
        label: 'Set as Default',
        BatchActionButton: getGridDialogButton(SetAsDefaultDialog, {
          defaultsKey: UserPreferences.Namespace,
          type: 'namespace',
        }),
      },
    ],
    [],
  )
  const { fetchUserDefaults } = useScopedPreferences('defaults')

  useEffect(() => {
    if (isDeccoEnv) {
      fetchUserDefaults(UserPreferences.Namespace)
    }
  }, [])

  useEffect(() => {
    if (clusterId) {
      reload(true)
    }
  }, [clusterId])

  return (
    <>
      <DocumentMeta title="Namespaces" />
      <AddNamespaceForm />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.Namespaces}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={data}
        columns={columns}
        addUrl={routes.kubernetes.resources.namespaces.add.path({
          cluster: clusterName,
        })}
        addText={'Add Namespace'}
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteNamespace}
        deleteCond={canDeleteNameSpace}
        DeleteDialogComponent={DeleteNamespaceDialog}
        batchActions={batchActions}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
