import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { useAppSelector } from 'app/store'
import { ArrayElement } from 'core/actions/Action'
import InferActionParams from 'core/actions/InferActionParams'
import DocumentMeta from 'core/components/DocumentMeta'
import DateCell from 'core/components/listTable/cells/DateCell'
import ListContainer from 'core/containers/ListContainer'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import usePluginRouter from 'core/hooks/usePluginRouter'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { routes } from 'core/utils/routes'
import AddClusterRolePage from 'k8s/components/access-control/cluster-roles/AddClusterRolePage'
import UpdateClusterRolePage from 'k8s/components/access-control/cluster-roles/UpdateClusterRolePage'
import DataKeys from 'k8s/DataKeys'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import { pick } from 'ramda'
import React, { useEffect } from 'react'
import { deleteClusterRole, listClusterRoles } from './actions'
import { clusterRolesSelector } from './selectors'

type ModelDataKey = DataKeys.ClusterRoles
type SelectorModel = ArrayElement<ReturnType<typeof clusterRolesSelector>>
type ActionParams = InferActionParams<typeof listClusterRoles>

type Params = ActionParams & {
  clusterId?: string
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  healthyClusters: true,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'ClusterRoles',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName']

const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'name', label: 'Name' },
  { key: 'created', label: 'Created', render: (value) => <DateCell value={value} /> },
]

export default function ClusterRolesListPage() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { currentPluginId } = usePluginRouter()
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterId = k8sPluginGlobalPerfs?.clusterId || ''
  const clusterName = k8sPluginGlobalPerfs?.cluster || ''

  const { message, loading, reload } = useListAction(listClusterRoles, {
    params: { clusterId },
    requiredParams,
  })

  const data = useAppSelector(clusterRolesSelector)

  useEffect(() => {
    if (clusterId) {
      reload(true)
    }
  }, [clusterId])

  return (
    <>
      <DocumentMeta title="Cluster Roles" />
      <AddClusterRolePage />
      <UpdateClusterRolePage />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.ClusterRoles}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        data={data}
        getParamsUpdater={getParamsUpdater}
        addUrl={routes.kubernetes.accessControl.addClusterRoles.path({
          cluster: clusterName,
          plugin: currentPluginId,
        })}
        addText="Add Cluster Role"
        editUrl={(item, id) =>
          routes.kubernetes.accessControl.editClusterRoles.path({
            id,
            cluster: clusterName,
            plugin: currentPluginId,
          })
        }
        deleteAction={deleteClusterRole}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
