import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import { rbacApiGroupsToRules } from 'k8s/components/access-control/api-groups/new-actions'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import { trackEvent } from 'utils/tracking'

const { capi } = ApiClient.getInstance()

const uniqueIdentifier = 'metadata.uid'

export const roleActions = ActionsSet.make<DataKeys.KubeRoles>({
  uniqueIdentifier,
  entityName: entityNamesByKey[DataKeys.KubeRoles],
  cacheKey: DataKeys.KubeRoles,
})

export const listRoles = roleActions.add(
  new ListAction<DataKeys.KubeRoles>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get kube roles', params)
    return capi.getRoles(params.clusterId)
  }),
)

export const createRole = roleActions.add(
  new CreateAction<DataKeys.KubeRoles>(async (data) => {
    const rules = rbacApiGroupsToRules(data.rbac)
    const body = {
      apiVersion: 'rbac.authorization.k8s.io/v1',
      kind: 'Role',
      metadata: {
        name: data.name,
        namespace: data.namespace,
      },
      rules,
    }
    Bugsnag.leaveBreadcrumb('Attempting to create kube role', body)
    trackEvent('Create Kube Role', body)
    return capi.createRole(data.clusterId, data.namespace, body)
  }),
)

export const updateRole = roleActions.add(
  new UpdateAction<DataKeys.KubeRoles>(async (data) => {
    const rules = rbacApiGroupsToRules(data.rbac)
    const body = {
      apiVersion: 'rbac.authorization.k8s.io/v1',
      kind: 'Role',
      metadata: {
        name: data.name,
      },
      rules,
    }
    Bugsnag.leaveBreadcrumb('Attempting to update kube role', body)
    trackEvent('Update Kube Role', body)
    return capi.updateRole(data.clusterId, data.namespace, data.name, body)
  }),
)

export const deleteRole = roleActions.add(
  new DeleteAction<DataKeys.KubeRoles>(async ({ clusterId, namespace, name }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete kube role', { clusterId, name, namespace })
    const { capi } = ApiClient.getInstance()
    trackEvent('Delete Kube Role', { clusterId, name, namespace })
    await capi.deleteRole(clusterId, namespace, name)
  }),
)
