import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { trackEvent } from 'utils/tracking'
import jsYaml from 'js-yaml'
import { pathStr } from 'utils/fp'

const { capi } = ApiClient.getInstance()

export const persistentVolumeActions = ActionsSet.make<DataKeys.PersistentVolumes>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.PersistentVolumes],
  cacheKey: DataKeys.PersistentVolumes,
})

export const listPersistentVolumes = persistentVolumeActions.add(
  new ListAction<DataKeys.PersistentVolumes, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Persistent Volumes', params)
    return capi.getPersistentVolumes(params.clusterId)
  }),
)

export const createPersistentVolume = persistentVolumeActions.add(
  new CreateAction<DataKeys.PersistentVolumes, { clusterId: string; yaml: string }>(
    async ({ clusterId, yaml }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new persistent volume', {
        clusterId,
        yaml,
      })
      const body = jsYaml.load(yaml)
      const created = await capi.createPersistentVolume(clusterId, body)
      trackEvent('Create New Persistent Volume', {
        clusterId,
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)

export const updatePersistentVolume = persistentVolumeActions.add(
  new UpdateAction<
    DataKeys.PersistentVolumes,
    {
      clusterId: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update persistent volume', {
      clusterId,
      name,
      body,
    })
    const updateFn =
      requestType === 'patch' ? capi.patchPersistentVolume : capi.updatePersistentVolume
    const updatedPersistentVolume = await updateFn({
      clusterId,
      name,
      body,
      contentType,
    })
    trackEvent('Update Persistent Volume', { clusterId, name })
    return updatedPersistentVolume
  }),
)

export const deletePersistentVolume = persistentVolumeActions.add(
  new DeleteAction<DataKeys.PersistentVolumes, { clusterId: string; name: string; id: string }>(
    async ({ clusterId, name, id }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete persistent volume', {
        id,
        clusterId,
        name,
      })
      await capi.deletePersistentVolume(clusterId, name)
      trackEvent('Delete Persistent Volume', { clusterId, name, id })
    },
  ),
)
