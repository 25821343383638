import { IGenericResource } from 'api-client/qbert.model'

export enum ISecretsDetailsPageTabs {
  Overview = 'overview',
  Yaml = 'yaml',
}

export enum SecretTypes {
  Opaque = 'Opaque',
  ServiceAccountToken = 'kubernetes.io/service-account-token',
  DockerCfg = 'kubernetes.io/dockercfg',
  DockerConfigJson = 'kubernetes.io/dockerconfigjson',
  BasicAuth = 'kubernetes.io/basic-auth',
  SshAuth = 'kubernetes.io/ssh-auth',
  Tls = 'kubernetes.io/tls',
  BootstrapToken = 'bootstrap.kubernetes.io/token',
}

export interface SecretsResponse {
  kind: string
  apiVersion: string
  metadata: SecretsResponseMetadata
  items: SecretItem[]
}

export interface SecretItem {
  metadata: ItemMetadata
  data?: Record<string, string>
  type?: string
  immutable?: boolean
}

interface ItemMetadata {
  name: string
  namespace: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
  labels?: Record<string, string>
  ownerReferences?: OwnerReference[]
  annotations?: Record<string, string>
}

export interface OwnerReference {
  apiVersion: string
  kind: string
  name: string
  uid: string
  controller: boolean
  blockOwnerDeletion: boolean
}

interface SecretsResponseMetadata {
  resourceVersion: string
}
export interface ISecretSelector extends IGenericResource<SecretItem> {
  clusterName: string
  clusterId: string
  dataSize: number
  ownerReferences: SecretItem['metadata']['ownerReferences']
  age?: string
  labels: SecretItem['metadata']['labels']
  annotations: SecretItem['metadata']['annotations']
}
