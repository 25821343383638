import { makeStyles } from '@material-ui/styles'
import { useAppSelector } from 'app/store'
import DocumentMeta from 'core/components/DocumentMeta'
import PresetField from 'core/components/PresetField'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ModalForm from 'core/elements/modal/ModalForm'
import useListAction from 'core/hooks/useListAction'
import usePluginRouter from 'core/hooks/usePluginRouter'
import useUpdateAction from 'core/hooks/useUpdateAction'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import RbacChecklist from 'k8s/components/access-control/RbacChecklist'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import { propEq } from 'ramda'
import React, { useCallback, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { emptyObj } from 'utils/fp'
import { listClusterRoles, updateClusterRole } from './actions'
import { IRbacClusterRoleSelector } from './model'
import { clusterRolesSelector } from './selectors'

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: 16,
  },
}))

export default function UpdateClusterRolePage() {
  const classes = useStyles({})
  const { match, history } = useReactRouter()
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterRoleId = match?.params?.id
  const clusterId = k8sPluginGlobalPerfs?.clusterId || ''
  const { currentPluginId } = usePluginRouter()

  const { loading } = useListAction(listClusterRoles, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })
  const clusterRoles = useAppSelector(clusterRolesSelector)

  const clusterRole = useMemo(
    () => clusterRoles.find(propEq('id', clusterRoleId)) || (emptyObj as IRbacClusterRoleSelector),
    [clusterRoles, clusterRoleId],
  )

  const { update, updating, reset, error: errorUpdatingClusterRole } = useUpdateAction(
    updateClusterRole,
  )

  const submitForm = useCallback(
    async (params) => {
      const { success } = await update({ ...clusterRole, ...params })
      if (success) handleClose()
    },
    [clusterRole],
  )
  const handleClose = () => {
    reset()
    history.push(
      routes.kubernetes.accessControl.clusterRoles.path({
        cluster: k8sPluginGlobalPerfs?.cluster,
        plugin: currentPluginId,
      }),
    )
  }
  return (
    <>
      <DocumentMeta title="Update Cluster Role" bodyClasses={['form-view']} />
      <ModalForm
        route={routes.kubernetes.accessControl.editClusterRoles}
        title="Edit Cluster Role"
        onSubmit={submitForm}
        onClose={handleClose}
        loading={loading}
        submitting={updating}
        error={errorUpdatingClusterRole}
        loadingMessage={loading ? 'Loading cluster role...' : 'Submitting form...'}
        submitTitle="Update Cluster Role"
      >
        <>
          <FormFieldSection title="Basic Details" step={1}>
            <PresetField label="Name" value={clusterRole.name} />
          </FormFieldSection>
          {clusterRole.clusterId && (
            <FormFieldSection title="API Access / Permissions" step={2}>
              <RbacChecklist
                id="rbac"
                clusterId={clusterRole.clusterId}
                initialRules={clusterRole.rules}
              />
            </FormFieldSection>
          )}
        </>
      </ModalForm>
    </>
  )
}
