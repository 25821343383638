import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import Modal from 'core/elements/modal'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { routes } from 'core/utils/routes'
import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import { deletePersistentVolume } from './actions'

export default function DeletePersistentVolumeDialog({ rows: [persistentVolume], onClose }) {
  const { history, match } = useReactRouter()
  const { cluster } = match.params
  const { update: deleteFn, updating: deletingPersistentVolume } = useUpdateAction(
    deletePersistentVolume,
  )
  const title = `Permanently delete "${persistentVolume?.name}"?`
  const handleDelete = useCallback(async () => {
    await deleteFn(persistentVolume)
    onClose(true)
    history.push(routes.kubernetes.resources.persistentVolumes.list.path({ cluster }))
  }, [persistentVolume])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={title}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingPersistentVolume}>
            Don't Delete
          </Button>
          <Button onClick={handleDelete} loading={deletingPersistentVolume}>
            Delete Persistent Volume
          </Button>
        </>
      }
    >
      <Text variant="body2">This action cannot be undone, are you sure you want to do this?</Text>
    </Modal>
  )
}
