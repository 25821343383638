import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import jsYaml from 'js-yaml'

import Theme from 'core/themes/model'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import Text from 'core/elements/Text'
import RadioSelectableCard from 'k8s/components/common/RadioSelectableCard'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createSecret } from './actions'
import SecretYamlParamFields from './SecretYamlParamFields'
import SecretParamFields from './SecretParamFields'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'

export default function AddSecretModal() {
  const classes = useStyles()
  const { history, match } = useReactRouter()
  const { cluster } = match.params
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterId = k8sPluginGlobalPerfs?.clusterId || ''

  const defaultParams = {
    method: 'form',
    clusterId: null,
    name: '',
    namespace: null,
    type: null,
    immutable: undefined,
    data: {},
    stringData: {},
    yaml: '',
  }

  const { params, updateParams, setParams } = useParams<{
    method: string
    clusterId: string
    name: string
    namespace: string
    type?: string
    immutable?: boolean
    data?: any
    stringData?: any
    yaml?: string
  }>(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(createSecret)

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.kubernetes.resources.secrets.list.path({ cluster }))
  }

  const submitForm = useCallback(async () => {
    if (params?.method === 'yaml') {
      const body = jsYaml.load(params?.yaml)
      const { success } = await update({
        clusterId: clusterId,
        namespace: params?.namespace || body?.metadata?.namespace,
        body,
      })
      if (success) handleClose()
    } else {
      const body = {
        apiVersion: 'v1',
        kind: 'Secret',
        metadata: {
          name: params?.name,
        },
        type: params?.type,
        data: params?.data,
        stringData: params?.stringData,
        immutable: params?.immutable,
      }

      const { success } = await update({
        clusterId: clusterId,
        namespace: params?.namespace,
        body,
      })
      if (success) handleClose()
    }
  }, [params, handleClose])

  return (
    <ModalForm
      route={routes.kubernetes.resources.secrets.add}
      title="Add Secret"
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      maxWidth={528}
    >
      <div className={classes.container}>
        <Text variant="body2">To start configuration choose the method of creation.</Text>

        <div className={classes.radioCards}>
          <Text variant="caption2" className={classes.radioText}>
            Select one of the below methods
          </Text>
          <RadioSelectableCard
            onClick={() => updateParams({ method: 'form' })}
            label="Fill in the form"
            active={params?.method === 'form'}
            subtitle=""
          />
          <RadioSelectableCard
            onClick={() => updateParams({ method: 'yaml' })}
            label="Upload or paste YAML"
            active={params?.method === 'yaml'}
            subtitle=""
          />
        </div>
        <hr className={classes.divider} />
        {params?.method === 'yaml' && (
          <SecretYamlParamFields params={params} updateParams={updateParams} />
        )}
        {params?.method === 'form' && (
          <div className={classes.fields}>
            <SecretParamFields params={params} updateParams={updateParams} />
          </div>
        )}
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: '16px 40px 40px 32px',
    display: 'grid',
    gap: 32,
  },
  radioText: {
    textTransform: 'uppercase',
    color: theme.components.typography.passive,
  },
  radioCards: {
    display: 'grid',
    gap: 16,
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    width: '100%',
  },
  fields: {
    display: 'grid',
    gap: 24,
  },
}))
