// libs
import { makeStyles } from '@material-ui/styles'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { prop } from 'ramda'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'

// Components
import DocumentMeta from 'core/components/DocumentMeta'
import Text from 'core/elements/Text'
import Theme from 'core/themes/model'

import ApiClient from 'api-client/ApiClient'
import { IClusterStatus } from './model'
import ConfirmationDialog from 'core/components/ConfirmationDialog'
import Progress from 'core/components/progress/Progress'
import StatusBar, { getClusterUpgradeTitle } from 'core/components/status-bar/StatusBar'
import Breadcrumbs from 'core/elements/breadcrumbs'
import Button from 'core/elements/button/Button'
import { HeaderRefreshPortal, HeaderTitlePortal } from 'core/elements/header/portals'
import useListAction from 'core/hooks/useListAction'
import useUpdateAction from 'core/hooks/useUpdateAction'
import downloadFile from 'core/utils/downloadFile'
import StatusCardsHeader from 'k8s/components/dashboard/StatusCardsHeader'
import ClusterUpgradeModal from 'k8s/components/kaapi/cluster-upgrade/ClusterUpgradeModal'
import {
  clusterUpgradeStatusMap,
  ClusterUpgradeSteps,
} from 'k8s/components/kaapi/cluster-upgrade/model'
import { decodeStr, formatDate } from 'utils/misc'
import { deleteKaapiCluster, listKaapiClusters } from '../../components/kaapi/clusters/actions'
import { kaapiClustersOverviewSelector } from '../../components/kaapi/clusters/selectors'
import ClusterOverview from './ClusterOverview'
import { isTimeExpired } from './helpers'
import ResourceUtilizationCard from './ResourceUtilizationCard'
import DeployNewCluster from '../../components/clusters-dashboard/DeployNewCluster'
import { routes } from 'core/utils/routes'
import { useToast } from 'core/providers/ToastProvider'
import { MessageTypes } from 'core/components/notifications/model'

const { kubernetes } = ApiClient.getInstance()

const ClusterDetails = () => {
  const classes = useStyles({})
  const { history } = useReactRouter()
  const { prefs: k8sPluginGlobalPerfs, updatePrefs } = useScopedPreferences('k8sPluginGlobalParams')
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const showToast = useToast()

  // Maintain the current cluster name in a ref to avoid unnecessary reloads
  const clusterName = useRef(k8sPluginGlobalPerfs?.cluster)

  // Fetch the list of clusters
  const { loading, reload } = useListAction(listKaapiClusters)
  const clusters = useSelector(kaapiClustersOverviewSelector)
  const cluster = clusters?.find(
    (cluster) => cluster.metadata.name === k8sPluginGlobalPerfs?.cluster,
  )
  const clusterUpgradeJob = (cluster?.upgradeJobs || []).find(
    (job) => job.clusterName === cluster.metadata.name,
  )

  // Delete cluster action
  const { update: deleteKaapiClusterFn, updating: deletingKaapiCluster } = useUpdateAction(
    deleteKaapiCluster,
  )

  // Reload the list of clusters when the cluster changes
  useEffect(() => {
    if (clusterName.current !== k8sPluginGlobalPerfs?.cluster) {
      clusterName.current = k8sPluginGlobalPerfs?.cluster
      reload(true, false)
    }
  }, [clusterName, k8sPluginGlobalPerfs?.cluster])

  const handleDownload = async () => {
    try {
      const kubeconfig = await kubernetes.downloadKubeconfig({ clusterName: cluster.metadata.name })
      if (kubeconfig?.['oidc-login.conf']) {
        downloadFile({
          filename: `${cluster.metadata.name}.yaml`,
          contents: decodeStr(kubeconfig?.['oidc-login.conf']),
        })
      } else {
        showToast('Failed to download kubeconfig', MessageTypes.error)
      }
    } catch (e) {
      showToast('Failed to download kubeconfig', MessageTypes.error)
    }
  }

  const deleteCluster = async () => {
    setShowDeleteDialog(false)
    await deleteKaapiClusterFn({ name: cluster?.metadata?.name })
    reload(true, false)
  }

  const completionTime = clusterUpgradeJob?.completionTime
    ? formatDate(clusterUpgradeJob?.completionTime)
    : '-'
  const expectedCompletionTime = clusterUpgradeJob?.expectedCompletionTime
    ? formatDate(clusterUpgradeJob?.expectedCompletionTime)
    : '-'

  const statusDetails = {
    status: (clusterUpgradeStatusMap[clusterUpgradeJob?.phase] as IClusterStatus) || 'loading',
    title: getClusterUpgradeTitle(clusterUpgradeJob?.phase),
    error: clusterUpgradeJob,
    subtitles: [
      {
        label: 'Target K8s Version',
        value: (
          <div className={classes.currentVersion}>{clusterUpgradeJob?.targetVersion || '-'}</div>
        ),
      },
      {
        label: 'Completed',
        value: `${clusterUpgradeJob?.completedSteps || '0'} of ${
          Object.keys(ClusterUpgradeSteps).length
        } steps`,
      },
      {
        label: 'Started',
        value: formatDate(clusterUpgradeJob?.startTime) || '-',
      },
      {
        label: clusterUpgradeJob?.phase === 'Completed' && 'Completed',
        value: clusterUpgradeJob?.phase === 'Completed' && completionTime,
      },
      // {
      //   label: clusterUpgradeJob?.phase === 'Completed' ? 'Completed' : 'Expected Completion',
      //   value: clusterUpgradeJob?.phase === 'Completed' ? completionTime : expectedCompletionTime,
      // },
    ],
  }
  const isTimeWithinTwoDays = useMemo(
    () => isTimeExpired(clusterUpgradeJob?.status?.completionTime),
    [clusterUpgradeJob?.status?.completionTime],
  )
  const isCompletedAndExpired = clusterUpgradeJob?.phase === 'Completed' && !isTimeWithinTwoDays
  const shouldShowStatusBar =
    clusterUpgradeJob &&
    !isCompletedAndExpired &&
    !((k8sPluginGlobalPerfs?.hideUpgradeSuccessBanner as Array<string>) || [])?.includes(
      cluster?.metadata?.name,
    )

  const handleRetryClick = () => {
    history.push(routes.kubernetes.manage.clusterUpgrade.path({ cluster: cluster?.metadata?.name }))
  }

  return (
    <>
      <HeaderTitlePortal>
        <Breadcrumbs />
      </HeaderTitlePortal>
      <HeaderRefreshPortal>
        <Button icon="refresh" variant="secondary" onClick={reload}>
          Refresh
        </Button>
      </HeaderRefreshPortal>
      <DeployNewCluster>
        <Button icon="download" variant="secondary" onClick={handleDownload}>
          Download Kubeconfig
        </Button>
      </DeployNewCluster>
      <Progress loading={loading}>
        <DocumentMeta title="Dashboard" />
        {clusters.length > 0 ? (
          <>
            <ConfirmationDialog
              open={showDeleteDialog}
              text={`Delete cluster - ${cluster?.metadata?.name} ?`}
              onCancel={() => setShowDeleteDialog(false)}
              onConfirm={deleteCluster}
              loading={deletingKaapiCluster}
            />

            {shouldShowStatusBar && (
              <StatusBar
                statusDetails={statusDetails}
                onSeeDetailsClick={() =>
                  history.push(
                    routes.kubernetes.manage.upgradeStatus.path({
                      cluster: cluster?.metadata?.name,
                    }),
                  )
                }
                onClose={() => {
                  updatePrefs({
                    hideUpgradeSuccessBanner: [
                      ...((k8sPluginGlobalPerfs?.hideUpgradeSuccessBanner as Array<string>) || []),
                      cluster.metadata.name,
                    ],
                  })
                }}
                onRetryClick={handleRetryClick}
              />
            )}
            <ClusterUpgradeModal
              onClose={() =>
                history.push(
                  routes.kubernetes.manage.overview.path({
                    cluster: cluster?.metadata?.name,
                  }),
                )
              }
            />
            <div className={classes.customGridContainer}>
              <div>
                <ClusterOverview deleteClusterAction={() => setShowDeleteDialog(true)} />
              </div>
              <div>
                <StatusCardsHeader />
                <div className={classes.headerSubText}>
                  <Text variant="caption1" className={classes.headerText}>
                    RESOURCE UTILIZATION
                  </Text>
                </div>
                <div className={classes.divider} />
                <ResourceUtilizationCard />
              </div>
            </div>
          </>
        ) : (
          <Text variant="body1">No clusters found</Text>
        )}
      </Progress>
    </>
  )
}

export default ClusterDetails

const useStyles = makeStyles((theme: Theme) => ({
  customGridContainer: {
    display: 'grid',
    gridTemplateColumns: '370px 1fr',
    gridGap: '24px',
  },
  headerText: {
    color: theme.components.card.passiveText,
  },
  headerSubText: {
    paddingTop: '24px',
  },
  divider: {
    border: '0',
    height: '1px',
    margin: '16px 0px',
    background: '#e6e6ea',
  },
  currentVersion: {
    display: 'flex',
    alignItems: 'center',
  },
}))
