import { makeStyles } from '@material-ui/styles'
import DocumentMeta from 'core/components/DocumentMeta'
import NoContentMessage from 'core/components/NoContentMessage'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import TextField from 'core/components/validatedForm/TextField'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import usePluginRouter from 'core/hooks/usePluginRouter'
import useUpdateAction from 'core/hooks/useUpdateAction'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import React from 'react'
import useReactRouter from 'use-react-router'
import { createClusterRole } from './actions'
import RbacChecklist from '../RbacChecklist'

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: 16,
  },
}))

const defaultParams = {
  clusterId: '', // Initialize here to prevent desync with formContext
  rbac: {},
}

export default function AddClusterRoleForm() {
  const classes = useStyles({})
  const { history } = useReactRouter()
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const { currentPluginId } = usePluginRouter()
  const clusterId = k8sPluginGlobalPerfs?.clusterId || ''
  const clusterName = k8sPluginGlobalPerfs?.cluster || ''
  const { update, updating, reset, error: errorCreatingClusterRole } = useUpdateAction(
    createClusterRole,
  )

  const submitForm = async (params) => {
    const { success } = await update(params)
    if (success) handleClose()
  }
  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(
      routes.kubernetes.accessControl.clusterRoles.path({
        cluster: clusterName,
        plugin: currentPluginId,
      }),
    )
  }

  return (
    <>
      <DocumentMeta title="Add Cluster Role" bodyClasses={['form-view']} />
      <ModalForm
        route={routes.kubernetes.accessControl.addClusterRoles}
        title="Add Cluster Role"
        onSubmit={submitForm}
        onClose={handleClose}
        submitting={updating}
        error={errorCreatingClusterRole}
        submitTitle="Add Cluster Role"
      >
        <>
          <FormFieldSection title="Basic Details" step={1}>
            <TextField id="name" label="Name" required />
          </FormFieldSection>
          <FormFieldSection title="API Access / Permissions" step={2}>
            {clusterId && (
              <RbacChecklist
                id="rbac"
                clusterId={clusterId}
                onChange={getParamsUpdater('rbac')}
                value={params.rbac}
              />
            )}
            {!clusterId && (
              <NoContentMessage message="Please choose a cluster to define it's API access permissions." />
            )}
          </FormFieldSection>
        </>
      </ModalForm>
    </>
  )
}
