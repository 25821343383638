import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import Text from 'core/elements/Text'
import VirtualMachineFlavorsTable from 'k8s/components/cluster/deployment/form-components/VirtualMachineFlavorsTable'
import FontAwesomeIcon from 'pf9-ui-components/built/components/FontAwesomeIcon'
import QuantitySelector from 'pf9-ui-components/built/components/QuantitySelector'
import DropdownField from 'pf9-ui-components/built/components/validatedForm/DropdownField'
import ToggleSwitchField from 'pf9-ui-components/built/components/validatedForm/ToggleSwitchField'
import AsyncDropdown from 'pf9-ui-components/built/elements/dropdown/AsyncDropdown'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import React, { useEffect, useMemo, useState } from 'react'

interface AddNodeGroupsProps {
  nodePool: any
  vmFlavors: any[]
  storagePools: any[]
  networks: any[]
  onChange: (value: Record<string, any>) => void
  onDeleteNodePool: (id: number) => void
  showDeleteButton?: boolean
  errors?: Errors
}

interface Errors {
  [fieldId: string]: string
}

const AddNodeGroups = ({
  nodePool,
  onChange,
  vmFlavors,
  storagePools,
  networks,
  showDeleteButton = false,
  onDeleteNodePool,
  errors = undefined,
}: AddNodeGroupsProps) => {
  const [collapseCard, setCollapseCard] = useState(false)
  const classes = useStyles({ collapseCard, showDeleteButton })
  const [autoscalingError, setAutoscalingError] = useState('')

  const storageOptions = useMemo(
    () =>
      storagePools
        .filter((storagePool) => !!storagePool.name)
        .map((storagePool) => ({
          label: storagePool.name,
          value: storagePool.name,
        })),
    [storagePools],
  )

  const networkOptions = useMemo(
    () =>
      networks?.map((network) => ({
        label: network?.name,
        value: network?.id,
      })),
    [networks],
  )

  const subnetOptionsByNetworkId = useMemo(
    () =>
      networks.reduce((acc, network) => {
        acc[network?.id] = network?.subnetDetails?.map((subnet) => ({
          label: `${subnet?.name}: ${subnet?.cidr}`,
          value: subnet?.id,
        }))
        return acc
      }, {} as Record<string, { label: string; value: string }[]>),
    [networks],
  )

  const handleVmChange = (value) => {
    onChange(value)
  }

  const handleAutoscaling = (enableAutoscaling) => {
    onChange(
      enableAutoscaling
        ? { autoscaling: { max: nodePool?.replicas || 1 } }
        : { autoscaling: undefined },
    )
  }

  const handleMaxNodesChange = (value) => {
    onChange({ autoscaling: { max: value } })
  }

  useEffect(() => {
    if (nodePool.autoscaling?.max < nodePool?.replicas) {
      setAutoscalingError(
        'The value must be greater than or equal to the selected amount of instance types.',
      )
    } else {
      setAutoscalingError('')
    }
  }, [nodePool.autoscaling?.max, nodePool.replicas])

  return (
    <div className={classes.content}>
      <div className={clsx(classes.vmSelection, classes.borderBottom)}>
        <VirtualMachineFlavorsTable
          data={vmFlavors}
          selectedVmFlavor={nodePool?.flavor}
          selectedVmQuantity={nodePool?.replicas}
          onChange={handleVmChange}
        />
        {errors && errors.flavor && <ErrorMessage>{errors.flavor}</ErrorMessage>}
      </div>
      <div className={clsx(classes.autoscalingFields, classes.borderBottom)}>
        <ToggleSwitchField
          id={`autoscaling-${nodePool.id}`}
          label="Automatically scale this Node Pool"
          onChange={handleAutoscaling}
        />
        {nodePool.autoscaling !== undefined && (
          <div className={classes.quantitySelector}>
            <Text variant="body2">Max Nodes</Text>
            <QuantitySelector
              id={`maxNodes-${nodePool.id}`}
              value={nodePool.autoscaling.max || 0}
              onChange={handleMaxNodesChange}
              min={nodePool.replicas}
            />
            {autoscalingError && <ErrorMessage>{autoscalingError}</ErrorMessage>}
            {errors && errors.autoscaling && <ErrorMessage>{errors.autoscaling}</ErrorMessage>}
          </div>
        )}
      </div>
      <div className={clsx(classes.dropdownFields)}>
        <div style={{ position: 'relative' }}>
          <DropdownField
            id={`storage-${nodePool.id}`}
            label="Storage Pool"
            DropdownComponent={AsyncDropdown}
            items={storageOptions}
            value={nodePool.storage}
            onChange={(value) => onChange({ storage: value })}
            // required
            disabled
            tooltip="Cluster node VMs will get deployed using hypervisor local storage. Support for using block storage for cluster node VMs is coming soon"
          />
          <Text variant="caption1" className={classes.storageComingSoonFlag}>
            Coming soon!
          </Text>
        </div>
      </div>
      {/* Networks */}
      <div
        className={clsx(classes.dropdownFields, classes.networkDropdowns, {
          [classes.borderBottom]: showDeleteButton,
        })}
      >
        <DropdownField
          id={`network-${nodePool.id}`}
          label="Network"
          DropdownComponent={AsyncDropdown}
          items={networkOptions}
          value={nodePool.network}
          onChange={(value) => {
            onChange({ network: value, subnet: '' })
          }}
          required
        />
        <DropdownField
          id={`subnet-${nodePool.id}`}
          label="Subnet"
          DropdownComponent={AsyncDropdown}
          items={subnetOptionsByNetworkId[nodePool?.network]}
          value={nodePool.subnet}
          onChange={(value) => {
            onChange({ subnet: value })
          }}
          disabled={!nodePool?.network}
          required
        />
      </div>
      {showDeleteButton && (
        <div className={classes.deleteNodePoolButton}>
          <FontAwesomeIcon
            className={classes.minusIcon}
            onClick={() => onDeleteNodePool(nodePool?.id)}
            size="xl"
          >
            minus-circle
          </FontAwesomeIcon>
          <Text variant="body2">Delete Node Pool</Text>
        </div>
      )}
    </div>
  )
}

export default AddNodeGroups

const useStyles = makeStyles<Theme, { collapseCard: boolean; showDeleteButton: boolean }>(
  (theme) => ({
    content: {
      display: 'grid',
      gridAutoFlow: 'row',
      gridGap: theme.spacing(4),
    },
    vmSelection: {
      display: 'grid',
      gridGap: theme.spacing(2),
    },
    borderBottom: {
      paddingBottom: theme.spacing(3),
      borderBottom: `1px solid ${theme.components.card.border}`,
    },
    autoscalingFields: {
      display: 'grid',
      gridGap: theme.spacing(2),
    },
    quantitySelector: {
      display: 'grid',
      gridTemplateColumns: 'max-content',
      gridGap: theme.spacing(2),
      marginTop: theme.spacing(),
    },
    dropdownFields: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.spacing(3),
      alignItems: 'baseline',
    },
    networkDropdowns: {
      paddingBottom: ({ showDeleteButton }) =>
        showDeleteButton ? theme.spacing(4) : theme.spacing(2),
    },
    deleteNodePoolButton: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, max-content)',
      padding: theme.spacing(1, 0, 3, 0),
      alignItems: 'center',
    },
    minusIcon: {
      color: theme.palette.blue[700],
      marginRight: theme.spacing(1),
      fontWeight: 900,
    },
    storageComingSoonFlag: {
      position: 'absolute',
      top: 32,
      right: 0,
      backgroundColor: theme.palette.blue['700'],
      color: theme.palette.common.white,
      padding: '6px 16px 6px 20px',
      whiteSpace: 'nowrap',
      zIndex: 10,
      display: 'inline-block',
      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%, 10px 50%)', // Creates the transparent triangle on the left
    },
  }),
)
