import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import useReactRouter from 'use-react-router'

import DocumentMeta from 'core/components/DocumentMeta'
import Theme from 'core/themes/model'
import Breadcrumbs from 'core/elements/breadcrumbs'
import { HeaderTitlePortal } from 'core/elements/header/portals'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import Card from 'core/elements/card'
import Button from 'core/elements/button/Button'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import Text from 'core/elements/Text'
import Timeline from 'core/components/Timeline'
import ListClusterNodeGroups from './ListClusterNodeGroups'
import { compareVersions } from 'k8s/util/helpers'
import KaapiClusterVersionField from './KaapiClusterVersionField'
import { kaapiConfigMapsSelector } from 'k8s/components/kaapi/config-maps/selectors'

import { isNil, prop } from 'ramda'
import { emptyObj } from 'utils/fp'
import { useSelector } from 'react-redux'
import { extractSupportedK8sVersions } from '../cluster/deployment/form-components/helpers'
import { kaapiClustersOverviewSelector } from '../kaapi/clusters/selectors'
import {
  getNextUpgradeVersion,
  getRequiredVersion,
  isVersionGreaterOrEqual,
} from 'core/utils/helpers'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import useListAction from 'core/hooks/useListAction'
import { listKaapiConfigMaps } from '../kaapi/config-maps/actions'
import { RootState, useAppSelector } from 'app/store'
import { MachineDeploymentPhase } from '../dashboard/model'
import { NodeGroupsNotFullyHealthyAlert, UnhealthyNodeGroupsAlert } from './ClusterUpgradeAlerts'
import { routes } from 'core/utils/routes'
import { createKaapiClusterUpgradeJob } from '../kaapi/clusters/actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ExternalLink from 'core/components/ExternalLink'
import Alert from 'core/components/Alert'
import { UserPreferences } from 'app/constants'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { getK8sImage } from '../cluster/deployment/helper'
import { listImages } from 'app/plugins/openstack/components/images/actions'
import { imagesSelector } from 'app/plugins/openstack/components/images/selectors'
import { DOCUMENT_LINKS } from '../constants'

const ClustersUpgradePage = () => {
  const classes = useStyles({})
  const { history, match } = useReactRouter()
  const { cluster } = match.params
  const { activeKaapiTenant } = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const namespace = activeKaapiTenant
  const { prefs, updateUserDefaults } = useScopedPreferences('defaults')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const { hideUpgradeSuccessBanner = [] } = (prefs[UserPreferences.Dashboard] || emptyObj) as {
    hideUpgradeSuccessBanner: string[]
  }
  const { reload } = useListAction(listKaapiConfigMaps, {
    params: {
      namespace,
    },
    requiredParams: ['namespace'],
  })
  const configMaps = useSelector(kaapiConfigMapsSelector)

  const clusters = useSelector(kaapiClustersOverviewSelector)
  const clusterData = clusters?.find((clusterData) => clusterData?.metadata?.name === cluster)
  const machineDeployments = clusterData?.machineDeployments || []
  const [upgradeTargetVersion, setUpgradeTargetVersion] = useState(null)
  const {
    update: startClusterUpgrade,
    updating: clusterUpgrading,
    error: clusterUpgradeError,
  } = useUpdateAction(createKaapiClusterUpgradeJob)

  // Load Images
  const { loading: loadingImages } = useListAction(listImages)
  const images = useAppSelector(imagesSelector)

  // Error Handling
  const hasError = clusterUpgradeError || error

  useEffect(() => {
    if (!hasError) return
    // Show first error
    const errorObj = clusterUpgradeError || error
    setError({
      title: errorObj?.title || 'Error upgrading cluster',
      message:
        errorObj?.message || 'An error occurred while upgrading the cluster. Please try again.',
    })
  }, [hasError, clusterUpgradeError, error])

  const handleSubmit = useCallback(
    async ({ targetVersion }) => {
      setLoading(true)
      const { version } = clusterData
      const k8sImage = getK8sImage({
        k8sVersion: targetVersion,
        images,
        setError,
      })

      if (!k8sImage) {
        setLoading(false)
        return
      }

      const { success, response } = await startClusterUpgrade({
        namespace,
        clusterName: clusterData?.metadata?.name,
        nodeGroups: machineDeployments, //Node Group Data
        upgradeJobs: [],
        targetVersion,
        sourceVersion: version,
        upgradeImageName: k8sImage,
        // addons: [],
      })

      const filteredHideUpgradeSuccessBanner = hideUpgradeSuccessBanner.filter(
        (item) => item !== clusterData?.clusterName,
      )

      updateUserDefaults(UserPreferences.Dashboard, {
        hideUpgradeSuccessBanner: [...filteredHideUpgradeSuccessBanner],
      })

      if (success) {
        reload(true)
        history.push(routes.kubernetes.manage.overview.path({ cluster: clusterData?.clusterName }))
      }
      setLoading(false)
    },
    [cluster?.upgradeJobs, cluster?.uuid, clusterData, machineDeployments?.spec?.clusterName],
  )
  const hasUnhealthyNodeGroups = useMemo(
    () =>
      !!machineDeployments.find((group) => group?.status?.phase === MachineDeploymentPhase.Failed),
    [machineDeployments],
  )
  const hasPartiallyHealthyNodeGroups = useMemo(
    () =>
      !!machineDeployments.find((group) => group?.status?.phase !== MachineDeploymentPhase.Running),
    [machineDeployments],
  )
  const supportedK8sVersions = useMemo(() => extractSupportedK8sVersions(configMaps).sort(), [
    configMaps,
  ])
  const currentVersion = clusterData?.version
  const nextUpgradeVersion = useMemo(
    () => getNextUpgradeVersion(currentVersion, supportedK8sVersions),
    [currentVersion, supportedK8sVersions],
  )
  const updatedK8sVersions = useMemo(() => {
    if (currentVersion && Array.isArray(supportedK8sVersions)) {
      return Array.from(new Set([currentVersion, ...supportedK8sVersions]))
        .filter((version) => isVersionGreaterOrEqual(version, currentVersion))
        .sort((a, b) => (isVersionGreaterOrEqual(a, b) ? 1 : -1))
    }
    return [] // Default to an empty array if inputs are invalid
  }, [currentVersion, supportedK8sVersions])

  const latestVersion = updatedK8sVersions[updatedK8sVersions.length - 1]

  useEffect(() => {
    setUpgradeTargetVersion(nextUpgradeVersion)
  }, [nextUpgradeVersion])

  const customizeVersionOptions = useCallback(
    (options) => {
      if (isNil(options)) return []

      return options?.reduce((accum, option) => {
        const version = typeof option === 'string' ? option : option?.value

        // Skip versions <= current cluster version
        if (compareVersions(version, currentVersion) <= 0) return accum

        // Disable and update label for versions > next possible upgrade version
        const requiredVersion = getRequiredVersion(currentVersion, options)

        if (compareVersions(version, requiredVersion) > 0) {
          accum?.push({
            label: `${version} (requires v${requiredVersion})`,
            value: version,
            disabled: true,
          })
        } else {
          accum?.push({
            label: version,
            value: version,
          })
        }

        return accum
      }, [])
    },
    [currentVersion, nextUpgradeVersion],
  )

  return (
    <>
      <HeaderTitlePortal>
        <Breadcrumbs />
      </HeaderTitlePortal>
      {/* <Progress loading={loading}> */}
      <DocumentMeta title="Upgrade" />
      {error && <Alert variant="error" title={error?.title} message={error?.message} />}
      <ExternalLink
        textVariant="caption1"
        url={DOCUMENT_LINKS.K8S_CLUSTER_UPGRADE}
        className={classes.externalLink}
      >
        <Button variant="secondary" rightIcon="arrow-up-right-from-square">
          Learn More About Upgrades
        </Button>
      </ExternalLink>
      {hasUnhealthyNodeGroups && (
        <UnhealthyNodeGroupsAlert
          redirectLink={() =>
            history.push(routes.kubernetes.manage.capacityAndHealth.path({ cluster }))
          }
        />
      )}
      <div>
        <ValidatedForm onSubmit={handleSubmit} className={classes.validatedForm}>
          <Card
            footer={
              <div className={classes.actionsContainer}>
                <Button
                  variant="secondary"
                  onClick={() => history.push(routes.kubernetes.dashboard.root.path())}
                  disabled={clusterUpgrading}
                >
                  Back
                </Button>
                <Button
                  disabled={hasUnhealthyNodeGroups || currentVersion === latestVersion || loading}
                  loading={clusterUpgrading || loading}
                >
                  Upgrade Cluster
                </Button>
              </div>
            }
          >
            <FormFieldSection title="Select Version" step={1}>
              {/* TODO:: Add Extended Version Alert => Currently not supported in BE, but should come from CM/CRD. */}
              {/* <ExtendedVersionAlert /> */}

              <Text variant="body2" className={classes.infoText}>
                Cluster upgrades can only be performed to the next available version. The newest
                possible version <b>{nextUpgradeVersion}</b> is selected by default.
              </Text>

              <div className={classes.timelineContainer}>
                <Timeline items={updatedK8sVersions} activeStep={1} />
                <div className={classes.currAndLatestVersions}>
                  <Text variant="body2">
                    Current Version: <b>{currentVersion}</b>
                  </Text>
                  <Text variant="body2">
                    Latest Version: <b>{latestVersion}</b>
                  </Text>
                </div>
              </div>
              <KaapiClusterVersionField
                id="targetVersion"
                wizardContext={{ targetVersion: upgradeTargetVersion }}
                setWizardContext={({ targetVersion }) => setUpgradeTargetVersion(targetVersion)}
                // showAwsEksVersions={isEksCluster}
                customizeOptionsFn={customizeVersionOptions}
                selectFirst={false}
                inClusterUpgrade={true}
                isCapiCluster
                k8sVersions={supportedK8sVersions}
              />
            </FormFieldSection>
            <FormFieldSection title="Check Node Groups" step={2}>
              {hasPartiallyHealthyNodeGroups && !hasUnhealthyNodeGroups && (
                <NodeGroupsNotFullyHealthyAlert
                  redirectLink={() =>
                    history.push(routes.kubernetes.manage.capacityAndHealth.path({ cluster }))
                  }
                />
              )}
              <Text variant="body2" className={classes.infoText}>
                Check the health of the node groups and if needed make all neccessary changes in the
                upgrade strategy of each node group.
              </Text>
              <ListClusterNodeGroups />
            </FormFieldSection>
          </Card>
        </ValidatedForm>
      </div>
      {/* </Progress> */}
    </>
  )
}

export default ClustersUpgradePage

const useStyles = makeStyles((theme: Theme) => ({
  customGridContainer: {
    display: 'grid',
    gridTemplateColumns: '370px 1fr',
    gridGap: '24px',
  },
  headerSubText: {
    paddingTop: '24px',
  },
  validatedForm: {
    '& .content': {
      marginLeft: '0px',
    },
    '& article > section': {
      padding: '36px',
    },
    '& article > section > fieldset': {
      marginBottom: '46px',
    },
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  timelineWidth: {
    width: '30%',
  },
  infoText: {
    margin: theme.spacing(2, 0),
  },
  timelineContainer: {
    maxWidth: '720px',
    marginTop: theme.spacing(1),
  },
  currAndLatestVersions: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(4, 0),
  },
  externalLink: {
    width: theme.spacing(31),
  },
}))
