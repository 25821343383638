import { listTablePrefs } from 'app/constants'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import ListContainer from 'core/containers/ListContainer'
import { createGridStatusCell, StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import React, { useEffect, useMemo, useState } from 'react'
import { isNilOrEmpty } from 'utils/fp'
import DeleteWorkerNodesDialog from './DeleteWorkerNodesDialog'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import UsageProgress from './UsageProgress'
import useScopedPreferences from 'core/session/useScopedPreferences'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { kaapiMachinesForClusterSelector } from '../kaapi/machines/selectors'
import { listKaapiMachines } from '../kaapi/machines/actions'
import PollingData from 'core/components/PollingData'
import SimpleLink from 'core/components/SimpleLink'
import { GridCellProps } from 'pf9-ui-components/built/elements/grid/hooks/useGridRows'
import Tooltip from 'core/elements/tooltip/Tooltip'
import { makeStyles } from '@material-ui/styles'
import { IVmDetailsPageTabs } from 'app/plugins/openstack/components/vms/vm-details/model'
import { routes } from 'core/utils/routes'
import { IMachineDeploymentSelector } from '../kaapi/machine-deployment/model'
import { kaapiClustersSelector } from '../kaapi/clusters/selectors'

const useWorkerNodeCellComponentStyles = makeStyles(() => ({
  disabled: {
    cursor: 'not-allowed',
  },
}))

export type NodeGroupSelector = IMachineDeploymentSelector

const usePrefParams = createUsePrefParamsHook('Machine Deployments', listTablePrefs)

const Phase = {
  Provisioning: 'Provisioning',
  Provisioned: 'Provisioned',
  Running: 'Running',
  Failed: 'Failed',
  ScalingUp: 'ScalingUp',
  ScalingDown: 'ScalingDown',
  Deleting: 'Deleting',
  Unknown: 'Unknown',
  Ready: 'Ready',
  Pending: 'Pending',
}

// OpenStack Machine States
const getMachineStatus = (phase): StatusCellModel => {
  switch (phase) {
    case Phase.Running:
    case Phase.Provisioned:
    case Phase.Ready:
      return { variant: 'success', label: phase }
    case Phase.Provisioning:
    case Phase.ScalingUp:
    case Phase.ScalingDown:
    case Phase.Pending:
      return { variant: 'warning', label: phase }
    case Phase.Failed:
    case Phase.Deleting:
      return { variant: 'error', label: phase }
    default:
      return { variant: 'unknown', label: phase || Phase.Unknown }
  }
}

// K8s Node States
const getK8sNodeStatus = (phase): StatusCellModel => {
  switch (phase) {
    case 'Ready':
      return { variant: 'success', label: phase }
    case 'MemoryPressure':
    case 'DiskPressure':
    case 'PIDPressure':
      return { variant: 'warning', label: phase }
    case 'NetworkUnavailable':
      return { variant: 'error', label: phase }
    default:
      return { variant: 'unknown', label: phase || Phase.Unknown }
  }
}

const cellFormatFn = (value) => (isNilOrEmpty(value) ? '-' : value)

// Extract UUID from providerId Eg. openstack:///283d5beb-4f02-4fd5-ad4f-ebc7cf472060
function extractUUID(providerId) {
  const match = providerId?.match(/[0-9a-fA-F-]{36}/)
  return match ? match[0] : null
}

export function WorkerNodeCellComponent({ item }: GridCellProps<any>) {
  const classes = useWorkerNodeCellComponentStyles()

  const vmID = extractUUID(item?.spec?.providerID)
  const linkToVM = routes.openstack.vmDetails.path({ id: vmID, tab: IVmDetailsPageTabs.Overview })

  return (
    <Tooltip message={!vmID ? 'VM not provisioned yet' : ''}>
      <SimpleLink className={!vmID && classes.disabled} src={vmID ? linkToVM : null}>
        {item?.metadata?.name}
      </SimpleLink>
    </Tooltip>
  )
}

const columns: GridViewColumn<any>[] = [
  {
    key: 'metadata.name',
    label: 'Name',
    CellComponent: WorkerNodeCellComponent,
  },
  {
    key: 'k8sNodeStatus.type',
    label: 'K8s Node status',
    CellComponent: createGridStatusCell({
      dataFn: (node: NodeGroupSelector): StatusCellModel => {
        return getK8sNodeStatus(node)
      },
    }),
  },
  {
    key: 'status.phase',
    label: 'Machine Status',
    CellComponent: createGridStatusCell({
      dataFn: (node: NodeGroupSelector): StatusCellModel => {
        return getMachineStatus(node)
      },
    }),
  },
  {
    key: 'machineDeployment.metadata.name',
    label: 'Node Group',
  },
  {
    key: 'machineDeployment.openStackMachineTemplate.spec.template.spec.flavor',
    label: 'Flavor',
  },
  {
    key: 'spec.version',
    label: 'K8s Version',
    formatFn: cellFormatFn,
  },
  {
    key: 'metadata.creationTimestamp',
    label: 'Created',
    CellComponent: DateAndTime,
  },
  {
    key: 'cpuUsage',
    label: 'CPU Usage (cores)',
    CellComponent: UsageProgress,
  },
  {
    key: 'memoryUsage',
    label: 'Memory Usage (GiB)',
    CellComponent: UsageProgress,
  },
  //commenting it as we are not getting storageUsage
  // {
  //   key: 'storageUsage',
  //   label: 'Storage Usage (GiB)',
  //   CellComponent: UsageProgress,
  // },
]

export default function ListWorkerNodes() {
  const { params, getParamsUpdater } = usePrefParams({})
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences('k8sPluginGlobalParams')

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [selectedWorkerNode, setSelectedWorkerNode] = useState(null)

  // Refresh the node list when the cluster changes
  useEffect(() => {
    if (k8sPluginGlobalPerfs.cluster) {
      reloadKaapiMachines(true, false)
    }
  }, [k8sPluginGlobalPerfs.cluster])

  const allClusters = useSelector(kaapiClustersSelector)
  const cluster = allClusters?.find((c) => c?.metadata?.name === k8sPluginGlobalPerfs?.cluster)

  // Fetch Worker Nodes
  const { loading: loadingKaapiMachines, reload: reloadKaapiMachines } = useListAction(
    listKaapiMachines,
  )
  const machinesSelector = kaapiMachinesForClusterSelector({
    clusterName: k8sPluginGlobalPerfs?.cluster,
  })
  const machines = useSelector(machinesSelector) || []

  // Check if this is the last running node in the cluster
  const isLastRunningNode: boolean = useMemo(
    () => machines?.length === 1 && machines[0]?.status.phase === 'Running',
    [machines],
  )

  const batchActions = [
    {
      label: 'Delete',
      icon: 'trash-alt',
      BatchActionButton: getGridDialogButton(DeleteWorkerNodesDialog, {
        isLastRunningNode,
        reloadKaapiMachines,
      }),
    },
  ]

  const rowMenuItems = [
    {
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (workerNode) => {
        setSelectedWorkerNode(workerNode)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
  ]

  return (
    <>
      <PollingData
        hidden
        loading={loadingKaapiMachines}
        onReload={reloadKaapiMachines}
        refreshDuration={1000 * 10}
      />
      {showDeleteDialog && (
        <DeleteWorkerNodesDialog
          rows={[selectedWorkerNode]}
          onClose={() => setShowDeleteDialog(false)}
          isLastRunningNode={isLastRunningNode}
          reloadKaapiMachines={reloadKaapiMachines}
        />
      )}
      <ListContainer<any, any>
        searchTargets={['name', 'phase']}
        uniqueIdentifier="uid"
        loading={loadingKaapiMachines}
        loadingMessage="Loading..."
        onRefresh={reloadKaapiMachines}
        hideRefreshButtonOnTop={true}
        multiSelection
        data={machines}
        columns={columns}
        getParamsUpdater={getParamsUpdater}
        label="Worker Nodes"
        showItemsCountInLabel
        showBreadcrumbs={false}
        batchActions={batchActions}
        rowMenuItems={rowMenuItems}
        showRowMenuForSingleRowActions
        isReadOnly={cluster?.isBYOHCluster} // For BYOH clusters disable all actions
      />
    </>
  )
}
