import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { kaapiClustersSelector } from 'k8s/components/kaapi/clusters/selectors'
import DataKeys from 'k8s/DataKeys'
import { findClusterName } from 'k8s/util/helpers'

export const clusterRolesSelector = createSharedSelector(
  getDataSelector<DataKeys.ClusterRoles>(DataKeys.ClusterRoles),
  kaapiClustersSelector,
  (items, clusters) => {
    return items.map((item) => ({
      ...item,
      id: item?.metadata?.uid,
      name: item?.metadata?.name,
      clusterName: findClusterName(clusters, item.clusterId),
      created: item?.metadata?.creationTimestamp,
      pickerLabel: `Cluster Role: ${item?.metadata?.name}`,
      pickerValue: `ClusterRole:${item?.metadata?.name}`,
    }))
  },
)
