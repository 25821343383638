import { makeStyles } from '@material-ui/styles'
import React from 'react'
import useReactRouter from 'use-react-router'

import DocumentMeta from 'core/components/DocumentMeta'
import Progress from 'core/components/progress/Progress'
import Breadcrumbs from 'core/elements/breadcrumbs'
import { HeaderTitlePortal } from 'core/elements/header/portals'
import useListAction from 'core/hooks/useListAction'
import Theme from 'core/themes/model'
import { useSelector } from 'react-redux'
import { listKaapiClusters } from '../kaapi/clusters/actions'
import { kaapiClustersOverviewSelector } from '../kaapi/clusters/selectors'
import ClustersDashboardHeader from './ClustersDashboardHeader'
import ClustersLandingPage from './ClustersLandingPage'
import DeployNewCluster from './DeployNewCluster'
import ListK8sClusters from './ListK8sClusters'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { listNamespaces } from 'k8s/components/resources/namespaces/actions'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import PollingData from 'core/components/PollingData'

const ClustersDashboardPage = () => {
  const classes = useStyles({})
  const { history } = useReactRouter()
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterId = k8sPluginGlobalPerfs?.clusterId
  useListAction(listNamespaces, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })
  const { loading: loadingKaapiClusters, reload } = useListAction(listKaapiClusters, {
    initialLoadingState: true,
  })
  const clusters = useSelector(kaapiClustersOverviewSelector)

  // Landing page for empty state
  const showLandingPage = !loadingKaapiClusters && !clusters?.length

  return (
    <>
      <HeaderTitlePortal>
        <Breadcrumbs />
      </HeaderTitlePortal>
      <Progress loading={loadingKaapiClusters}>
        <PollingData
          hidden
          loading={loadingKaapiClusters}
          onReload={reload}
          refreshDuration={1000 * 10}
        />
        <DocumentMeta title="Dashboard" />
        {showLandingPage ? (
          <div>
            <ClustersLandingPage />
          </div>
        ) : (
          <>
            {!loadingKaapiClusters && <DeployNewCluster />}
            <div>
              <ClustersDashboardHeader />
              <div className={classes.headerSubText}>
                <ListK8sClusters />
              </div>
            </div>
          </>
        )}
      </Progress>
    </>
  )
}

export default ClustersDashboardPage

const useStyles = makeStyles((theme: Theme) => ({
  customGridContainer: {
    display: 'grid',
    gridTemplateColumns: '370px 1fr',
    gridGap: '24px',
  },
  headerSubText: {
    paddingTop: '24px',
  },
}))
