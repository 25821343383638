import { AppSelector } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { kaapiOpenStackMachineTemplateSelector } from '../openstack-machine-templates/selectors'

export const isAutoScalingEnabled = (md) => {
  return md?.metadata?.annotations?.['cluster.x-k8s.io/cluster-api-autoscaler-node-group-max-size']
    ? 'Enabled'
    : 'Disabled'
}

export const kaapiMachineDeploymentsSelector: AppSelector<any[]> = createSharedSelector(
  getDataSelector<DataKeys.KaapiMachineDeployments>(DataKeys.KaapiMachineDeployments),
  kaapiOpenStackMachineTemplateSelector,
  (kaapiMachineDeployments, kaapiOpenStackMachineTemplates): any[] => {
    return kaapiMachineDeployments.map((md) => {
      // Find the openstack machine template for the machine deployment
      const openStackMachineTemplate = kaapiOpenStackMachineTemplates?.find((t) => {
        return t?.metadata?.name === md?.spec?.template?.spec?.infrastructureRef?.name
      })

      // Upgrade fields
      const strategyType = md?.spec?.strategy?.type
      const rollingUpdate = md?.spec?.strategy?.rollingUpdate || {}
      let maxSurgeValue, maxSurge, maxUnavailableValue, maxUnavailable

      // Handle `OnDelete` strategy
      if (strategyType.toLowerCase() === 'ondelete') {
        maxSurgeValue = 'N/A'
        maxSurge = 'N/A'
        maxUnavailableValue = 'N/A'
        maxUnavailable = 'N/A'
      } else {
        // Handle other strategies
        maxSurgeValue = rollingUpdate.maxSurge ?? 'N/A'
        maxSurge = typeof rollingUpdate.maxSurge === 'string' ? 'Percent' : 'Count'
        maxUnavailableValue = rollingUpdate.maxUnavailable ?? 'N/A'
        maxUnavailable = typeof rollingUpdate.maxUnavailable === 'string' ? 'Percent' : 'Count'
      }

      return {
        uid: md?.metadata?.uid,
        name: md?.metadata?.name,
        strategy: strategyType,
        maxSurgeValue,
        maxSurge,
        maxUnavailableValue,
        maxUnavailable,
        nodes: md?.spec?.replicas,
        ...md,
        spec: {
          ...md?.spec,
          autoscaling: md?.metadata?.annotations?.[
            'cluster.x-k8s.io/cluster-api-autoscaler-node-group-max-size'
          ]
            ? 'Enabled'
            : 'Disabled',
          autoscalingMaxNodes: md?.metadata?.annotations?.[
            'cluster.x-k8s.io/cluster-api-autoscaler-node-group-max-size'
          ]
            ? Number(
                md?.metadata?.annotations?.[
                  'cluster.x-k8s.io/cluster-api-autoscaler-node-group-max-size'
                ],
              )
            : 0,
        },
        openStackMachineTemplate,
      }
    })
  },
)

export const kaapiMachineDeploymentsForClusterSelector = ({ clusterName }): AppSelector<any[]> =>
  createSharedSelector(kaapiMachineDeploymentsSelector, (kaapiMachineDeployments): any[] => {
    return kaapiMachineDeployments.filter((md) => md.spec.clusterName === clusterName)
  })
