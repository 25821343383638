import { Selector } from '@reduxjs/toolkit'
import { AppSelector, RootState } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { findClusterName } from 'k8s/util/helpers'
import { flatten } from 'ramda'
import { durationBetweenDates } from 'utils/misc'
import { pvcByPVSelector } from '../persistent-volume-claims/selectors'
import { pvByStorageClassSelector } from '../persistent-volume/selectors'
import { isDefaultStorageClass } from './helpers'
import { IStorageClassSelector } from './model'
import { IEventSelector } from './model'

export const storageClassSelector: Selector<
  RootState,
  IStorageClassSelector[]
> = createSharedSelector(
  getDataSelector<DataKeys.StorageClasses>(DataKeys.StorageClasses),
  pvByStorageClassSelector,
  pvcByPVSelector,
  getDataSelector<DataKeys.KaapiClusters>(DataKeys.KaapiClusters),
  (rawStorageClasses, pvByStorageClass, pvcByPv, allClusters) => {
    return rawStorageClasses.map((storageClass) => {
      const pvs = pvByStorageClass.get(storageClass?.name) || []
      const pvcs = flatten(pvs.map((pv) => pvcByPv.get(pv?.name) || []))
      return {
        ...storageClass,
        clusterName: findClusterName(allClusters, storageClass.clusterId),
        isDefault: isDefaultStorageClass(storageClass),
        kind: 'StorageClass',
        allowVolumeExpansion: !!storageClass?.allowVolumeExpansion, // Force boolean here
        persistentVolumes: pvs,
        persistentVolumeClaims: pvcs,
        labels: storageClass?.metadata?.labels,
        annotations: storageClass?.metadata?.annotations,
        age: durationBetweenDates({ labels: ['d'] })(storageClass?.metadata?.creationTimestamp),
      }
    })
    // .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)

export const clusterEventsSelector: AppSelector<IEventSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.Events>(DataKeys.Events, ['clusterId'], ['clusterId', 'namespace']),
  (rawEvents) => {
    return rawEvents.map((event) => {
      return {
        ...event,
        id: event?.metadata?.uid,
        name: event?.metadata?.name,
        timestamp: event?.eventTime || event?.deprecatedLastTimestamp,
      }
    })
  },
)
