import { AppSelector } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { convertCpuUsageValueToCores, convertMemoryUsageValueToGb } from './helpers'
import DataKeys from 'k8s/DataKeys'

export const kaapiMetricsNodesSelector: AppSelector<any[]> = createSharedSelector(
  getDataSelector<DataKeys.KaapiMetricsNodes>(DataKeys.KaapiMetricsNodes),
  (kaapiNodes): any[] => {
    return kaapiNodes.map((kaapiNode) => {
      return kaapiNode
    })
  },
)

export const kaapiNodesSelector: AppSelector<any[]> = createSharedSelector(
  getDataSelector<DataKeys.KaapiNodes>(DataKeys.KaapiNodes),
  kaapiMetricsNodesSelector,
  (kaapiNodes, kaapiMetricsNodes): any[] => {
    return kaapiNodes.map((kaapiNode) => {
      const maxCpu = convertCpuUsageValueToCores(kaapiNode?.status?.capacity?.cpu)
      const maxMemory = convertMemoryUsageValueToGb(kaapiNode?.status?.capacity?.memory)
      const kaapiNodeMetrics = kaapiMetricsNodes?.find(
        (node) => node?.metadata?.name === kaapiNode?.metadata?.name,
      )

      const usedCpu = convertCpuUsageValueToCores(kaapiNodeMetrics?.usage?.cpu)
      const usedMemory = convertMemoryUsageValueToGb(kaapiNodeMetrics?.usage?.memory)
      return {
        ...kaapiNode,
        maxCpu,
        maxMemory,
        usedCpu,
        usedMemory,
      }
    })
  },
)
