import { equals, prop, uniqBy } from 'ramda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAppSelector } from 'app/store'
import MultiDropdown from 'core/elements/dropdown/MultiDropdown'
import useListAction from 'core/hooks/useListAction'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import { emptyArr, ensureArray, isNilOrEmpty, projectAs } from 'utils/fp'
import { listNamespaces } from 'k8s/components/resources/namespaces/actions'
import { namespacesSelector } from 'k8s/components/resources/namespaces/selectors'

export default function useNamespaceFilterDropdown() {
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const { loading: isLoading, reload: reloadNs } = useListAction(listNamespaces, {
    params: { clusterId: k8sPluginGlobalPerfs?.clusterId },
    requiredParams: ['clusterId'],
  })
  const namespaces = useAppSelector(namespacesSelector)
  const namespacesNames = useMemo(() => namespaces.map((ns) => ns.name), [namespaces])

  const options = useMemo(() => {
    return projectAs({ label: 'name', value: 'name' }, uniqBy(prop('name'), namespaces))
  }, [namespaces])

  const [selectedNs, setSelectedNs] = useState<string[]>(namespacesNames)
  useEffect(() => {
    //To prepopulate the selected namespaces in the dropdown when the page is loaded for the first time
    setSelectedNs(namespacesNames) // fixme
  }, [namespacesNames])

  useEffect(() => {
    if (k8sPluginGlobalPerfs?.cluster) {
      reloadNs(true, false)
    }
  }, [k8sPluginGlobalPerfs?.cluster])

  const onChange = useCallback((value) => {
    setSelectedNs(value)
  }, [])

  return {
    Component: (
      <MultiDropdown
        compact={true}
        onChange={onChange}
        label={'Namespace'}
        showAll
        loading={isLoading}
        items={options}
        // value={namespacesNames}
        value={selectedNs}
        condensed
      />
    ),
    selectedNs,
  }
}
