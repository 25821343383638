import { makeStyles } from '@material-ui/styles'
import { useAppSelector } from 'app/store'
import DocumentMeta from 'core/components/DocumentMeta'
import PresetField from 'core/components/PresetField'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ModalForm from 'core/elements/modal/ModalForm'
import useListAction from 'core/hooks/useListAction'
import useParams from 'core/hooks/useParams'
import usePluginRouter from 'core/hooks/usePluginRouter'
import useUpdateAction from 'core/hooks/useUpdateAction'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import GroupMultiSelect from 'k8s/components/common/GroupMultiSelect'
import UserMultiSelect from 'k8s/components/common/UserMultiSelect'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import { propEq } from 'ramda'
import React, { useCallback, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { emptyObj } from 'utils/fp'
import { listClusterRoleBindings, updateClusterRoleBinding } from './actions'
import { IRbacClusterRoleBindingSelector } from './model'
import { clusterRoleBindingsSelector } from './selectors'

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: 16,
  },
}))

const defaultParams = {
  users: [] as string[],
  groups: [] as string[],
}

export default function UpdateClusterRoleBindingPage() {
  const classes = useStyles({})
  const { match, history } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterRoleBindingId = match?.params?.id
  const clusterId = k8sPluginGlobalPerfs?.clusterId || ''

  const { loading } = useListAction(listClusterRoleBindings, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })
  const clusterRoleBindings = useAppSelector(clusterRoleBindingsSelector)

  const clusterRoleBinding = useMemo(
    () =>
      clusterRoleBindings.find(propEq('id', clusterRoleBindingId)) ||
      (emptyObj as IRbacClusterRoleBindingSelector),
    [clusterRoleBindings, clusterRoleBindingId],
  )
  const { getParamsUpdater } = useParams(defaultParams)

  const { update, updating, reset, error: errorUpdatingClusterRoleBinding } = useUpdateAction(
    updateClusterRoleBinding,
  )

  const submitForm = useCallback(
    async (params) => {
      const { success } = await update({ ...clusterRoleBinding, ...params })
      if (success) handleClose()
    },
    [clusterRoleBinding],
  )

  const handleClose = () => {
    reset()
    history.push(
      routes.kubernetes.accessControl.clusterRoleBindings.path({
        cluster: k8sPluginGlobalPerfs?.cluster,
        plugin: currentPluginId,
      }),
    )
  }

  return (
    <>
      <DocumentMeta title="Update Cluster Role Binding" bodyClasses={['form-view']} />
      <ModalForm
        route={routes.kubernetes.accessControl.editClusterRoleBindings}
        title="Edit Cluster Role Binding"
        onSubmit={submitForm}
        onClose={handleClose}
        loading={loading}
        submitting={updating}
        error={errorUpdatingClusterRoleBinding}
        loadingMessage={loading ? 'Loading cluster role binding...' : 'Submitting form...'}
        submitTitle="Update Cluster Role Binding"
      >
        <>
          <FormFieldSection title="Basic Details" step={1}>
            <PresetField label="Name" value={clusterRoleBinding.name} />
            {clusterRoleBinding.roleRef && (
              <PresetField label="Role" value={clusterRoleBinding.roleRef.name} />
            )}
          </FormFieldSection>
          {clusterRoleBinding.users && (
            <FormFieldSection title="Assign Users to this Cluster Binding">
              <UserMultiSelect
                id="users"
                tooltip="Select users to assign this role"
                onChange={getParamsUpdater('users')}
                initialValue={clusterRoleBinding.users}
              />
            </FormFieldSection>
          )}
          {clusterRoleBinding.groups && (
            <FormFieldSection title="Assign Groups to this Cluster Binding" step={2}>
              <GroupMultiSelect
                id="groups"
                tooltip="Select groups to assign this role"
                onChange={getParamsUpdater('groups')}
                initialValue={clusterRoleBinding.groups}
              />
            </FormFieldSection>
          )}
        </>
      </ModalForm>
    </>
  )
}
