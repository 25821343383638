import React, { useState } from 'react'
import { HeaderPrimaryActionPortal, HeaderTitlePortal } from 'core/elements/header/portals'
import CreateButton from 'core/components/buttons/CreateButton'
import ClusterDeploymentForm from '../cluster/deployment/ClusterDeploymentForm'
import { isReadonlyUser } from 'core/utils/helpers'

/**
 * @summary This component is used to render deploy new cluster button and cluster deployment form
 * Use this component anywhere we need to show deploy new cluster feature
 * @returns {React.ReactNode} The DeployNewCluster component
 */
const DeployNewCluster = ({ children = null }) => {
  const [showClusterDeploymentModal, setshowClusterDeploymentModal] = useState(false)

  if (isReadonlyUser()) {
    return null
  }

  return (
    <>
      <HeaderPrimaryActionPortal>
        {children}
        {/* <IconButton icon="pencil" info="Customize your dashboard" onClick={handleEditDashboard} /> */}
        <CreateButton onClick={() => setshowClusterDeploymentModal(true)}>
          {'Deploy New Cluster'}
        </CreateButton>
      </HeaderPrimaryActionPortal>
      {showClusterDeploymentModal && (
        <ClusterDeploymentForm onClose={() => setshowClusterDeploymentModal(false)} />
      )}
    </>
  )
}

export default DeployNewCluster
