import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { useAppSelector } from 'app/store'
import { ArrayElement } from 'core/actions/Action'
import InferActionParams from 'core/actions/InferActionParams'
import DocumentMeta from 'core/components/DocumentMeta'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import ListContainer from 'core/containers/ListContainer'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { routes } from 'core/utils/routes'
import DataKeys from 'k8s/DataKeys'
import {
  renderLabelsAsBadges,
  renderResourceLabels,
} from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import { pick } from 'ramda'
import React, { useEffect, useState } from 'react'
import AddPersistentVolumeClaimModal from './AddPersistentVolumeClaimModal'
import DeletePersistentVolumeClaimDialog from './DeletePersistentVolumeClaimDialog'
import { renderPvcStatus } from './helpers'
import { IPersistentVolumeClaimDetailsPageTabs } from './model'
import { deletePersistentVolumeClaim, listPersistentVolumeClaims } from './new-actions'
import { persistentVolumeClaimsSelector } from './selectors'
import useNamespaceFilterDropdown from 'k8s/components/useNamespaceFilterDropdown'

type ModelDataKey = DataKeys.PersistentVolumeClaims
type SelectorModel = ArrayElement<ReturnType<typeof persistentVolumeClaimsSelector>>
type ActionParams = InferActionParams<typeof listPersistentVolumeClaims>

type Params = ActionParams & {
  namespace?: string
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  healthyClusters: true,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'PersistentVolumeClaims',
  listTablePrefs,
)

const searchTargets = ['name']

const columns = [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ clusterId, id, clusterName }) =>
        routes.kubernetes.resources.persistentVolumeClaims.details.path({
          cluster: clusterName,
          id,
          tab: IPersistentVolumeClaimDetailsPageTabs.Overview,
        }),
    }),
  } as GridViewColumn<SelectorModel, 'name'>,
  { key: 'namespace', label: 'Namespace' },
  { key: 'status.phase', label: 'Status', render: renderPvcStatus },
  {
    key: 'labels',
    label: 'Label',
    render: renderResourceLabels({ type: 'table', variant: 'default' }),
  },
  { key: 'volume', label: 'Volume' },
  { key: 'capacity', label: 'Capacity', render: renderResourceLabels({ type: 'table' }) },
  {
    key: 'accessModes',
    label: 'Access Modes',
    render: renderLabelsAsBadges({ variant: 'default' }),
  },
  { key: 'storageClassName', label: 'Storage Class' },
  { key: 'created', label: 'Created', render: (value: string) => <DateAndTime value={value} /> },
]

export default function PersistentVolumeClaimsListPage() {
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )

  const clusterId = k8sPluginGlobalPerfs?.clusterId || ''
  const clusterName = k8sPluginGlobalPerfs?.cluster || ''

  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listPersistentVolumeClaims, {
    params: { clusterId },
    requiredParams,
  })
  const data = useAppSelector(persistentVolumeClaimsSelector)

  const [selectedPersistentVolumeClaim, setSelectedPersistentVolumeClaim] = useState<SelectorModel>(
    null,
  )
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (persistentVolumeClaim) => {
        setSelectedPersistentVolumeClaim(persistentVolumeClaim)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
  ]
  const { Component, selectedNs } = useNamespaceFilterDropdown()
  const filteredPvcs = data?.filter((item) => selectedNs?.includes(item?.namespace)) || []

  useEffect(() => {
    if (clusterId) {
      reload(true)
    }
  }, [clusterId])

  return (
    <>
      <DocumentMeta title="Persistent Volume Claims" />
      <AddPersistentVolumeClaimModal />
      {showDeleteDialog && (
        <DeletePersistentVolumeClaimDialog
          onClose={() => setShowDeleteDialog(false)}
          rows={[selectedPersistentVolumeClaim]}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.PersistentVolumeClaims}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        data={filteredPvcs}
        addUrl={routes.kubernetes.resources.persistentVolumeClaims.add.path({
          cluster: clusterName,
        })}
        addText="Add Persistent Volume Claim"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deletePersistentVolumeClaim}
        rowMenuItems={rowMenuItems}
        showRowMenuForSingleRowActions
        {...pick(listTablePrefs, params)}
        extraToolbarContent={Component}
      />
    </>
  )
}
