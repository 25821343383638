import { IGenericResource } from 'api-client/qbert.model'

export enum ICustomResourceDefinitionDetailsPageTabs {
  Overview = 'overview',
  Yaml = 'yaml',
  CustomResources = 'custom-resources',
}

export enum ICustomResourceDetailsPageTabs {
  Overview = 'overview',
  Yaml = 'yaml',
}

export interface CustomResourceDefinitionsResponse {
  kind: string
  apiVersion: string
  metadata: CustomResourceDefinitionsResponseMetadata
  items: CustomResourceDefinitionItem[]
}

export interface CustomResourcesResponse {
  kind: string
  apiVersion: string
  metadata: CustomResourceDefinitionsResponseMetadata
  items: CustomResourcesItem[]
}

interface CustomResourceDefinitionsResponseMetadata {
  resourceVersion: string
}

export interface CustomResourceDefinitionItem {
  metadata: ItemMetadata
  spec: CustomResourceDefinitionSpec
  status?: CustomResourceDefinitionStatus
}

export interface CustomResourcesItem {
  kind: string
  apiVersion: string
  metadata: ItemMetadata
  spec: Record<string, any>
  status: Record<string, any>
  group: string
  version: string
  pluralName: string
}

interface ItemMetadata {
  name: string
  namespace: string
  uid: string
  resourceVersion: string
  creationTimestamp: string
  labels?: Record<string, string>
  annotations?: Record<string, string>
  ownerReferences?: OwnerReference[]
  managedField?: ManagedField[]
}

interface OwnerReference {
  apiVersion: string
  kind: string
  name: string
  uid?: string
  namespace?: string
  blockOwnerDeletion?: boolean
  controller?: boolean
}

interface CustomResourceDefinitionSpec {
  group: string
  names: CustomResourceDefinitionNames
  scope?: 'Cluster' | 'Namespaced'
  versions: CustomResourceDefinitionVersion[]
  conversion: CustomResourceConversion
}

interface CustomResourceDefinitionNames {
  plural: string
  singular?: string
  shortNames?: string[]
  kind: string
  listKind?: string
  categories?: string[]
}

export interface CustomResourceDefinitionVersion {
  name: string
  served: boolean
  storage: boolean
  schema: CustomResourceValidation
}

interface CustomResourceValidation {
  openAPIV3Schema: Record<string, any>
}

interface CustomResourceConversion {
  strategy: string
}

interface CustomResourceDefinitionStatus {
  conditions: CustomResourceDefinitionCondition[]
  acceptedNames: CustomResourceDefinitionNames
  storedVersions: string[]
}

interface CustomResourceDefinitionCondition {
  type: string
  status: string
  lastTransitionTime: string
  reason?: string
  message?: string
}

export interface ICustomResourceDefinitionSelector
  extends IGenericResource<CustomResourceDefinitionItem> {
  clusterName: string
  group: CustomResourceDefinitionItem['spec']['group']
  scope: CustomResourceDefinitionItem['spec']['scope']
  latestVersion: string
  namespace: string
  creationTimestamp: CustomResourceDefinitionItem['metadata']['creationTimestamp']
  labels: CustomResourceDefinitionItem['metadata']['labels']
  annotations: CustomResourceDefinitionItem['metadata']['annotations']
  age?: string
}

export interface ICustomResourcesSelector extends IGenericResource<CustomResourcesItem> {
  clusterName: string
  clusterId: string
  phase: string
  group: string
  version: string
  pluralName: string
  id: string
  namespace: CustomResourcesItem['metadata']['namespace']
  creationTimestamp: CustomResourcesItem['metadata']['creationTimestamp']
  labels: CustomResourcesItem['metadata']['labels']
  age?: string
}

export enum APIVersion {
  KubevirtIoV1 = 'kubevirt.io/v1',
  KubevirtIoV1Alpha3 = 'kubevirt.io/v1alpha3',
}
export enum FieldsType {
  FieldsV1 = 'FieldsV1',
}

export interface FMetadata {
  'f:annotations': FAnnotations
  'f:generateName'?: FGenerateName
}

export interface FAnnotations {
  '.': FGenerateName
  'f:kubevirt.io/latest-observed-api-version': FGenerateName
  'f:kubevirt.io/storage-observed-api-version': FGenerateName
  'f:kubevirt.io/workloadUpdateMigration'?: FGenerateName
}

export interface FGenerateName {}

export interface FSpec {
  '.': FGenerateName
  'f:vmiName': FGenerateName
}

export interface FStatus {
  '.': FGenerateName
  'f:phase': FGenerateName
  'f:phaseTransitionTimestamps': FGenerateName
}

export interface FieldsV1 {
  'f:metadata'?: FMetadata
  'f:status'?: FStatus
  'f:spec'?: FSpec
}
export enum Manager {
  GoHTTPClient = 'Go-http-client',
  Mozilla = 'Mozilla',
}

export enum Operation {
  Update = 'Update',
}

export interface ManagedField {
  apiVersion: APIVersion
  fieldsType: FieldsType
  fieldsV1: FieldsV1
  manager: Manager
  operation: Operation
  time: string
  subresource?: string
}
