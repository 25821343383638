import { pick } from 'ramda'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { ArrayElement } from 'core/actions/Action'
import InferActionParams from 'core/actions/InferActionParams'
import DocumentMeta from 'core/components/DocumentMeta'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import ListContainer from 'core/containers/ListContainer'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import StringMultiDropdownFilter from 'core/elements/grid/filters/StringMultiDropdownFilter'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { routes } from 'core/utils/routes'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import DataKeys from 'k8s/DataKeys'
import { listCustomResourceDefinitions } from './actions'

import CreateButton from 'core/components/buttons/CreateButton'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import { GridDropdownBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import { HeaderPrimaryActionPortal } from 'core/elements/header/portals'
import AddCustomResourceDefinitionModal from './AddCustomResourceDefinitionModal'
import DeleteCustomResourceDefinitionDialog from './DeleteCustomResourceDefinitionDialog'
import {
  ICustomResourceDefinitionDetailsPageTabs,
  ICustomResourceDefinitionSelector,
} from './model'
import { createGroupOptionsSelector, customResourceDefinitionsSelector } from './selectors'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import { isReadonlyUser } from 'core/utils/helpers'

type ModelDataKey = DataKeys.CustomResourceDefinitions
type SelectorModel = ArrayElement<ReturnType<typeof customResourceDefinitionsSelector>>
type ActionParams = InferActionParams<typeof listCustomResourceDefinitions>
type Params = ActionParams & {
  masterNodeClusters: boolean
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']
const defaultParams: Params & SortingState = {
  clusterId: '',
  masterNodeClusters: true,
  healthyClusters: true,
  orderBy: 'name',
  orderDirection: 'asc',
}

const usePrefParams = createUsePrefParamsHook<ActionParams & TablePrefsParams>(
  'CustomResourceDefinitions',
  listTablePrefs,
)
const searchTargets = ['name', 'clusterName']

export const getCustomResourceDefinitionsColumns = (): GridViewColumn<SelectorModel>[] => {
  return [
    {
      key: 'name',
      label: 'Name',
      width: 'medium',
      CellComponent: createGridLinkCell({
        routeToFn: ({ clusterName, id }) =>
          routes.kubernetes.resources.customResourceDefinitions.details.path({
            cluster: clusterName,
            id,
            tab: ICustomResourceDefinitionDetailsPageTabs.Overview,
          }),
      }),
    },
    {
      key: 'group',
      label: 'Group',
    },
    {
      key: 'scope',
      label: 'Scope',
    },
    {
      key: 'latestVersion',
      label: 'Latest version',
    },
    {
      key: 'creationTimestamp',
      label: 'Created',
      CellComponent: DateAndTime,
    },
    {
      key: 'labels',
      label: 'Labels',
      disableSorting: true,
      CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
    },
  ]
}

export default function CustomResourceDefinitionsListPage() {
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterId = k8sPluginGlobalPerfs?.clusterId || ''
  const clusterName = k8sPluginGlobalPerfs?.cluster || ''

  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { message, loading, reload } = useListAction(listCustomResourceDefinitions, {
    params: { clusterId },
    requiredParams,
  })

  const customResourceDefinitions: ICustomResourceDefinitionSelector[] = useSelector(
    customResourceDefinitionsSelector,
  )
  const groupOptions = useSelector(createGroupOptionsSelector)
  const columns = getCustomResourceDefinitionsColumns()
  const [selectedCustomResourceDefinition, setSelectedCustomResourceDefinition] = useState<
    SelectorModel
  >(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (customResourceDefinition) => {
        setSelectedCustomResourceDefinition(customResourceDefinition)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  const dropdownFilters = useMemo(
    () => [
      {
        key: 'group',
        label: 'By Group',
        FilterComponent: StringMultiDropdownFilter,
        filterComponentProps: {
          label: 'By Group',
        },
        filterComponentOptionsPropName: 'dropdownOptions',
        getOptionsFn: () => {
          return groupOptions
        },
        equalityComparerFn: (item, value) => {
          return value.includes(item?.spec?.group)
        },
      },
    ],
    [groupOptions],
  )

  const dropdownBatchActions: GridDropdownBatchActionSpec<SelectorModel>[] = useMemo(
    () => [
      {
        label: 'Delete',
        BatchActionButton: getGridDialogButton(DeleteCustomResourceDefinitionDialog, {
          action: 'delete',
        }),
      },
    ],
    [],
  )

  return (
    <>
      <DocumentMeta title="Custom Resource Definitions" />
      <AddCustomResourceDefinitionModal reload={reload} />
      {showDeleteDialog && (
        <DeleteCustomResourceDefinitionDialog
          onClose={() => setShowDeleteDialog(false)}
          rows={[selectedCustomResourceDefinition]}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.CustomResourceDefinitions}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={customResourceDefinitions}
        columns={columns}
        addUrl={routes.kubernetes.resources.customResourceDefinitions.add.path({
          cluster: clusterName,
        })}
        addText={'Add CRD'}
        rowMenuItems={rowMenuItems}
        dropdownFilters={dropdownFilters}
        getParamsUpdater={getParamsUpdater}
        showItemsCountInLabel
        showRowMenuForSingleRowActions
        disableColumnOrdering
        batchActions={dropdownBatchActions}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
