import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import usePluginRouter from 'core/hooks/usePluginRouter'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import { routes } from 'core/utils/routes'
import { deletePersistentVolumeClaim } from './new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'
import Modal from 'core/elements/modal'

export default function DeletePersistentVolumeClaimDialog({
  rows: [persistentVolumeClaim],
  onClose,
}) {
  const { currentPluginId } = usePluginRouter()
  const { history } = useReactRouter()
  const { update: deleteFn, updating: deletingPersistentVolumeClaim } = useUpdateAction(
    deletePersistentVolumeClaim,
  )
  const title = `Permanently delete "${persistentVolumeClaim?.name}"?`
  const handleDelete = useCallback(async () => {
    await deleteFn(persistentVolumeClaim)
    onClose(true)
    history.push(
      routes.kubernetes.resources.persistentVolumeClaims.list.path({
        cluster: persistentVolumeClaim?.clusterName,
      }),
    )
  }, [persistentVolumeClaim, currentPluginId])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={title}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingPersistentVolumeClaim}>
            Don't Delete
          </Button>
          <Button onClick={handleDelete} loading={deletingPersistentVolumeClaim}>
            Delete Persistent Volume Claim
          </Button>
        </>
      }
    >
      <Text variant="body2">This action cannot be undone, are you sure you want to do this?</Text>
    </Modal>
  )
}
