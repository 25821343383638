import { makeStyles } from '@material-ui/styles'
import useReactRouter from 'use-react-router'
import clsx from 'clsx'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import colors from 'core/themes/base/colors'
import Theme from 'core/themes/model'
import React, { useEffect } from 'react'
import UsageWidgetProgress from './UsageWidgetProgress'
import { useSelector } from 'react-redux'
import {
  kaapiMetricsNodesSelector,
  kaapiNodesSelector,
} from '../../components/kaapi/workload-clusters/nodes/selectors'
import { totalCpuNodes, totalMemoryNodes } from './helpers'
import useListAction from 'core/hooks/useListAction'
import { listKaapiMachines } from '../../components/kaapi/machines/actions'

const ResourceUtilizationCard = () => {
  const { match } = useReactRouter()
  const { cluster } = match.params
  const classes = useStyles()
  const nodesMetricsDeployments = useSelector(kaapiMetricsNodesSelector)
  const nodeDeployments = useSelector(kaapiNodesSelector)
  // Calculate total maxCpu
  const totalMaxCpu = totalCpuNodes(nodeDeployments, 'maxCpu')
  // Calculate total usedCpu
  const totalUsedCpu = totalCpuNodes(nodeDeployments, 'usedCpu')
  // Calculate total maxMemory
  const totalMaxMemory = totalMemoryNodes(nodeDeployments, 'maxMemory')
  // Calculate total usedMemory
  const totalUsedMemory = totalMemoryNodes(nodeDeployments, 'usedMemory')

  // Fetch Worker Nodes
  const { loading: loadingKaapiMachines, reload: reloadKaapiMachines } = useListAction(
    listKaapiMachines,
  )
  useEffect(() => {
    if (cluster) {
      reloadKaapiMachines(true, false)
    }
  }, [cluster])

  // Data for all cards
  const cardData = [
    {
      icon: 'microchip',
      title: 'CPU',
      subTitle: '(Cores)',
      footerValues: [
        { key: 'PROVISIONED', value: '2' },
        { key: 'AVAILABLE', value: '4' },
        { key: 'CAPACITY', value: '6' },
      ],
      Trend: {
        key: 'Trend',
        value: '45.5%',
        subText: 'avg.',
      },
      graph: {
        values: [
          { name: '5:00 PM', firstRange: 1 },
          { name: '6:00 PM', firstRange: 10 },
          { name: '7:00 PM', firstRange: 4 },
          { name: '8:00 PM', firstRange: 2 },
          { name: '9:00 PM', firstRange: 1 },
          { name: '10:00 PM', firstRange: 1 },
        ],
        keys: [
          {
            name: 'firstRange',
            color: '#0089C7',
            legendName: 'CPU Utilization',
            offsetFirst: '0%',
            offsetSecond: '113.39%',
            stopOpacityFirst: 0.15,
            stopOpacitySecond: 0.0,
          },
        ],
        unit: 'cores',
      },
      usageWidget: {
        title: 'CPU',
        units: '%',
        current: totalUsedCpu,
        total: totalMaxCpu,
        subText: 'cores',
      },
    },
    {
      icon: 'memory',
      title: 'Memory',
      subTitle: '(GiB)',
      footerValues: [
        { key: 'PROVISIONED', value: '4.5' },
        { key: 'AVAILABLE', value: '7.5' },
        { key: 'CAPACITY', value: '12' },
      ],
      Trend: {
        key: 'Trend',
        value: '45.5%',
        subText: 'avg.',
      },
      graph: {
        values: [
          { name: '5:00 PM', firstRange: 2 },
          { name: '6:00 PM', firstRange: 6 },
          { name: '7:00 PM', firstRange: 5 },
          { name: '8:00 PM', firstRange: 3 },
          { name: '9:00 PM', firstRange: 2 },
          { name: '10:00 PM', firstRange: 1 },
        ],
        keys: [
          {
            name: 'firstRange',
            color: '#FF5722',
            legendName: 'Memory Utilization',
            offsetFirst: '0%',
            offsetSecond: '113.39%',
            stopOpacityFirst: 0.15,
            stopOpacitySecond: 0.0,
          },
        ],
        unit: 'GiB',
      },
      usageWidget: {
        title: 'Memory',
        units: '%',
        current: totalUsedMemory,
        total: totalMaxMemory,
        subText: 'GiB',
      },
    },
    // {
    //   icon: 'sim-card',
    //   title: 'Storage',
    //   subTitle: '(GiB)',
    //   footerValues: [
    //     { key: 'PROVISIONED', value: '16.5' },
    //     { key: 'AVAILABLE', value: '103.5' },
    //     { key: 'CAPACITY', value: '120' },
    //   ],
    //   Trend: {
    //     key: 'Trend',
    //     value: '45.5%',
    //     subText: 'avg.',
    //   },
    //   graph: {
    //     values: [
    //       { name: '5:00 PM', firstRange: 3 },
    //       { name: '6:00 PM', firstRange: 8 },
    //       { name: '7:00 PM', firstRange: 6 },
    //       { name: '8:00 PM', firstRange: 5 },
    //       { name: '9:00 PM', firstRange: 4 },
    //       { name: '10:00 PM', firstRange: 2 },
    //     ],
    //     keys: [
    //       {
    //         name: 'firstRange',
    //         color: '#4CAF50',
    //         legendName: 'Storage Utilization',
    //         offsetFirst: '0%',
    //         offsetSecond: '113.39%',
    //         stopOpacityFirst: 0.15,
    //         stopOpacitySecond: 0.0,
    //       },
    //     ],
    //     unit: 'GiB',
    //   },
    //   usageWidget: {
    //     title: 'Memory',
    //     units: '%',
    //     current: 16.5,
    //     total: 120,
    //     subText: 'GiB',
    //   },
    // },
  ]

  return (
    <div
      className={clsx(classes.cardsRow, 'responsive-dasboard-resource-utilization-card-container')}
    >
      {cardData.map((card, index) => (
        <UtilizationCard key={index} classes={classes} data={card} />
      ))}
    </div>
  )
}

const UtilizationCard = ({ classes, data }) => {
  const {
    icon,
    title,
    subTitle,
    footerValues,
    graph,
    Trend,
    onChange = () => {},
    usageWidget,
  } = data
  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardContent} style={{ padding: '0 8px 0 0', margin: '8px 0' }}>
        <div className={clsx(classes.badge)}>
          <FontAwesomeIcon size="sm">{icon}</FontAwesomeIcon>
        </div>
        <Text variant="subtitle2">{title}</Text>
        <Text variant="body2" className={clsx(classes.headerText, classes.customText)}>
          {subTitle}
        </Text>
      </div>
      <div className={clsx(classes.content, classes.progressBarWidth)}>
        <UsageWidgetProgress
          title={usageWidget?.title}
          units={''}
          current={usageWidget?.current}
          total={usageWidget?.total}
          usedText={'provisioned'}
          primary={`out of ${usageWidget?.total} ${usageWidget?.subText}`}
        />
      </div>
      {/* TODO:: Need to uncomment once get the Metrics API data for Trends */}
      {/* <div className={classes.divider} /> */}

      {/* {Trend?.value && (
        <div className={clsx(classes.rightContent)}>
          <Text variant="subtitle2">{Trend?.key}</Text>

          <TimePicklist
            onChange={onChange}
            currentTimeRange={'last_one_hour'}
            isModalView={false}
          />
          <span className={classes.seeAll}>
            <Text variant="subtitle1">{Trend?.value}</Text>{' '}
            <Text variant="caption3" className={classes.avgLable}>
              {Trend?.subText}
            </Text>
          </span>
        </div>
      )} */}
      {/* <div className={classes.content}>
        <CustomStackedAreaChart
          values={graph?.values}
          keys={graph?.keys}
          xAxis="name"
          responsive={true}
          verticalAxisLines={!graph?.values?.length}
          responsiveHeight={90}
          showXAxisTick={true}
          horizontalAxisLines={!graph?.values?.length}
          angle={-35}
          dy={6}
          dx={-8}
          customTooltip={
            <StackedAreaChartTooltip keys={graph?.keys} yAxisPostfixLabel={graph?.unit} />
          }
          xAxisTickFontSize={10}
          yAxisTickFontSize={10}
          axisLine={false}
        />
      </div> */}
      {/* <div className={clsx(classes.cardsRow, classes.content)}>
        {footerValues?.map((item, index) => (
          <div key={index} className={classes.gridContainer}>
            <Text variant="body2" className={clsx(classes.headerText, classes.customText)}>
              {item?.key}
            </Text>
            <div className={classes.footerContent}>
              <Text variant="caption1">{item?.value}</Text>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  )
}

export default ResourceUtilizationCard
const useStyles = makeStyles((theme: Theme) => ({
  rightContent: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', // Ensures vertical alignment
  },
  seeAll: {
    display: 'flex',
    alignItems: 'baseline',
    gap: 0, // Ensures no space between "See All" and the icon
  },
  cardsRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '16px',
  },
  gridContainer: {
    display: 'grid',
    gap: '8px',
  },
  cardContainer: {
    backgroundColor: theme.components.card.background,
    border: `1px solid ${theme.palette.grey[200]}`, // Add border here theme.components.card.border,
    borderRadius: '4px',
    padding: '24px',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
  },
  footerContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px', // Ensures no space between "See All" and the icon
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500],
  },
  headerText: {
    color: theme.components.card.passiveText,
  },
  customText: {
    fontSize: '12px',
  },
  progressBar: {
    height: '-webkit-fill-available',
  },
  progressBarWidth: {
    width: '100%',
  },
  cpuProgressBar: {
    backgroundColor: colors.teal[200],
    border: `1px solid ${colors.teal[100]}`,
  },
  memoryProgressBar: {
    backgroundColor: colors.beige[100],
    border: `1px solid ${colors.yellow[500]}`,
  },
  storageProgressBar: {
    backgroundColor: colors.pink[800],
    border: `1px solid ${colors.palette[700]}`,
  },
  divider: {
    border: '0',
    height: '1px',
    margin: '16px 0px',
    background: colors.grey[200],
  },
  content: {
    paddingTop: '16px',
  },
  avgLable: {
    paddingLeft: '4px',
  },
}))
