import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import useReactRouter from 'use-react-router'
import jsYaml from 'js-yaml'

import ExternalLink from 'core/components/ExternalLink'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Note from 'core/components/validatedForm/Note'
import ModalForm from 'core/elements/modal/ModalForm'
import Text from 'core/elements/Text'
import useParams from 'core/hooks/useParams'
import usePluginRouter from 'core/hooks/usePluginRouter'
import useUpdateAction from 'core/hooks/useUpdateAction'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import RadioSelectableCard from 'k8s/components/common/RadioSelectableCard'
import {
  enableSriovLinkWithConfigMapAnchor,
  enableSriovWithDpdkLinkWithConfigMapAnchor,
} from 'k8s/links'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import ConfigMapParamFields from './ConfigMapParamFields'
import ConfigMapYamlParamFields from './ConfigMapYamlParamFields'
import { createConfigMap } from './actions'

export default function AddConfigMapModal(props) {
  const { history, location, match } = useReactRouter()
  const { cluster } = match.params
  const urlParams = new URLSearchParams(location.search)
  const forSriovPlugin = urlParams.get('for') === 'sriov'
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const defaultClusterId = k8sPluginGlobalPerfs?.clusterId
  const networkPluginId = urlParams.get('networkPluginId')
  const classes = useStyles()
  const { currentPluginId } = usePluginRouter()
  const routePath = currentPluginId === 'kubevirt' ? 'kubevirtConfiguration' : 'configuration'

  const defaultParams = {
    method: forSriovPlugin ? 'yaml' : 'form',
    clusterId: defaultClusterId || null,
    name: '',
    namespace: null,
    immutable: undefined,
    data: {},
    binaryData: {},
    yaml: '',
  }

  const { params, updateParams, setParams } = useParams<{
    method: string
    clusterId: string
    name: string
    namespace: string
    immutable?: boolean
    data?: any
    binaryData?: any
    yaml?: string
  }>(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(createConfigMap)

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.kubernetes.resources.configMaps.list.path({ cluster }))
  }

  const handleSriovClose = () => {
    const networksRoutePath = currentPluginId === 'kubevirt' ? 'kubevirtNetworking' : 'networking'
    setParams(defaultParams)
    reset()
    history.push(
      routes[networksRoutePath].pluginDetails.path({
        clusterId: defaultClusterId,
        id: networkPluginId,
      }),
    )
  }

  const submitForm = useCallback(async () => {
    if (params?.method === 'yaml') {
      const body = jsYaml.load(params?.yaml)

      const { success } = await update({
        clusterId: params?.clusterId,
        namespace: params?.namespace || body?.metadata?.namespace,
        body,
      })
      if (success) {
        if (forSriovPlugin) {
          handleSriovClose()
        } else {
          handleClose()
        }
      }
    } else {
      const body = {
        apiVersion: 'v1',
        kind: 'ConfigMap',
        metadata: {
          name: params?.name,
        },
        data: params?.data,
        binaryData: params?.binaryData,
        immutable: params?.immutable,
      }

      const { success } = await update({
        clusterId: params?.clusterId,
        namespace: params?.namespace,
        body,
      })
      if (success) handleClose()
    }
  }, [params, handleClose, handleSriovClose, forSriovPlugin, defaultClusterId, networkPluginId])

  return (
    <ModalForm
      route={routes.kubernetes.resources.configMaps.add}
      title="Add Config Map"
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      maxWidth={528}
    >
      <div className={classes.container}>
        <Text variant="body2">To start configuration choose the method of creation.</Text>

        {forSriovPlugin && (
          <Note title="SR-IOV Device Plugin Configuration:">
            <div className={classes.noteContents}>
              <Text variant="body2">
                Follow the steps in the documentation to create the required Config Map:
              </Text>
              <div className={classes.buttons}>
                <ExternalLink url={enableSriovLinkWithConfigMapAnchor}>
                  <div className={classes.button}>
                    <FontAwesomeIcon>arrow-up-right-from-square</FontAwesomeIcon>
                    <Text variant="caption1">SR-IOV</Text>
                  </div>
                </ExternalLink>
                <ExternalLink url={enableSriovWithDpdkLinkWithConfigMapAnchor}>
                  <div className={classes.button}>
                    <FontAwesomeIcon>arrow-up-right-from-square</FontAwesomeIcon>
                    <Text variant="caption1">SR-IOV + DPDK</Text>
                  </div>
                </ExternalLink>
              </div>
            </div>
          </Note>
        )}
        {!forSriovPlugin && (
          <div className={classes.radioCards}>
            <Text variant="caption2" className={classes.radioText}>
              Select one of the below methods
            </Text>
            <RadioSelectableCard
              onClick={() => updateParams({ method: 'form' })}
              label="Fill in the form"
              active={params?.method === 'form'}
              subtitle=""
            />
            <RadioSelectableCard
              onClick={() => updateParams({ method: 'yaml' })}
              label="Upload or paste YAML"
              active={params?.method === 'yaml'}
              subtitle=""
            />
          </div>
        )}
        <hr className={classes.divider} />
        {params?.method === 'yaml' && (
          <ConfigMapYamlParamFields params={params} updateParams={updateParams} />
        )}

        {params?.method === 'form' && (
          <div className={classes.fields}>
            <ConfigMapParamFields params={params} updateParams={updateParams} />
          </div>
        )}
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: '16px 40px 40px 32px',
    display: 'grid',
    gap: 32,
  },
  radioText: {
    textTransform: 'uppercase',
    color: theme.components.typography.passive,
  },
  radioCards: {
    display: 'grid',
    gap: 16,
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    width: '100%',
  },
  fields: {
    display: 'grid',
    gap: 24,
  },
  buttons: {
    display: 'grid',
    gap: 16,
    gridTemplateColumns: '1fr 1fr',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    background: theme.components.button.secondary.background,
    padding: 8,
  },
  noteContents: {
    display: 'grid',
    gap: 24,
  },
}))
