export const DOCUMENT_LINKS = {
  K8S_GETTING_STARTED:
    'https://platform9.com/docs/private-cloud-director/private-cloud-director/getting-started-with-kubernetes-in-pcd',
  K8S_IMAGE_MANAGEMENT:
    'https://platform9.com/docs/private-cloud-director/private-cloud-director/kubernetes-operating-system-image-management',
  K8S_CLUSTER_UPGRADE:
    'https://platform9.com/docs/private-cloud-director/private-cloud-director/upgrade-kubernetes-clusters',
  K8S_ACCEPT_CERTIFICATE_AUTHORITY:
    'https://platform9.com/docs/private-cloud-director/private-cloud-director/accept-certificate-authority',
}
