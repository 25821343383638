import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { getControlPlaneStatus } from './helper'
import { ClusterUpgradeSteps } from './model'
import Stepper from './Stepper'
import Accordion from 'core/components/accordion/Accordion'
import Text from 'core/elements/Text'
import Theme from 'core/themes/model'
import clsx from 'clsx'
import { formatDate } from 'utils/misc'
import ClusterStatusSpan from 'k8s/components/resources/persistent-volume-claims/ClusterStatusSpan'

const StepHeader = ({ resourceType, upgradeProgressCount = '' }) => {
  const classes = useStyles()
  return (
    <div className={classes.headerContainer}>
      <Text variant="subtitle2">
        {resourceType}
        <span className={classes.upgradeText}> Upgrade</span>
      </Text>
      {upgradeProgressCount && <Text variant="body2">{upgradeProgressCount}</Text>}
    </div>
  )
}

const ControlPlaneStepProgress = ({ status, severity, startTime, completionTime }) => {
  const classes = useStyles()
  const controlPlaneStatus = getControlPlaneStatus(status, severity)
  return (
    <>
      <StepHeader resourceType="Control Plane" />
      <div className={classes.accordionWrapper}>
        <Accordion
          id={'controlPlane'}
          title={
            <div className={classes.accordianRow}>
              <ClusterStatusSpan
                status={controlPlaneStatus}
                iconStatus={!!status && status !== 'Unknown'}
              >
                Control Plane
              </ClusterStatusSpan>
            </div>
          }
          className={clsx(classes.accordian, 'firstAccordion')}
        >
          <div className={classes.nodeGroupAccordianContent}>
            <div className={classes.startTime}>
              <Text variant="caption1">Start Time</Text>
              <Text variant="body2">{startTime ? formatDate(startTime) : 'N/A'}</Text>
            </div>
            <div>
              <Text variant="caption1">Completion Time</Text>
              <Text variant="body2">
                {controlPlaneStatus === 'loading'
                  ? '-'
                  : completionTime
                  ? formatDate(completionTime)
                  : 'N/A'}
              </Text>
            </div>
          </div>
        </Accordion>
      </div>
    </>
  )
}

const nodeGroupUpgradeStatusMap = {
  Unknown: 'unknown',
  NotStarted: 'unknown',
  InProgress: 'loading',
  Completed: 'ok',
  Failed: 'fail',
}

const nodeGroupUpgradeSuccessful = (nodeGroup) =>
  nodeGroup?.phase === 'Completed' ||
  (nodeGroup?.completionTime && nodeGroup?.phase === 'InProgress')

const getNodeGroupStatus = (nodeGroup) => {
  // if (!nodeGroup?.upgradeStatus) return 'unknown' //TODO: check this if we need this
  if (nodeGroupUpgradeSuccessful(nodeGroup)) return 'ok'
  return nodeGroupUpgradeStatusMap[nodeGroup?.upgradeStatus || nodeGroup?.phase]
}

const NodeGroupsStepProgress = ({ nodeGroups = [] }) => {
  const classes = useStyles()
  const numCompleted = nodeGroups.filter(nodeGroupUpgradeSuccessful).length
  const total = (nodeGroups || []).length
  const upgradeProgressCount = `${numCompleted} of ${total} completed`
  return (
    <>
      <StepHeader resourceType="Worker Node Groups" upgradeProgressCount={upgradeProgressCount} />
      <div className={classes.accordionWrapper}>
        {nodeGroups.map((nodeGroup, index) => {
          const { kind, name, startTime, completionTime, phase } = nodeGroup
          return (
            <Accordion
              id={name}
              key={name}
              title={
                <div className={classes.accordianRow}>
                  <ClusterStatusSpan
                    status={getNodeGroupStatus(nodeGroup)}
                    iconStatus={!!phase && phase !== 'Unknown' && phase !== 'NotStarted'}
                    className={classes.nodeGroupStatus}
                  >
                    {name}
                  </ClusterStatusSpan>
                </div>
              }
              className={clsx(classes.accordian, ` ${index === 0 ? 'firstAccordion' : ''}`)}
            >
              <div className={classes.nodeGroupAccordianContent}>
                <div className={classes.startTime}>
                  <Text variant="caption1">Start Time</Text>
                  <Text variant="body2">{startTime ? formatDate(startTime) : 'N/A'}</Text>
                </div>
                <div>
                  <Text variant="caption1">Completion Time</Text>
                  <Text variant="body2">{completionTime ? formatDate(completionTime) : 'N/A'}</Text>
                </div>
              </div>
            </Accordion>
          )
        })}
      </div>
    </>
  )
}

/* Might need this in future for Addons */
// const getAddonStatus = (status) =>
//   switchCase(
//     {
//       Unknown: 'unknown',
//       True: 'ok',
//       False: 'fail',
//     },
//     'unknown',
//   )(status)
// const AddonsStepProgress = ({ status }) => {
//   const classes = useStyles()
//   const addonsStatus = getAddonStatus(status)
//   return (
//     <>
//       <StepHeader resourceType="Add-ons" />
//       <div className={classes.tableRow}>
//         <ClusterStatusSpan status={addonsStatus} iconStatus={!!status && status !== 'Unknown'}>
//           Add-ons
//         </ClusterStatusSpan>
//       </div>
//     </>
//   )
// }
const ClusterUpgradeProgressTracker = ({ clusterUpgradeJob }) => {
  const {
    controlPlaneCondition,
    nodeGroups,
    nodeGroupsCondition,
    addonsCondition,
    completedSteps,
    metadata,
  } = clusterUpgradeJob || {}

  const upgradeSteps = [
    {
      stepId: ClusterUpgradeSteps.ControlPlane,
      content: (
        <ControlPlaneStepProgress
          status={controlPlaneCondition?.status}
          severity={controlPlaneCondition?.severity}
          startTime={metadata?.creationTimestamp}
          completionTime={controlPlaneCondition?.lastTransitionTime}
        />
      ),
      failed:
        getControlPlaneStatus(controlPlaneCondition?.status, controlPlaneCondition?.severity) ===
        'fail',
    },
    {
      stepId: ClusterUpgradeSteps.WorkerNodeGroups,
      content: <NodeGroupsStepProgress nodeGroups={nodeGroups} />,
      failed:
        nodeGroupsCondition?.status === 'False' && nodeGroupsCondition?.reason !== 'InProgress',
    },
    /* Might need this in future for Addons */
    // {
    //   stepId: ClusterUpgradeSteps.Addons,
    //   content: <AddonsStepProgress status={addonsCondition?.status} />,
    //   failed:
    //     nodeGroupsCondition?.status === 'False' && nodeGroupsCondition?.reason !== 'InProgress',
    // },
  ]
  return <Stepper steps={upgradeSteps} completedSteps={completedSteps} />
}

export default ClusterUpgradeProgressTracker
const useStyles = makeStyles<Theme>((theme) => ({
  headerContainer: {
    display: 'flex',
    flexFlow: 'column',
    width: '614px',
    padding: '12px 16px',
    borderWidth: '1px 1px 0px 1px',
    borderStyle: 'solid',
    backgroundColor: theme.components.accordion.background,
    borderColor: theme.components.card.border,
  },
  upgradeText: {
    fontWeight: 'normal',
  },
  tableRow: {
    display: 'grid',
    border: `1px solid ${theme.components.card.border}`,
    boxSizing: 'border-box',
    padding: '12px',
    '& > div': {
      marginLeft: theme.spacing(2),
    },
  },
  accordianRow: {
    display: 'grid',
    gridTemplateColumns: '296px 185px',
    gap: theme.spacing(2),
    padding: '12px',
    width: '100%',
  },
  nodeGroupStatus: {
    whiteSpace: 'break-spaces',
  },
  accordian: {
    '& .accordionTopBar': {
      backgroundColor: 'unset',
      borderTop: 'unset',
      border: `1px solid ${theme.components.card.border}`,
    },
    '& .accordianContent': {
      backgroundColor: theme.components.card.activeBackground,
    },
    '& .firstAccordion': {
      borderTop: `1px solid ${theme.components.card.border}`,
    },
  },
  nodeGroupAccordianContent: {
    display: 'grid',
    gridTemplateColumns: '303px 185px',
    gap: theme.spacing(2),
    padding: '12px',
    border: `1px solid ${theme.components.card.border}`,
    borderTop: 'none',
  },
  startTime: {
    marginLeft: '40px',
  },
  accordionWrapper: {
    '& .firstAccordion': {
      borderTop: `1px solid ${theme.components.card.border}`,
    },
  },
}))
