import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import yaml from 'js-yaml'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import { pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'

const { capi } = ApiClient.getInstance()

const storageClassActions = ActionsSet.make<DataKeys.StorageClasses>({
  uniqueIdentifier: 'metadata.uid',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.StorageClasses],
  cacheKey: DataKeys.StorageClasses,
})

export const listStorageClasses = storageClassActions.add(
  new ListAction<DataKeys.StorageClasses, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Storage Classes', params)
    return capi.getClusterStorageClasses(params.clusterId)
  }),
)

export const createStorageClass = storageClassActions.add(
  new CreateAction<DataKeys.StorageClasses, { clusterId: string; storageClassYaml: string }>(
    async ({ clusterId, storageClassYaml }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create Storage Class', { clusterId, storageClassYaml })
      const body = yaml.load(storageClassYaml)
      const created = await capi.createStorageClass(clusterId, body)
      trackEvent('Create Storage Class', {
        id: pathStr('metadata.uid', created),
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)

export const updateStorageClass = storageClassActions.add(
  new UpdateAction<
    DataKeys.StorageClasses,
    {
      clusterId: string
      name: string
      body: unknown
    }
  >(async ({ clusterId, name, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Storage Class', {
      clusterId,
      name,
      body,
    })
    const data = await capi.updateClusterStorageClass(clusterId, name, body)
    trackEvent('Update Storage Class', { clusterId, name })
    return data
  }),
)

export const deleteStorageClass = storageClassActions.add(
  new DeleteAction<DataKeys.StorageClasses, { clusterId: string; name: string }>(
    async ({ clusterId, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Storage Class', { clusterId, name })
      await capi.deleteStorageClass(clusterId, name)
      trackEvent('Delete Storage Class', { clusterId, name })
    },
  ),
)

const clusterEventsActions = ActionsSet.make<DataKeys.Events>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey.Events,
  cacheKey: DataKeys.Events,
  // cache: false,
})

export const listClusterEvents = clusterEventsActions.add(
  new ListAction<DataKeys.Events, { clusterId: string }>(async ({ clusterId }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get cluster events', { clusterId })
    try {
      return capi.getClusterEvents(clusterId)
    } catch {
      return []
    }
  }),
)

export const deleteClusterEvent = clusterEventsActions.add(
  new DeleteAction<DataKeys.Events, { clusterId: string; namespace: string; name: string }>(
    async ({ clusterId, namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete cluster event', {
        clusterId,
        namespace,
        name,
      })
      trackEvent('Delete Cluster Event', { clusterId, namespace, name })
      await capi.deleteClusterEvent(clusterId, namespace, name)
    },
  ),
)
