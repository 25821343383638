import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'ramda'
import jsYaml from 'js-yaml'

import { clientActions } from 'core/client/clientReducers'
import useReactRouter from 'use-react-router'
import useListAction from 'core/hooks/useListAction'
import { listSecrets, updateSecret } from '../actions'
import { ISecretsDetailsPageTabs, ISecretSelector } from '../model'
import { secretsSelector } from '../selectors'
import useUpdateAction from 'core/hooks/useUpdateAction'
import DocumentMeta from 'core/components/DocumentMeta'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import { routes } from 'core/utils/routes'
import SecretsOverview from './SecretsOverview'
import EntityYamlPage from 'k8s/components/common/entity/entity-yaml-page'
import ApiClient from 'api-client/ApiClient'
import { pathStrOr } from 'utils/fp'
import OverviewActions from 'core/elements/overview/OverviewActions'
import DeleteSecretDialog from '../DeleteSecretDialog'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { K8sPluginGlobalPrefs } from 'k8s/model'

const { capi } = ApiClient.getInstance()

const actions = {
  rightActions: [
    {
      label: 'Delete',
      icon: 'trash-alt',
      DialogComponent: DeleteSecretDialog,
    },
  ],
}

export default function SecretsDetailsPage() {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { id } = match.params
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterId = k8sPluginGlobalPerfs?.clusterId
  const { loading, reload } = useListAction(listSecrets, { params: { clusterId } })
  const secrets: ISecretSelector[] = useSelector(secretsSelector)
  const secret = useMemo(() => secrets.find((secret) => secret.id === id), [id, secrets])

  const { update, updating } = useUpdateAction(updateSecret)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: secret?.clusterName || clusterId,
        id: secret?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [secret?.clusterName, secret?.name, id, clusterId])

  const getSecretYaml = useCallback(async () => {
    if (isEmpty(secret)) return undefined
    return capi.getSecret(secret.clusterId, secret.namespace, secret.name)
  }, [secret])

  const handleSecretUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const namespace = pathStrOr('', 'metadata.namespace', body)
    const name = pathStrOr('', 'metadata.name', body)
    await update({
      id,
      clusterId,
      namespace,
      name,
      body,
    })
  }

  return (
    <>
      <DocumentMeta title="Secrets Overview" breadcrumbs />
      <div>
        <OverviewActions actions={actions} entity={secret} />
      </div>
      <Tabs route={routes.kubernetes.resources.secrets.details}>
        <Tab label="Overview" value={ISecretsDetailsPageTabs.Overview}>
          <SecretsOverview secret={secret} loading={loading} reload={reload} />
        </Tab>
        <Tab label="YAML" value={ISecretsDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Secrets"
            entityName={secret?.name}
            getYamlFn={getSecretYaml}
            handleUpdate={handleSecretUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}
