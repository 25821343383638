import ApiClient from 'api-client/ApiClient'
import { encodeStr } from 'utils/misc'
import jsYaml from 'js-yaml'
import { createKaapiSecretBody } from 'k8s/components/kaapi/secrets/action-helpers'
import store from 'app/store'
import ActionsSet from 'core/actions/ActionsSet'
import DeleteAction from 'core/actions/DeleteAction'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import { groupByRegion } from 'api-client/Keystone'
import { isDevelopment, isLocalhost } from 'core/utils/helpers'
import config from 'app-config'

const { kubernetes, keystone } = ApiClient.getInstance()

// Get the Infra Region FQDN
export const getInfraRegionURL = () => {
  const { session } = store.getState()
  const { isSingleRegionDeployment } = session

  if (isSingleRegionDeployment) {
    if (isDevelopment() || isLocalhost()) {
      return config.apiHost
    }
    return window?.location?.origin
  }

  const { serviceCatalog } = ApiClient.getInstance()
  const servicesByRegion = groupByRegion(serviceCatalog)
  const resmgrEndpoint = servicesByRegion?.['Infra']?.resmgr?.public?.url
  const baseUrl = resmgrEndpoint?.split('/resmgr')?.[0]
  return baseUrl
}

export enum ApplicationCredentialType {
  Cluster = 'cluster', // Cluster level
  IdentityRef = 'identityRef', // User-tenant Level
}

export const kaapiSecretActions = ActionsSet.make<DataKeys.KaapiSecrets>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.KaapiSecrets],
  cacheKey: DataKeys.KaapiSecrets,
})

export const listKaapiSecrets = kaapiSecretActions.add(
  new ListAction<DataKeys.KaapiSecrets>(async ({ namespace }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Kaapi Machines ', { namespace })
    return kubernetes.getKaapiSecrets({ namespace })
  }),
)

export const deleteKaapiSecret = kaapiSecretActions.add(
  new DeleteAction<DataKeys.KaapiSecrets, { namespace: string; name: string }>(
    async ({ namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Kaapi Secret', { namespace, name })
      await kubernetes.deleteKaapiSecret({ name })
    },
  ),
)

export const getApplicationSecretName = (type, clusterName = '') => {
  const { session } = store.getState()
  const {
    userDetails: { email },
    activeTenant,
  } = session

  if (type === ApplicationCredentialType.Cluster) {
    return `${clusterName}-cloud-config`
  } else {
    const username = email.split('@')[0].replaceAll('.', '-')
    if (!activeTenant || !username) return ''

    return `pf9-${username}-${activeTenant}`
  }
}

// Cleanup kaapi application credentials and secrets
export const cleanupKaapiSecrets = async (clusterName) => {
  const { session } = store.getState()
  const {
    userDetails: { userId },
  } = session
  const applicationCredentialKey = getApplicationSecretName(
    ApplicationCredentialType.Cluster,
    clusterName,
  )

  // Get all application credentials and find matching application credential
  const applicationCredentials = await keystone.getAllApplicationCredentials({
    userId,
  })
  const matchingApplicationCredential = applicationCredentials?.find(
    (ac) => ac.name === applicationCredentialKey,
  )

  // Delete application credential and kaapi secret
  if (matchingApplicationCredential) {
    await keystone.deleteApplicationCredential({ userId, id: matchingApplicationCredential.id })
    await kubernetes.deleteKaapiSecret({ name: applicationCredentialKey })
  }
}

// Create application credential for user-tenant and generate identityRef for kaapi
export const createKaapiApplicationCredentials = async (
  type: ApplicationCredentialType = ApplicationCredentialType.IdentityRef,
  clusterName: string = '',
  isLoadBalancerEnabled: boolean = false,
) => {
  const { session } = store.getState()
  const {
    userDetails: { userId },
    activeKaapiTenant,
  } = session

  // Create application credential name
  const applicationCredentialKey = getApplicationSecretName(type, clusterName)

  if (!applicationCredentialKey) return

  const body = {
    application_credential: {
      unrestricted: false,
      name: applicationCredentialKey,
      secret: null,
      roles: [],
      expires_at: null,
      access_rules: [],
      description: '',
    },
  }

  try {
    // Create new application credential for openstack
    const applicationCredentials = await keystone.createApplicationCredentials({
      userId,
      body,
    })

    // If application credentials is created create new kubernetes secret for kaapi
    if (applicationCredentials) {
      await createKaapiSecrets({
        type,
        applicationCredentials,
        activeKaapiTenant,
        userId,
        isLoadBalancerEnabled,
      })
    }
  } catch (e) {
    console.log('Error creating application credentials: ', e)
  }
}

// Create IdentityRef secret for kaapi
export const createKaapiSecrets = async ({
  type,
  applicationCredentials,
  activeKaapiTenant,
  userId,
  isLoadBalancerEnabled,
}) => {
  const auth_url = `${getInfraRegionURL()}/keystone/v3/auth/tokens?nocatalog`
  const { name, id, secret } = applicationCredentials

  try {
    const cloudYaml =
      type === ApplicationCredentialType.Cluster
        ? encodeStr(
            jsYaml.dump({
              cloudConfig: {
                loadBalancer: {
                  enabled: isLoadBalancerEnabled,
                },
                global: {
                  'auth-url': auth_url,
                  'application-credential-id': id,
                  'application-credential-secret': secret,
                },
              },
            }),
          )
        : encodeStr(
            jsYaml.dump({
              clouds: {
                openstack: {
                  auth: {
                    auth_url,
                    application_credential_id: id,
                    application_credential_secret: secret,
                  },
                  identity_api_version: 3,
                },
              },
            }),
          )

    const secretData = await kubernetes.createKaapiSecret({
      body: createKaapiSecretBody({
        name,
        cloudYaml,
        namespace: activeKaapiTenant,
        type:
          type === ApplicationCredentialType.IdentityRef
            ? 'Opaque'
            : 'addons.projectsveltos.io/cluster-profile',
      }),
    })

    // If the kaapi secret is not created successfully - delete the application credentials
    if (!secretData) {
      await keystone.deleteApplicationCredential({ userId, id })
    }
  } catch (e) {
    await keystone.deleteApplicationCredential({ userId, id })
    console.log('Error creating kaapi secret :', e)
  }
}
