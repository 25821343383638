import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Progress from 'core/components/progress/Progress'
import { renderAge } from 'k8s/components/common/entity/helpers'
import SummaryCard from 'k8s/components/common/entity/summary-card'
import { IConfigMapSelector } from 'k8s/components/resources/config-maps/selectors'
import InfoCard from 'k8s/components/common/entity/info-card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import Grid, { GridViewColumn } from 'core/elements/grid/Grid'
import Card from 'core/elements/card/Card'
import Text from 'core/elements/Text'
import SimpleLink from 'core/components/SimpleLink'
import ConfigMapsOwnerRefInfoModal from './ConfigMapsOwnerRefInfoModal'
import { OwnerReference } from 'k8s/components/resources/config-maps/models'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import CopyToClipboard from 'core/components/CopyToClipboard'
import GridDefaultCell from 'core/elements/grid/cells/GridDefaultCell'
import ConfigMapsDataInfoModal from './ConfigMapsDataInfoModal'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import ConfigMapImmutabilityConfirmationDialog from './ConfigMapImmutabilityConfirmationDialog'
import DeleteConfigMapDataDialog from './DeleteConfigMapDataDialog'
import Dropdown from 'core/elements/dropdown'
import { allKey } from 'app/constants'

export const OwnerReferences = ({ refs = [] }: { refs: OwnerReference[] }) => {
  const [showModal, setShowModal] = useState(false)
  const [ownerRef, setOwnerRef] = useState<OwnerReference>()

  const handleClickForRef = (ref) => (event) => {
    setOwnerRef(ref)
    setShowModal(true)
  }
  return (
    <>
      <ConfigMapsOwnerRefInfoModal
        key={`${ownerRef?.uid}-modal`}
        ownerRef={ownerRef}
        open={showModal}
        onClose={() => setShowModal(false)}
      />
      {refs?.map((ownerRef) => (
        <>
          <Text key={`${ownerRef?.uid}-name`} variant="caption1">
            {ownerRef?.name}
          </Text>
          <br />
          <SimpleLink key={`${ownerRef?.uid}-link`} onClick={handleClickForRef(ownerRef)}>
            See More
          </SimpleLink>
        </>
      ))}
    </>
  )
}

export const GridCellWithCopyIcon = (value) => {
  const classes = useStyles()
  return (
    <CopyToClipboard className={classes.copyClipboard} copyText={value} codeBlock={false} inline>
      <GridDefaultCell>{value}</GridDefaultCell>
    </CopyToClipboard>
  )
}

const keyValuesColums: GridViewColumn<{ key: string; value: string }>[] = [
  { key: 'key', label: 'Key', width: 'medium' },
  { key: 'value', label: 'Value', render: GridCellWithCopyIcon },
]

const summaryRows: { key: keyof IConfigMapSelector; label: string }[] = [
  { key: 'clusterName', label: 'Cluster' },
  { key: 'namespace', label: 'Namespace' },
  { key: 'age', label: 'Age' },
]

const options = [
  { label: 'Data', value: 'data' },
  { label: 'Binary Data', value: 'binaryData' },
]

const ConfigMapDataPicklist = ({ onChange, ...rest }) => {
  return (
    <Dropdown
      {...rest}
      compact
      showAll
      label="Key Data Type"
      name="role"
      onChange={onChange}
      items={options}
    />
  )
}

const ConfigMapsOverview = ({
  configMap,
  loading,
  reload,
}: {
  configMap: IConfigMapSelector
  loading: boolean
  reload: (refetch: boolean) => void
}) => {
  const classes = useStyles()

  const [selectedConfigMapData, setSelectedConfigMapData] = useState<{
    key: string
    value: string
  }>({
    key: '',
    value: '',
  })
  const [showImmutabilityDialog, setShowImmutabilityDialog] = useState(false)
  const [showDeleteConfigMapDataDialog, setShowDeleteConfigMapDataDialog] = useState(false)
  const [showConfigMapDataModal, setShowConfigMapDataModal] = useState(false)

  const metadataFields = [
    {
      id: 'age',
      title: 'Created',
      required: true,
      render: renderAge,
    },
    {
      id: 'immutable',
      title: 'Immutable Enabled',
      helpMessage: 'If enabled, the config map cannot be modified.',
      required: true,
      render: (value) => (
        <ToggleSwitch
          active={value}
          onClick={() => setShowImmutabilityDialog(true)}
          disabled={configMap?.immutable}
        />
      ),
    },
    {
      id: 'ownerReferences',
      title: 'OwnerReferences',
      required: true,
      render: (value) => <OwnerReferences refs={value} />,
      condition: ({ ownerReferences }) => ownerReferences?.length > 0,
    },
  ]

  const rowMenuItems: Array<GridRowMenuItemSpec<{ key: string; value: string }>> = [
    {
      label: 'View',
      icon: 'eye',
      handleClick: (configMapData) => {
        setSelectedConfigMapData(configMapData)
        setShowConfigMapDataModal(true)
      },
      refreshAfterSuccess: true,
    },
    {
      cond: () => !configMap?.immutable,
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (configMapData) => {
        setSelectedConfigMapData(configMapData)
        setShowDeleteConfigMapDataDialog(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, configMap)
  }, [configMap])

  const configMapData = useMemo(() => {
    if (!configMap?.data) return []
    return Object.entries(configMap.data).map(([key, value]) => {
      return { key, value, type: 'data' }
    })
  }, [configMap])

  const configMapBinaryData = useMemo(() => {
    if (!configMap?.binaryData) return []
    return Object.entries(configMap.binaryData).map(([key, value]) => {
      return { key, value, type: 'binaryData' }
    })
  }, [configMap])

  const combinedConfigMapData = useMemo(() => {
    return [...configMapData, ...configMapBinaryData]
  }, [configMap])

  const [currKeyType, setCurrKeyType] = useState(allKey)

  const filters = useMemo(
    () => [
      {
        columnKey: 'type',
        FilterComponent: ConfigMapDataPicklist,
        onChange: (type) => {
          setCurrKeyType(type)
        },
      },
    ],
    [],
  )
  return (
    <Progress loading={loading}>
      <div className={classes.container}>
        <DeleteConfigMapDataDialog
          configMap={configMap}
          configMapData={selectedConfigMapData}
          open={showDeleteConfigMapDataDialog}
          onClose={() => setShowDeleteConfigMapDataDialog(false)}
          reload={reload}
        />
        <ConfigMapImmutabilityConfirmationDialog
          configMap={configMap}
          open={showImmutabilityDialog}
          onClose={() => setShowImmutabilityDialog(false)}
          reload={reload}
        />
        <ConfigMapsDataInfoModal
          configMap={configMap}
          configMapData={selectedConfigMapData}
          open={showConfigMapDataModal}
          onClose={() => setShowConfigMapDataModal(false)}
        />
        <div className={classes.column}>
          <SummaryCard<IConfigMapSelector>
            title={configMap?.name}
            subtitle="Config Map"
            rows={summaryRows}
            data={configMap}
          />
          <InfoCard
            items={metadata}
            title="Properties"
            footer={
              <div className={classes.footer}>
                <LabelsAndAnnotationsSection entity={configMap} resourceType="configMap" />
              </div>
            }
          />
        </div>
        <div className={classes.column}>
          <Card title={null} withCustomBody>
            <Grid
              uniqueIdentifier="key"
              label="Config Map Data"
              data={combinedConfigMapData}
              columns={keyValuesColums}
              //@ts-ignore
              filters={filters}
              loading={loading}
              compact
              rowMenuItems={rowMenuItems}
              // disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutosummaryRows: 'max-content',
  },
  footer: {
    margin: theme.spacing(1),
  },
  copyClipboard: {
    '& .copy': {
      color: theme.components.typography.passive,
    },
  },
}))

export default ConfigMapsOverview
