import { makeStyles } from '@material-ui/styles'
import { codeMirrorOptions } from 'app/constants'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import TextFileDrop from 'core/elements/grid/TextFileDrop'
import ModalForm from 'core/elements/modal/ModalForm'
import Text from 'core/elements/Text'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Theme from 'core/themes/model'
import { customValidator, requiredValidator, yamlValidator } from 'core/utils/fieldValidators'
import { routes } from 'core/utils/routes'
import jsYaml from 'js-yaml'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import moize from 'moize'
import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import { createPersistentVolume } from './actions'

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: '16px 40px 40px 32px',
    display: 'grid',
    gap: 24,
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    width: '100%',
  },
  fields: {
    display: 'grid',
    gap: 24,
  },
}))

const moizedYamlLoad = moize(jsYaml.load, {
  maxSize: 10,
})

const codeMirrorValidations = [
  requiredValidator,
  yamlValidator,
  customValidator((yaml, formFields) => {
    try {
      const body = moizedYamlLoad(yaml)
      return body?.kind === 'PersistentVolume'
    } catch (err) {
      return true
    }
  }, 'Yaml kind must be PersistentVolume'),
]

export default function AddPersistentVolumeModal() {
  const { history, match } = useReactRouter()
  const { cluster } = match.params
  const classes = useStyles()
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterId = k8sPluginGlobalPerfs?.clusterId || ''
  const defaultParams = {
    clusterId: '',
    yaml: '',
  }

  const { params, updateParams, setParams } = useParams<{
    clusterId: string
    yaml: string
  }>(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(createPersistentVolume)

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.kubernetes.resources.persistentVolumes.list.path({ cluster }))
  }

  const submitForm = useCallback(async () => {
    const { success } = await update({
      clusterId: clusterId,
      yaml: params?.yaml,
    })
    if (success) handleClose()
  }, [params, handleClose])

  return (
    <ModalForm
      route={routes.kubernetes.resources.persistentVolumes.add}
      title={`Add Persistent Volume`}
      onSubmit={submitForm}
      onClose={handleClose}
      submitting={updating}
      error={error}
      submitTitle={`Add Persistent Volume`}
      maxWidth={528}
    >
      <div className={classes.container}>
        <div className={classes.fields}>
          <Text variant="caption1">Upload Persistent Volume YAML</Text>
          <TextFileDrop
            onChange={(value) => updateParams({ yaml: value })}
            fileTypes={['.yaml', '.yml']}
          />
          <CodeMirror
            label="YAML"
            id="yaml"
            validations={codeMirrorValidations}
            onChange={(value) => updateParams({ yaml: value })}
            value={params?.yaml}
            options={codeMirrorOptions}
          />
        </div>
      </div>
    </ModalForm>
  )
}
