import { createSelector } from '@reduxjs/toolkit'
import { allKey } from 'app/constants'
import { AppSelector } from 'app/store'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector, selectParamsFromProps } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { findClusterName } from 'k8s/util/helpers'
import { pipe, propEq } from 'ramda'
import { arrayIfEmpty, filterIf } from 'utils/fp'
import { durationBetweenDates } from 'utils/misc'
import { IPersistentVolumeSelector } from './model'

export const persistentVolumeSelector: AppSelector<IPersistentVolumeSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.PersistentVolumes>(DataKeys.PersistentVolumes),
  getDataSelector<DataKeys.KaapiClusters>(DataKeys.KaapiClusters),
  (rawPvs, allClusters): IPersistentVolumeSelector[] => {
    return rawPvs.map((rawPv) => {
      const creationTimestamp = rawPv?.metadata?.creationTimestamp
      return {
        ...rawPv,
        // labels: rawPv?.metadata?.annotations,
        accessModes: rawPv?.spec?.accessModes,
        storageClass: rawPv?.spec?.storageClassName,
        capacity: rawPv?.spec?.capacity,
        reclaimPolicy: rawPv?.spec?.persistentVolumeReclaimPolicy,
        clusterName: findClusterName(allClusters, rawPv.clusterId),
        type: rawPv?.parameters?.type,
        creationTimestamp,
        age: durationBetweenDates({ labels: ['d'] })(creationTimestamp),
        // our generic response converter exposes the namespace from metadata,
        // this is our first example where the namespace comes from somewhere else
        // if theres a consistent pattern we can make the helper use accesors for data paths
        namespace: rawPv?.spec?.claimRef?.namespace,
      }
    })
    // .filter(propSatisfies(complement(isNil), 'clusterName'))
  },
)

export const makePersistentVolumeSelector = (
  defaultParams = {} as SortConfig & { clusterId?: string; namespace?: string },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(persistentVolumeSelector, selectParams, (pvcs, params) => {
    const { clusterId, namespace, orderBy, orderDirection } = params
    return pipe<
      IPersistentVolumeSelector[],
      IPersistentVolumeSelector[],
      IPersistentVolumeSelector[],
      IPersistentVolumeSelector[],
      IPersistentVolumeSelector[]
    >(
      filterIf(clusterId && clusterId !== allKey, propEq('clusterId', clusterId)),
      filterIf(namespace && namespace !== allKey, propEq('namespace', namespace)),
      createSorter({ orderBy, orderDirection }),
      arrayIfEmpty,
    )(pvcs)
  })
}

export const pvByStorageClassSelector: AppSelector<Map<
  string,
  IPersistentVolumeSelector[]
>> = createSharedSelector(persistentVolumeSelector, (pvs) => {
  const pvByStorageClass = new Map<string, IPersistentVolumeSelector[]>()
  for (const pv of pvs) {
    const storageClassName = pv?.spec?.storageClassName
    if (storageClassName) {
      const current = [...(pvByStorageClass.get(storageClassName) || []), pv]
      pvByStorageClass.set(storageClassName, current)
    }
  }
  return pvByStorageClass
})
