import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import StatusCard from 'core/components/statusCard/StatusCard'
import Text from 'core/elements/Text'
import useListAction from 'core/hooks/useListAction'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { routes } from 'core/utils/routes'
import { listKaapiClusters } from '../../components/kaapi/clusters/actions'
import { kaapiClustersOverviewSelector } from '../../components/kaapi/clusters/selectors'
import {
  DEFAULT_NODE_GROUP_STATUS,
  generateNodeGroupsStatus,
  generateWorkerNodesStatus,
  getClusterStatus,
  getBadgeVariant,
  isKaapiClusterHealthy,
  NodeGroupStatus,
} from './helpers'
import StatusIconText, { BadgeVariant } from './StatusIconText'

const StatusCardsHeader = () => {
  const classes = useStyles({})
  const { history, match } = useReactRouter()
  const { cluster } = match.params

  const routeToNodeLists = () =>
    history.push(routes.kubernetes.manage.capacityAndHealth.path({ cluster }))

  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences('k8sPluginGlobalParams')

  const [controlPlaneStatus, setControlPlaneStatus] = useState<NodeGroupStatus>(
    DEFAULT_NODE_GROUP_STATUS.success,
  )
  // Load Machine Deployments
  const { loading: loadingKaapiClusters, reload: reloadKaapiClusters } = useListAction(
    listKaapiClusters,
  )
  const clusters: any[] = useSelector(kaapiClustersOverviewSelector)
  const clusterData = clusters?.find(
    (cluster) => cluster.metadata.name === k8sPluginGlobalPerfs?.cluster,
  )
  const machineDeployments = clusterData?.machineDeployments || []
  const machines = clusterData?.machines || []

  // Map the machine deployments status to get the node groups status
  const nodeGroupsStatus = useMemo(() => {
    return generateNodeGroupsStatus(machineDeployments)
  }, [machineDeployments])

  const workerNodesStatus = useMemo(() => {
    return generateWorkerNodesStatus(machines)
  }, [machines])

  // Control Plane Status
  const isControlPlaneReady = clusterData?.hostedControlPlane?.status?.ready
  const isClusterHealthy = isKaapiClusterHealthy(clusterData)
  const isCapacityAndHealthDashboard =
    history.location.pathname === routes.kubernetes.manage.capacityAndHealth.path({ cluster })

  // Get Cluster Status
  const clusterStatus = useMemo(() => getClusterStatus(clusterData), [cluster])

  useEffect(() => {
    if (isControlPlaneReady) {
      setControlPlaneStatus(DEFAULT_NODE_GROUP_STATUS.success)
    } else {
      setControlPlaneStatus(DEFAULT_NODE_GROUP_STATUS.error)
    }
  }, [isControlPlaneReady])

  return (
    <div
      className={clsx(
        classes.cardsRow,
        !isCapacityAndHealthDashboard && 'responsive-dasboard-card-container',
      )}
    >
      <StatusCard show={isCapacityAndHealthDashboard} title="Overall Health">
        <StatusIconText
          icon={DEFAULT_NODE_GROUP_STATUS?.[getBadgeVariant(clusterStatus)]?.icon}
          variant={getBadgeVariant(clusterStatus)}
          label={clusterStatus}
          margin={false}
        />
      </StatusCard>

      <StatusCard title="Control Plane">
        <StatusIconText
          label={
            isControlPlaneReady
              ? 'Ready'
              : clusterData?.hostedControlPlane?.status?.phase || 'Not Ready'
          }
          icon={controlPlaneStatus?.icon}
          variant={controlPlaneStatus?.variant as BadgeVariant}
          margin={false}
        />
      </StatusCard>

      <StatusCard
        title="Node Groups"
        count={machineDeployments.length}
        onClick={routeToNodeLists}
        hideBtn={isCapacityAndHealthDashboard}
      >
        {nodeGroupsStatus.length ? (
          nodeGroupsStatus?.map((item, index) => (
            <StatusIconText
              key={index}
              label={item?.label || ''}
              icon={item?.icon}
              variant={item?.variant}
              rightBorder={index !== nodeGroupsStatus?.length - 1}
              margin={false}
            />
          ))
        ) : (
          <Text variant="body2" style={{ paddingTop: '8px', paddingBottom: '12px' }}>
            No Node Groups
          </Text>
        )}
      </StatusCard>

      <StatusCard
        title={'Worker Nodes'}
        count={machines.length}
        onClick={routeToNodeLists}
        hideBtn={isCapacityAndHealthDashboard}
      >
        {workerNodesStatus.length ? (
          workerNodesStatus?.map((item, index) => (
            <StatusIconText
              key={index}
              label={item?.label || ''}
              icon={item?.icon}
              variant={item?.variant}
              rightBorder={index !== workerNodesStatus?.length - 1}
              margin={false}
            />
          ))
        ) : (
          <Text variant="body2" style={{ paddingTop: '8px', paddingBottom: '12px' }}>
            No Worker Nodes
          </Text>
        )}
      </StatusCard>
    </div>
  )
}

export default StatusCardsHeader

const useStyles = makeStyles((theme: Theme) => ({
  cardsRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    //TODO: fix this based on card count
    // '& > :nth-child(3n+1)': {
    //   // First card in each row
    //   borderRadius: '4px 0px 0px 4px',
    // },
    // '& > :nth-child(3n)': {
    //   // Last card in each row
    //   borderRadius: '0px 4px 4px 0px',
    // },
  },
  card: {
    backgroundColor: theme.components.card.background,
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: '16px 24px',
    //  '&:nth-child(3n+1)': {
    //    // First card in each row
    //    borderRadius: '4px 0px 0px 4px',
    //  },
    //  '&:nth-child(3n)': {
    //    // Last card in each row
    //    borderRadius: '0px 4px 4px 0px',
    //  },
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  cardHeaderBtn: {
    cursor: 'pointer',
  },
  cardBody: {
    display: 'flex',
  },
  icon: {
    paddingLeft: '4px',
    fontSize: '12px',
  },
}))
