export const convertCpuUsageValueToCores = (usageValue) => {
  if (!usageValue) return 0

  if (usageValue.includes('n')) {
    // Convert usage value from nanocores to cores
    return parseInt(usageValue) / 1_000_000_000 // 1 core = 1,000,000,000 nanocores
  } else if (usageValue.includes('u')) {
    // Convert usage value from microcores to cores
    return parseInt(usageValue) / 1_000_000 // 1 core = 1,000,000 microcores
  } else if (usageValue.includes('m')) {
    // Convert usage value from millicores to cores
    return parseInt(usageValue) / 1_000 // 1 core = 1,000 millicores
  }

  // Return as-is if already in cores
  return parseInt(usageValue)
}

export const convertMemoryUsageValueToGb = (usageValue) => {
  if (!usageValue) return 0
  if (usageValue.includes('Ki')) {
    // Convert usage value from Ki to GB
    return parseInt(usageValue) / 1_048_576 // 1 GB = 1024 * 1024 Ki
  } else if (usageValue.includes('Mi')) {
    // Convert usage value from MiB to GB
    return parseInt(usageValue) / 1_024 // 1 GB = 1024 MiB
  }
  return parseInt(usageValue) // Return as-is if already in GB
}
