import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import { kaapiClustersSelector } from 'k8s/components/kaapi/clusters/selectors'
import DataKeys from 'k8s/DataKeys'
import { findClusterName } from 'k8s/util/helpers'

const getSubjectsOfKind = (subjects, kind) =>
  subjects.filter((subject) => subject.kind === kind).map((user) => user.name)

export const roleBindingsSelector = createSharedSelector(
  getDataSelector<DataKeys.RoleBindings>(DataKeys.RoleBindings),
  kaapiClustersSelector,
  (items, clusters) => {
    return items.map((item) => ({
      ...item,
      id: item?.metadata?.uid,
      name: item?.metadata?.name,
      namespace: item?.metadata?.namespace,
      clusterName: findClusterName(clusters, item.clusterId),
      created: item?.metadata?.creationTimestamp,
      users: item.subjects ? getSubjectsOfKind(item.subjects, 'User') : [],
      groups: item.subjects ? getSubjectsOfKind(item.subjects, 'Group') : [],
    }))
  },
)
