import React from 'react'
import PicklistField from 'core/components/validatedForm/DropdownField'
import UserMultiSelect from 'k8s/components/common/UserMultiSelect'
import GroupMultiSelect from 'k8s/components/common/GroupMultiSelect'
import RolePicklist from '../RolePicklist'
import TextField from 'core/components/validatedForm/TextField'
import useParams from 'core/hooks/useParams'
import useReactRouter from 'use-react-router'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import DocumentMeta from 'core/components/DocumentMeta'
import { routes } from 'core/utils/routes'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createClusterRoleBinding } from './actions'
import ModalForm from 'core/elements/modal/ModalForm'
import usePluginRouter from 'core/hooks/usePluginRouter'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { K8sPluginGlobalPrefs } from 'k8s/model'

const defaultParams = {
  clusterRole: null,
  users: [],
  groups: [],
}
export default function AddClusterRoleBindingForm() {
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)
  const { history } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterId = k8sPluginGlobalPerfs?.clusterId || ''
  const clusterName = k8sPluginGlobalPerfs?.cluster || ''

  const { update, updating, reset, error: errorCreatingClusterRoleBinding } = useUpdateAction(
    createClusterRoleBinding,
  )

  const submitForm = async (params) => {
    const { success } = await update(params)
    if (success) handleClose()
  }
  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(
      routes.kubernetes.accessControl.clusterRoleBindings.path({
        cluster: clusterName,
        plugin: currentPluginId,
      }),
    )
  }

  return (
    <>
      <DocumentMeta title="Add Cluster Role Binding" bodyClasses={['form-view']} />
      <ModalForm
        route={routes.kubernetes.accessControl.addClusterRoleBindings}
        title="Add Cluster Role Binding"
        onSubmit={submitForm}
        onClose={handleClose}
        submitting={updating}
        error={errorCreatingClusterRoleBinding}
        submitTitle="Add Cluster Role Binding"
      >
        <>
          <FormFieldSection title="Basic Details" step={1}>
            <TextField id="name" label="Name" required />
            <PicklistField
              DropdownComponent={RolePicklist}
              id="clusterRole"
              label="Cluster Role"
              clusterId={clusterId}
              onChange={getParamsUpdater('clusterRole')}
              value={params.clusterRole}
              compact={false}
              required
            />
          </FormFieldSection>
          <FormFieldSection title="Assign Users to this Cluster Binding" step={2}>
            <UserMultiSelect
              id="users"
              tooltip="Select users to assign this role"
              onChange={getParamsUpdater('users')}
              value={params.users}
            />
          </FormFieldSection>
          <FormFieldSection title="Assign Groups to this Cluster Binding" step={3}>
            <GroupMultiSelect
              id="groups"
              tooltip="Select groups to assign this role"
              onChange={getParamsUpdater('groups')}
              value={params.groups}
            />
          </FormFieldSection>
        </>
      </ModalForm>
    </>
  )
}
