import { listTablePrefs } from 'app/constants'
import DocumentMeta from 'core/components/DocumentMeta'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import ListContainer from 'core/containers/ListContainer'
import { createGridStatusCell, StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import React, { useMemo, useState } from 'react'
import { isNilOrEmpty } from 'utils/fp'
import DeleteNodeGroupsDialog from './DeleteNodeGroupsDialog'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import { IMachineDeploymentSelector } from '../kaapi/machine-deployment/model'
import useListAction from 'core/hooks/useListAction'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { useSelector } from 'react-redux'
import AddNodeGroupsModal from 'k8s/components/capacity-and-health/AddNodeGroupsModal'
import { kaapiMachinesForClusterSelector } from '../kaapi/machines/selectors'
import { kaapiMachineDeploymentsForClusterSelector } from '../kaapi/machine-deployment/selectors'
import { listKaapiMachineDeployments } from '../kaapi/machine-deployment/actions'
import { listKaapiMachines } from '../kaapi/machines/actions'
import EditClusterNodeGroupsModal from '../clusters-dashboard/EditClusterNodeGroupsModal'
import { isReadonlyUser } from 'core/utils/helpers'
import { kaapiClustersOverviewSelector } from '../kaapi/clusters/selectors'
import { listKaapiClusters } from '../kaapi/clusters/actions'
import Button from 'core/elements/button/Button'

export type NodeGroupSelector = IMachineDeploymentSelector

const usePrefParams = createUsePrefParamsHook('Machine Deployments', listTablePrefs)

const Phase = {
  Provisioning: 'Provisioning',
  Provisioned: 'Provisioned',
  Running: 'Running',
  Failed: 'Failed',
  ScalingUp: 'ScalingUp',
  ScalingDown: 'ScalingDown',
  Deleting: 'Deleting',
  Unknown: 'Unknown',
}

const getPhaseStatus = (phase): StatusCellModel => {
  switch (phase) {
    case Phase.Running:
    case Phase.Provisioned:
      return { variant: 'success', label: phase }
    case Phase.ScalingUp:
    case Phase.ScalingDown:
    case Phase.Provisioning:
      return { variant: 'warning', label: phase }
    case Phase.Failed:
    case Phase.Deleting:
      return { variant: 'error', label: phase }
    default:
      return { variant: 'unknown', label: Phase.Unknown }
  }
}
const cellFormatFn = (value) => (isNilOrEmpty(value) ? '-' : value)
const columns: GridViewColumn<any>[] = [
  {
    key: 'metadata.name',
    label: 'Name',
    // CellComponent: NodeGroupNameCell,
    // memoizeCell: false,
  },
  {
    key: 'status.phase',
    label: 'Status',
    CellComponent: createGridStatusCell({
      dataFn: (phase): StatusCellModel => {
        return getPhaseStatus(phase)
      },
    }),
  },
  {
    key: 'openStackMachineTemplate.spec.template.spec.flavor',
    label: 'Flavor',
  },
  {
    key: 'spec.autoscaling',
    label: 'Auto Scaling',
  },
  {
    key: 'spec.template.spec.version',
    label: 'K8s Version',
    formatFn: cellFormatFn,
  },
  {
    key: 'metadata.creationTimestamp',
    label: 'Created',
    CellComponent: DateAndTime,
  },
  {
    key: 'spec.replicas',
    label: 'Desired Replicas',
    formatFn: cellFormatFn,
  },
  {
    key: 'status.readyReplicas',
    label: 'Ready Replicas',
    formatFn: cellFormatFn,
  },
]

export default function ListNodeGroups() {
  const { params, getParamsUpdater } = usePrefParams({})
  const [open, setOpen] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [selectedNodeGroup, setSelectedNodeGroup] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences('k8sPluginGlobalParams')

  // Load the list of clusters
  const { loading: loadingKaapiClusters, reload: reloadKaapiClusters } = useListAction(
    listKaapiClusters,
  )
  const clusters = useSelector(kaapiClustersOverviewSelector)
  const cluster = clusters?.find(
    (cluster) => cluster.metadata.name === k8sPluginGlobalPerfs?.cluster,
  )

  // Fetch Worker Nodes
  const { loading: loadingKaapiMachines } = useListAction(listKaapiMachines)
  const machinesSelector = kaapiMachinesForClusterSelector({
    clusterName: k8sPluginGlobalPerfs.cluster,
  })
  const machines = useSelector(machinesSelector) || []

  // Fetch Node Groups
  const { loading: loadingMachineDeployments, reload: reloadMachineDeployments } = useListAction(
    listKaapiMachineDeployments,
  )
  const machineDeploymentSelector = kaapiMachineDeploymentsForClusterSelector({
    clusterName: k8sPluginGlobalPerfs.cluster,
  })
  const machineDeployments = useSelector(machineDeploymentSelector)

  // Check if this is the last running node in the cluster
  const isLastRunningNode: boolean = useMemo(
    () => machines?.length === 1 && machines[0]?.status.phase === 'Running',
    [machines],
  )

  const k8sVersion = useMemo(() => {
    if (!cluster?.hostedControlPlane?.spec?.version) return ''

    return cluster?.hostedControlPlane?.spec?.version
  }, [cluster])

  const batchActions = [
    {
      icon: 'edit',
      label: 'Edit',
      handleAction: (nodegroup) => {
        if (nodegroup?.length === 0) return
        setSelectedNodeGroup(nodegroup[0])
        setShowEditModal(true)
      },
    },
    {
      label: 'Delete',
      icon: 'trash-alt',
      BatchActionButton: getGridDialogButton(DeleteNodeGroupsDialog, {
        isLastRunningNode,
        reloadMachineDeployments,
      }),
    },
  ]

  const rowMenuItems = [
    {
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (nodegroup) => {
        setSelectedNodeGroup(nodegroup)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
    {
      label: 'Edit',
      icon: 'edit',
      handleClick: (nodegroup) => {
        setSelectedNodeGroup(nodegroup)
        setShowEditModal(true)
      },
      refreshAfterSuccess: true,
      hideIfDisabled: true,
    },
  ]

  return (
    <>
      <DocumentMeta title="Capi Node Groups Overview" />
      {open && <AddNodeGroupsModal onClose={() => setOpen(false)} k8sVersion={k8sVersion} />}
      {showEditModal && (
        <EditClusterNodeGroupsModal
          onClose={() => setShowEditModal(false)}
          rows={[selectedNodeGroup]}
        />
      )}
      {showDeleteDialog && (
        <DeleteNodeGroupsDialog
          rows={[selectedNodeGroup]}
          onClose={() => setShowDeleteDialog(false)}
          isLastRunningNode={isLastRunningNode}
          reloadMachineDeployments={reloadMachineDeployments}
        />
      )}
      <ListContainer<any, any>
        searchTargets={['metadata.name', 'status.phase']}
        uniqueIdentifier="uid"
        loading={loadingMachineDeployments}
        loadingMessage="Loading..."
        onRefresh={reloadMachineDeployments}
        data={machineDeployments}
        hideRefreshButtonOnTop={false}
        columns={columns}
        getParamsUpdater={getParamsUpdater}
        label="Node Groups"
        showItemsCountInLabel
        showBreadcrumbs={false}
        batchActions={batchActions}
        extraToolbarContent={
          // For BYOH clusters disable add node group
          !isReadonlyUser() && !cluster?.isBYOHCluster ? (
            <Button icon="add" onClick={() => setOpen(true)}>
              Add Node Group
            </Button>
          ) : null
        }
        rowMenuItems={rowMenuItems}
        showRowMenuForSingleRowActions
        isReadOnly={cluster?.isBYOHCluster}
      />
    </>
  )
}
