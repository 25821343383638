import ActionsSet from 'core/actions/ActionsSet'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import { pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import ListAction from 'core/actions/ListAction'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'

const { capi } = ApiClient.getInstance()

export const namespaceActions = ActionsSet.make<DataKeys.Namespaces>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey[DataKeys.Namespaces],
  cacheKey: DataKeys.Namespaces,
})

export const listNamespaces = namespaceActions.add(
  new ListAction<DataKeys.Namespaces, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get namespaces', params)
    return capi.getAllNamespaces(params.clusterId)
  }),
)

export const createNamespace = namespaceActions.add(
  new CreateAction<DataKeys.Namespaces, { clusterId: string; name: string }>(
    async ({ clusterId, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new namespace', { clusterId, name })
      const body = { metadata: { name } }
      const created = await capi.createNamespace(clusterId, body)
      trackEvent('WZ Create New Namespace', {
        id: pathStr('metadata.uid', created),
        clusterId,
        name,
        wizard_step: 'Create Namespace',
        wizard_state: 'Complete',
        wizard_progress: '1 of 1',
        wizard_name: 'Create New Namespace',
      })
      return created
    },
  ),
)

export const deleteNamespace = namespaceActions.add(
  new DeleteAction<DataKeys.Namespaces, { clusterId: string; name: string; id: string }>(
    async ({ clusterId, name, id }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete namespace', { clusterId, name, id })
      await capi.deleteNamespace(clusterId, name)
      trackEvent('WZ Delete Namespace', {
        id,
        clusterId,
        name,
        wizard_step: 'Delete Namespace',
        wizard_state: 'Complete',
        wizard_progress: '1 of 1',
        wizard_name: 'Deleted Namespace',
      })
    },
  ),
)

export default namespaceActions
