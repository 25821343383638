import DocumentMeta from 'core/components/DocumentMeta'
import PicklistField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import Column from 'core/containers/Column'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { namespaceValidator } from 'core/utils/fieldValidators'
import { routes } from 'core/utils/routes'
import React from 'react'
import useReactRouter from 'use-react-router'
import { createNamespace } from './actions'

const defaultParams = {
  masterNodeClusters: true,
  clusterId: null,
}

export default function AddNamespaceForm() {
  const { history, match } = useReactRouter()
  const { params, getParamsUpdater, setParams } = useParams(defaultParams)
  const { cluster } = match.params
  const { update, updating, error, reset } = useUpdateAction(createNamespace)

  const submitForm = async (params) => {
    const { success } = await update(params)
    if (success) handleClose()
  }
  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(routes.kubernetes.resources.namespaces.list.path({ cluster }))
  }

  return (
    <>
      <DocumentMeta title="Add Namespace" bodyClasses={['form-view']} />
      <ModalForm
        route={routes.kubernetes.resources.namespaces.add}
        title="Add Namespace"
        submitTitle="Add Namespace"
        onSubmit={submitForm}
        submitting={updating}
        error={error}
        onClose={handleClose}
      >
        <Column>
          <TextField id="name" label="Name" required validations={[namespaceValidator]} />
        </Column>
      </ModalForm>
    </>
  )
}
