import React, { useCallback, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import moize from 'moize'
import jsYaml from 'js-yaml'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ModalForm from 'core/elements/modal/ModalForm'
import useParams from 'core/hooks/useParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { createCustomResource, listCustomResourceDefinitions } from '../actions'
import TextFileDrop from 'core/elements/grid/TextFileDrop'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import { codeMirrorOptions } from 'app/constants'
import { customValidator, requiredValidator, yamlValidator } from 'core/utils/fieldValidators'
import { ICustomResourceDefinitionSelector } from '../model'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import { customResourceDefinitionsSelector } from '../selectors'
import { routes } from 'core/utils/routes'

const moizedYamlLoad = moize(jsYaml.load, {
  maxSize: 10,
})

const getCodeMirrorValidations = (kind) => [
  requiredValidator,
  yamlValidator,
  customValidator((yaml, formFields) => {
    try {
      const body = moizedYamlLoad(yaml)
      return body?.kind === kind
    } catch (err) {
      return true
    }
  }, `YAML kind must be ${kind}`),
]

function AddCustomResourceModal(props: any) {
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const { history, match } = useReactRouter()
  const { id, cluster } = match.params
  const clusterId = k8sPluginGlobalPerfs?.clusterId
  const classes = useStyles()
  const defaultParams = {
    clusterId: null,
    yaml: '',
  }
  useListAction(listCustomResourceDefinitions, {
    params: { clusterId },
  })
  const customResourceDefinitions: ICustomResourceDefinitionSelector[] = useSelector(
    customResourceDefinitionsSelector,
  )
  const customResourceDefinition = useMemo(
    () =>
      customResourceDefinitions.find(
        (customResourceDefinition) => customResourceDefinition?.id === id,
      ),
    [id, customResourceDefinitions],
  )
  const {
    group = '',
    latestVersion: version = '',
    spec: { names: { plural: pluralName } } = { names: { plural: '' } },
  } = customResourceDefinition || {}

  const { params, updateParams, setParams } = useParams<{
    clusterId: string
    yaml: string
  }>(defaultParams)

  const { update, updating, error, reset } = useUpdateAction(createCustomResource)

  const handleClose = () => {
    setParams(defaultParams)
    reset()
    history.push(
      routes.kubernetes.resources.customResourceDefinitions.customResources.path({
        cluster,
        id,
      }),
    )
  }
  const submitForm = useCallback(async () => {
    const body = jsYaml.load(params?.yaml)

    const { success } = await update({
      clusterId,
      group: group,
      version: version,
      pluralName: pluralName,
      namespace: body?.metadata?.namespace,
      body,
    })
    if (success) handleClose()
  }, [params, handleClose, update, customResourceDefinition, clusterId])

  return (
    <ModalForm
      route={routes.kubernetes.resources.customResourceDefinitions.customResourceAdd}
      title="Add Custom Resource"
      onSubmit={submitForm}
      submitting={updating}
      error={error}
      onClose={handleClose}
      maxWidth={528}
    >
      <div className={classes.container}>
        <TextFileDrop
          onChange={(value) => updateParams({ yaml: value })}
          fileTypes={['.yaml', '.yml']}
        />
        <CodeMirror
          className={classes.limitWidth}
          label="YAML"
          id="yaml"
          validations={getCodeMirrorValidations(customResourceDefinition?.spec?.names?.kind)}
          onChange={(value) => updateParams({ yaml: value })}
          value={params?.yaml}
          options={codeMirrorOptions}
        />
      </div>
    </ModalForm>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: '16px 40px 40px 32px',
    display: 'grid',
    gap: 32,
  },
  fields: {
    display: 'grid',
    gap: 24,
  },
  limitWidth: {
    // Max width possible for this w/o causing scrollbar
    // Assumes dialog with max width of 528px
    maxWidth: 407,
  },
}))

export default AddCustomResourceModal
