import ApiClient from 'api-client/ApiClient'
import { groupByRegion } from 'api-client/Keystone'
import { getClusterEndpoint } from '../clusters/action-helpers'

interface HostedControlPlaneParams {
  name: string
  namespace: string
  version: string
  apiServerFlags?: string[]
  schedulerFlags?: string[]
  controllerManagerFlags?: string[]
  clusterName: string
}

export const getRegionURLs = () => {
  const { serviceCatalog } = ApiClient.getInstance()
  const servicesByRegion = groupByRegion(serviceCatalog)
  const regionKeys = Object.keys(servicesByRegion)

  return regionKeys.reduce((acc, region) => {
    const resmgrEndpoint = servicesByRegion?.[region]?.resmgr?.public?.url
    const baseUrl = resmgrEndpoint?.split('/resmgr')?.[0]
    return baseUrl ? (acc ? `${acc},${baseUrl}` : baseUrl) : acc
  }, '')
}

export const createHostedControlPlaneBody = ({
  name,
  namespace,
  version,
  apiServerFlags = [],
  schedulerFlags = [],
  controllerManagerFlags = [],
  clusterName,
}: HostedControlPlaneParams) => {
  const regionURLs = getRegionURLs()
  const clusterEndpoint = getClusterEndpoint({
    clusterName,
    namespace,
  })

  const clusterCPEndpoint = getClusterEndpoint({
    clusterName: name,
    namespace,
  })

  return {
    apiVersion: 'controlplane.platform9.io/v1alpha1',
    kind: 'HostedControlPlane',
    metadata: {
      name,
      namespace,
    },
    spec: {
      apiServer: {
        extraArgs: [
          ...apiServerFlags,
          '--cloud-provider=external',
          '--advertise-address=10.96.0.40',
          `--cors-allowed-origins=${regionURLs}`,
        ],
      },
      scheduler: {
        extraArgs: schedulerFlags,
      },
      controllerManager: {
        extraArgs: [...controllerManagerFlags, '--cloud-provider=external'],
      },
      addons: {
        coreDNS: {},
        konnectivity: {
          server: {
            port: 8132,
          },
          agent: {
            extraArgs: [
              `--proxy-server-host=konnectivity-${clusterEndpoint}`,
              '--proxy-server-port=443',
            ],
          },
        },
      },
      extraCertSANs: [
        `konnectivity-${clusterEndpoint}`,
        `${clusterEndpoint}`,
        `${clusterCPEndpoint}`,
      ],
      kubelet: {
        preferredAddressTypes: ['InternalIP', 'ExternalIP', 'Hostname'],
        cgroupfs: 'systemd',
      },
      version,
      hcpClass: 'default',
      hostname: `${clusterEndpoint}`,
    },
  }
}
