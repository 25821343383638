export enum MachineDeploymentPhase {
  Pending = 'Pending',
  Provisioning = 'Provisioning',
  Running = 'Running',
  ScalingUp = 'ScalingUp',
  ScalingDown = 'ScalingDown',
  Updating = 'Updating',
  Failed = 'Failed',
  Deleting = 'Deleting',
  Unknown = 'Unknown',
}

export enum ClusterPhases {
  Pending = 'Pending',
  Provisioning = 'Provisioning',
  Provisioned = 'Provisioned',
  Deleting = 'Deleting',
  Failed = 'Failed',
}

interface Status {
  count: number
  tooltipText: string
  variant: IClusterStatus
}

export interface AllResourceStatuses {
  [key: string]: Status
}

export type IClusterStatus =
  | 'ok'
  | 'pause'
  | 'fail'
  | 'unknown'
  | 'error'
  | 'loading'
  | 'upgrade'
  | 'pending'
  | 'degraded'
