import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import DeleteAction from 'core/actions/DeleteAction'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'

const { capi } = ApiClient.getInstance()

export const kaapiNodesActions = ActionsSet.make<DataKeys.KaapiNodes>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.KaapiNodes],
  cacheKey: DataKeys.KaapiNodes,
})

export const listKaapiNodes = kaapiNodesActions.add(
  new ListAction<DataKeys.KaapiNodes>(async ({ endpoint }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Kaapi Nodes ')
    return capi.getAllWorkerNodes()
  }).addDependency(DataKeys.KaapiNodes),
)

export const kaapiMetricsNodesActions = ActionsSet.make<DataKeys.KaapiMetricsNodes>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.KaapiMetricsNodes],
  cacheKey: DataKeys.KaapiMetricsNodes,
})
export const listKaapiMetricsNodes = kaapiMetricsNodesActions.add(
  new ListAction<DataKeys.KaapiMetricsNodes>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get Kaapi Metrics Nodes ')
    return capi.getAllMetricsWorkerNodes()
  }).addDependency(DataKeys.KaapiMetricsNodes),
)
