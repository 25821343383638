import ApiClient from 'api-client/ApiClient'
import { RoleRef } from 'api-client/capi.model'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import { trackEvent } from 'utils/tracking'

const { capi } = ApiClient.getInstance()

const uniqueIdentifier = 'metadata.uid'

export const clusterRoleBindingActions = ActionsSet.make<DataKeys.ClusterRoleBindings>({
  uniqueIdentifier,
  entityName: entityNamesByKey[DataKeys.ClusterRoleBindings],
  cacheKey: DataKeys.ClusterRoleBindings,
})

export const listClusterRoleBindings = clusterRoleBindingActions.add(
  new ListAction<DataKeys.ClusterRoleBindings>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get cluster role bindings', params)
    return capi.getClusterRoleBindings(params.clusterId)
  }),
)

export const createClusterRoleBinding = clusterRoleBindingActions.add(
  new CreateAction<
    DataKeys.ClusterRoleBindings,
    { clusterId: string; name: string; users: string[]; groups: string[]; clusterRole: string }
  >(async (data) => {
    const { clusterId, name } = data
    Bugsnag.leaveBreadcrumb('Attempting to create cluster role binding', { clusterId, name })
    const users = data.users.map((user) => ({
      kind: 'User',
      name: user,
      apiGroup: 'rbac.authorization.k8s.io',
    }))
    const groups = data.groups.map((group) => ({
      kind: 'Group',
      name: group,
      apiGroup: 'rbac.authorization.k8s.io',
    }))

    const subjects = [...users, ...groups]

    const [roleType, ...rest] = data.clusterRole.split(':')
    const roleName = rest.join(':')
    const body = {
      kind: 'ClusterRoleBinding',
      metadata: {
        name: data.name,
      },
      subjects,
      roleRef: {
        kind: roleType,
        name: roleName,
        apiGroup: 'rbac.authorization.k8s.io',
      },
    }
    trackEvent('Create Cluster Role Binding', { clusterId, name })
    return capi.createClusterRoleBinding(data.clusterId, body)
  }),
)

export const updateClusterRoleBinding = clusterRoleBindingActions.add(
  new UpdateAction<
    DataKeys.ClusterRoleBindings,
    { clusterId: string; name: string; users: string[]; groups: string[]; roleRef: RoleRef }
  >(async (data) => {
    const { clusterId, name } = data
    Bugsnag.leaveBreadcrumb('Attempting to update cluster role binding', { clusterId, name })
    const users = data.users.map((user) => ({
      kind: 'User',
      name: user,
      apiGroup: 'rbac.authorization.k8s.io',
    }))

    const groups = data.groups.map((group) => ({
      kind: 'Group',
      name: group,
      apiGroup: 'rbac.authorization.k8s.io',
    }))

    const subjects = [...users, ...groups]

    const body = {
      kind: 'ClusterRoleBinding',
      metadata: {
        name: data.name,
      },
      subjects,
      roleRef: data.roleRef,
    }
    trackEvent('Update Cluster Role Binding', { clusterId, name })
    return capi.updateClusterRoleBinding(data.clusterId, data.name, body)
  }),
)

export const deleteClusterRoleBinding = clusterRoleBindingActions.add(
  new DeleteAction<DataKeys.ClusterRoleBindings>(async ({ clusterId, namespace, name, id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete cluster role binding', { id })
    await capi.deleteClusterRoleBinding(clusterId, name)
    trackEvent('Delete Cluster Role Binding', { id })
  }),
)
