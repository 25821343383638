import { makeStyles } from '@material-ui/styles'
import { useAppSelector } from 'app/store'
import DocumentMeta from 'core/components/DocumentMeta'
import PresetField from 'core/components/PresetField'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ModalForm from 'core/elements/modal/ModalForm'
import useListAction from 'core/hooks/useListAction'
import usePluginRouter from 'core/hooks/usePluginRouter'
import useUpdateAction from 'core/hooks/useUpdateAction'
import useScopedPreferences from 'core/session/useScopedPreferences'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import RbacChecklist from 'k8s/components/access-control/RbacChecklist'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import { propEq } from 'ramda'
import React, { useCallback, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { emptyObj } from 'utils/fp'
import { listRoles, updateRole } from './actions'
import { IRbacRoleSelector } from './model'
import { rolesSelector } from './selectors'

const useStyles = makeStyles<Theme>((theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

export default function UpdateRolePage() {
  const classes = useStyles({})
  const { match, history } = useReactRouter()
  const { currentPluginId } = usePluginRouter()
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )

  const roleId = match?.params?.roleId
  const clusterId = k8sPluginGlobalPerfs.clusterId || ''
  const { loading } = useListAction(listRoles, {
    params: { clusterId },
    requiredParams: ['clusterId'],
  })
  const roles = useAppSelector(rolesSelector)

  const role = useMemo(() => roles.find(propEq('id', roleId)) || (emptyObj as IRbacRoleSelector), [
    roles,
    roleId,
  ])
  const { update, updating, error: errorUpdatingRole, reset } = useUpdateAction(updateRole)

  const submitForm = useCallback(
    async (params) => {
      const { success } = await update({ ...role, ...params })
      if (success) handleClose()
    },
    [role],
  )
  const handleClose = () => {
    reset()
    history.push(
      routes.kubernetes.accessControl.roles.path({
        plugin: currentPluginId,
        cluster: k8sPluginGlobalPerfs?.cluster,
      }),
    )
  }

  return (
    <>
      <DocumentMeta title="Update Role" bodyClasses={['form-view']} />
      <ModalForm
        route={routes.kubernetes.accessControl.editRoles}
        title="Edit Role"
        onSubmit={submitForm}
        onClose={handleClose}
        loading={loading}
        submitting={updating}
        error={errorUpdatingRole}
        loadingMessage={loading ? 'Loading role...' : 'Submitting form...'}
        submitTitle="Update Role"
      >
        <>
          <FormFieldSection title="Basic Details" step={1}>
            <PresetField label="Name" value={role.name} />
            <PresetField label="Namespace" value={role.namespace} />
          </FormFieldSection>
          {role.clusterId && (
            <FormFieldSection title="API Access / Permissions" step={2}>
              <RbacChecklist id="rbac" clusterId={role.clusterId} initialRules={role.rules} />
            </FormFieldSection>
          )}
        </>
      </ModalForm>
    </>
  )
}
