import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { useAppSelector } from 'app/store'
import { ArrayElement } from 'core/actions/Action'
import InferActionParams from 'core/actions/InferActionParams'
import DocumentMeta from 'core/components/DocumentMeta'
import DateCell from 'core/components/listTable/cells/DateCell'
import ListContainer from 'core/containers/ListContainer'
import { GridViewColumn } from 'core/elements/grid/Grid'
import useGlobalParams from 'core/hooks/useGlobalParams'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import usePluginRouter from 'core/hooks/usePluginRouter'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { routes } from 'core/utils/routes'
import AddRoleBindingPage from 'k8s/components/access-control/role-bindings/AddRoleBindingPage'
import UpdateRoleBindingPage from 'k8s/components/access-control/role-bindings/UpdateRoleBindingPage'
import useNamespaceFilterDropdown from 'k8s/components/useNamespaceFilterDropdown'
import DataKeys from 'k8s/DataKeys'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import { pick } from 'ramda'
import React, { useEffect } from 'react'
import { deleteRoleBinding, listRoleBindings } from './actions'
import { roleBindingsSelector } from './selectors'

type ModelDataKey = DataKeys.RoleBindings
type SelectorModel = ArrayElement<ReturnType<typeof roleBindingsSelector>>
type ActionParams = InferActionParams<typeof listRoleBindings>

type Params = ActionParams & {
  namespace?: string
  healthyClusters: boolean
}

const requiredParams: Array<keyof ActionParams> = ['clusterId']

const defaultParams: Params = {
  clusterId: null,
  healthyClusters: true,
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'RoleBindings',
  listTablePrefs,
)

const searchTargets = ['name', 'clusterName', 'namespace']

const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'name', label: 'Name' },
  { key: 'namespace', label: 'Namespace' },
  { key: 'created', label: 'Created', render: (value) => <DateCell value={value} /> },
]

export default function RoleBindingsListPage() {
  const { allParams: params, updateGlobalParams, getParamsUpdater } = useGlobalParams(
    usePrefParams,
    defaultParams,
  )
  const { currentPluginId } = usePluginRouter()
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterId = k8sPluginGlobalPerfs?.clusterId || ''
  const clusterName = k8sPluginGlobalPerfs?.cluster || ''

  const { message, loading, reload } = useListAction(listRoleBindings, {
    params: { clusterId },
    requiredParams,
  })
  const { Component, selectedNs } = useNamespaceFilterDropdown()

  const data = useAppSelector(roleBindingsSelector)
  const filteredData = data?.filter((item) => selectedNs?.includes(item?.namespace)) || []

  useEffect(() => {
    if (clusterId) {
      reload(true)
    }
  }, [clusterId])

  return (
    <>
      <DocumentMeta title="Role Bindings" />
      <AddRoleBindingPage />
      <UpdateRoleBindingPage />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.RoleBindings}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        data={filteredData}
        getParamsUpdater={getParamsUpdater}
        addUrl={routes.kubernetes.accessControl.addRoleBindings.path({
          cluster: clusterName,
          plugin: currentPluginId,
        })}
        addText="Add Role Binding"
        editUrl={(item, id) =>
          routes.kubernetes.accessControl.editRoleBindings.path({
            cluster: clusterName,
            id,
            plugin: currentPluginId,
          })
        }
        deleteAction={deleteRoleBinding}
        extraToolbarContent={Component}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
