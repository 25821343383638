import config from 'app-config'
import YamlTemplateParser from 'utils/YamlTemplateParser'
import clusterUpgradeJobSchema from './cluster-upgrade-job.json'
import { pick } from 'ramda'
import { isDevelopment, isLocalhost } from 'core/utils/helpers'

/**
 * Remove the first part of URL and use subdomain
 * For Eg. When hostname is pcd-kaapi-demo.app.staging-pcd.platform9.com
 * Trim the 1st part of url and return app.staging-pcd.platform9.com
 */
export const getClusterEndpoint = ({ clusterName, namespace }) => {
  let hostname = window.location.host
  if (isDevelopment() || isLocalhost()) {
    hostname = config.apiHost
  }
  const subdomain = hostname
    .split('.')
    .slice(1)
    .join('.')

  return `${clusterName}.${namespace}.${subdomain}`
}

interface KaapiClusterParams {
  clusterName: string
  namespace: string
  hostedControlPlaneRefName: string
  infrastructureRefName: string
  version: string
}

export const createKaapiClusterBody = ({
  clusterName,
  namespace,
  hostedControlPlaneRefName,
  infrastructureRefName,
  version,
}: KaapiClusterParams) => {
  // Get cas-capi-version from the version
  const casCapiVersion = `v${version
    .split('.')
    .slice(0, 2)
    .join('.')}`

  return {
    apiVersion: 'cluster.x-k8s.io/v1beta1',
    kind: 'Cluster',
    metadata: {
      name: clusterName,
      namespace,
      labels: {
        'core-addons': 'enabled',
        'proxy-addons': 'enabled',
        'cas-capi-version': casCapiVersion,
      },
    },
    spec: {
      clusterNetwork: {
        apiServerPort: 443,
        pods: {
          cidrBlocks: ['10.244.0.0/16'],
        },
        services: {
          cidrBlocks: ['10.96.0.0/16'],
        },
      },
      controlPlaneEndpoint: {
        host: `${getClusterEndpoint({ clusterName, namespace })}`,
        port: 443,
      },
      controlPlaneRef: {
        apiVersion: 'controlplane.platform9.io/v1alpha1',
        kind: 'HostedControlPlane',
        name: hostedControlPlaneRefName,
      },
      infrastructureRef: {
        apiVersion: 'infrastructure.cluster.x-k8s.io/v1beta1',
        kind: 'OpenStackCluster',
        name: infrastructureRefName,
      },
    },
  }
}

const createUpgradeJobName = (clusterName, targetVersion, upgradeJobs) => {
  const attemptNum = upgradeJobs.reduce((accum, job) => {
    if (job.targetVersion === targetVersion) {
      accum += 1
    }
    return accum
  }, 0)
  return `${clusterName}-${targetVersion}-${attemptNum}`
}

const formatNodeGroups = (nodeGroups = [], upgradeImageName) => {
  return nodeGroups.map((nodeGroup) => {
    return {
      ...pick(['apiVersion', 'kind', 'name'], nodeGroup),
      upgradeImageName,
    }
  })
}

const formatAddons = (addons = [], eksAddons = []) => {
  const formattedAddons = addons.map((addon) => {
    return {
      name: addon.type,
      kind: 'ClusterAddon',
      apiVersion: 'sunpike.platform9.com/v1alpha2',
    }
  })
  const formattedEksAddons = eksAddons.map((addon) => {
    return {
      name: addon.name,
      kind: 'EKSClusterAddon',
      apiVersion: 'controlplane.cluster.x-k8s.io/v1beta1',
    }
  })
  return [...formattedAddons, ...formattedEksAddons]
}

export const createClusterUpgradeJobBody = (params) => {
  const { clusterName, nodeGroups, upgradeJobs, targetVersion, upgradeImageName } = params
  const jobName = createUpgradeJobName(clusterName, targetVersion, upgradeJobs)
  const templateParser = new YamlTemplateParser(clusterUpgradeJobSchema)
  templateParser.setValues({
    ...params,
    name: jobName,
    nodeGroups: formatNodeGroups(nodeGroups, upgradeImageName),
    // addons: formatAddons(addons, eksAddons),
  })
  return templateParser.getJson()
}
