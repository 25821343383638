import React, { useCallback, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import Modal from 'core/elements/modal'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { routes } from 'core/utils/routes'
import { deleteCustomResource, listCustomResourceDefinitions } from '../../actions'
import {
  ICustomResourceDefinitionDetailsPageTabs,
  ICustomResourceDefinitionSelector,
} from '../../model'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import { customResourceDefinitionsSelector } from '../../selectors'

const useStyles = makeStyles<Theme>((theme) => ({
  crdList: {
    maxHeight: 180,
    overflow: 'auto',
    border: `1px solid ${theme.components.table.border}`,
    borderRadius: 4,
    padding: '16px 32px',
    listStyleType: 'none',
  },
}))

function DeleteCustomResourceDialog({ rows, onClose }) {
  const { history, match } = useReactRouter()
  const { id, cluster } = match.params
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )
  const clusterId = k8sPluginGlobalPerfs?.clusterId
  const classes = useStyles()
  useListAction(listCustomResourceDefinitions, {
    params: { clusterId },
  })
  const customResourceDefinitions: ICustomResourceDefinitionSelector[] = useSelector(
    customResourceDefinitionsSelector,
  )
  const customResourceDefinition = useMemo(
    () =>
      customResourceDefinitions.find(
        (customResourceDefinition) => customResourceDefinition?.id === id,
      ),
    [id, customResourceDefinitions],
  )
  const {
    group = '',
    latestVersion: version = '',
    spec: { names: { plural: pluralName } } = { names: { plural: '' } },
  } = customResourceDefinition || {}

  const { update: deleteFn, updating: deletingCustomResource } = useUpdateAction(
    deleteCustomResource,
  )

  const handleDelete = useCallback(async () => {
    const promises = rows.map((customResourceDefinition) => {
      deleteFn({
        ...customResourceDefinition,
        group: group,
        version: version,
        pluralName: pluralName,
      })
    })

    await Promise.allSettled(promises)
    onClose(true)
    history.push(
      routes.kubernetes.resources.customResourceDefinitions.details.path({
        cluster,
        id,
        tab: ICustomResourceDefinitionDetailsPageTabs.CustomResources,
      }),
    )
  }, [rows])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={`Are you sure?`}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingCustomResource}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={deletingCustomResource}>
            Delete
          </Button>
        </>
      }
    >
      <Text variant="body2">
        {`Following ${rows.length > 1 ? rows.length + ' CRs' : 'CR'} will be deleted`}
      </Text>
      <ul className={classes.crdList}>
        {rows?.map((row) => (
          <li key={row.id}>
            <Text variant="caption1">{row.name}</Text>
          </li>
        ))}
      </ul>
    </Modal>
  )
}

export default DeleteCustomResourceDialog
