import { makeStyles } from '@material-ui/styles'
import {
  bottomLeft,
  bottomMiddle,
  middleRight,
  topMiddle,
  topRight,
} from 'pf9-ui-components/built/elements/menu/defaults'
import Text, { TextProps, TextVariant } from 'pf9-ui-components/built/elements/Text'
import Tooltip from 'pf9-ui-components/built/elements/tooltip'
import React, { useEffect, useRef, useState } from 'react'

interface EllipsisTextProps extends TextProps {
  variant?: TextVariant
  children: any
  component?: React.ElementType
  title?: string
  maxWidth?: number
  showTooltip?: boolean
  className?: string
  alignTooltip?: 'bottomLeft' | 'bottomMiddle' | 'middleRight' | 'topMiddle' | 'topRight'
  id?: string
}

const useStyles = makeStyles((theme: any) => ({
  textWithEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: (props: { maxWidth?: number }) => (props.maxWidth ? `${props.maxWidth}px` : '100%'),
    color: theme.components.card.text,
  },
  customTooltip: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltipText: {
    color: theme.palette.common.white,
  },
}))

const EllipsisText = ({
  children,
  variant = 'body1',
  component,
  title,
  maxWidth,
  showTooltip,
  className,
  alignTooltip: align = 'topMiddle',
  id,
}: EllipsisTextProps) => {
  const classes = useStyles({ maxWidth })
  const elementRef = useRef(null)
  const [isTextTruncated, setIsTextTruncated] = useState(false)

  useEffect(() => {
    if (elementRef?.current) {
      // Check if text is truncated
      setIsTextTruncated(elementRef?.current?.offsetWidth < elementRef?.current?.scrollWidth)
    }
  }, [title])

  const alignment = {
    bottomLeft,
    bottomMiddle,
    middleRight,
    topMiddle,
    topRight,
  }

  return (
    <>
      {showTooltip && isTextTruncated ? (
        <Tooltip
          className={classes.customTooltip}
          message={
            showTooltip && (
              <Text
                variant="body2"
                lineClamp={10}
                className={classes.tooltipText}
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                {title}
              </Text>
            )
          }
          align={alignment[align]?.align}
        >
          <Text
            id={id}
            variant={variant}
            component={component}
            ref={elementRef}
            className={`${classes.textWithEllipsis} ${className}`}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            {children}
          </Text>
        </Tooltip>
      ) : (
        <Text
          id={id}
          variant={variant}
          component={component}
          ref={elementRef}
          title={isTextTruncated ? title : undefined}
          className={`${classes.textWithEllipsis} ${className}`}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          placeholder={undefined}
        >
          {children}
        </Text>
      )}
    </>
  )
}

export default EllipsisText
