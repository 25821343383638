import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import { pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'

const { kubernetes } = ApiClient.getInstance()

export const kaapiMachineDeploymentActions = ActionsSet.make<DataKeys.KaapiMachineDeployments>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.KaapiMachineDeployments],
  cacheKey: DataKeys.KaapiMachineDeployments,
})

export const listKaapiMachineDeployments = kaapiMachineDeploymentActions.add(
  new ListAction<DataKeys.KaapiMachineDeployments>(async ({ namespace }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Kaapi MachineDeployment ', { namespace })
    return kubernetes.getMachineDeployments({ namespace })
  })
    .addDependency(DataKeys.KaapiMachines)
    .addDependency(DataKeys.KaapiOpenStackMachineTemplates),
)

export const deleteKaapiMachineDeployments = kaapiMachineDeploymentActions.add(
  new DeleteAction<DataKeys.KaapiMachineDeployments, { namespace: string; name: string }>(
    async ({ namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Kaapi MachineDeployments', { namespace, name })
      await kubernetes.deleteKaapiMachineDeployments({ namespace, name })
    },
  ),
)

export const createKaapiMachineDeployment = kaapiMachineDeploymentActions.add(
  new CreateAction<DataKeys.KaapiMachineDeployments, { namespace: string; body: any }>(
    async ({ namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create Kaapi MachineDeployment', { namespace, body })
      const created = await kubernetes.createMachineDeployment(namespace, body)
      trackEvent('Create New Kaapi MachineDeployment', {
        namespace,
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)
export const updateKaapiMachineDeployment = kaapiMachineDeploymentActions.add(
  new UpdateAction<
    DataKeys.KaapiMachineDeployments,
    { namespace: string; name: string; customBody: any }
  >(async ({ namespace, name, customBody }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Kaapi MachineDeployment', {
      namespace,
      customBody,
    })
    const updated = await kubernetes.patchMachineDeployment(name, customBody)
    return updated
  }),
)
