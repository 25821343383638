import React, { useCallback } from 'react'
import { IClusterStatus } from './model'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import FontAwesomeIcon from 'pf9-ui-components/built/components/FontAwesomeIcon'
import Tooltip from 'pf9-ui-components/built/elements/tooltip'
import Text from 'pf9-ui-components/built/elements/Text'
import colors from 'core/themes/base/colors'
import Theme from 'core/themes/model'

const iconMap = new Map<IClusterStatus, { icon: string; classes: string }>([
  ['fail', { icon: 'xmark', classes: 'fa-solid' }],
  ['ok', { icon: 'check', classes: 'fa-solid' }],
  ['pause', { icon: 'pause-circle', classes: '' }],
  ['unknown', { icon: 'question-circle', classes: '' }],
  ['error', { icon: 'exclamation-triangle', classes: '' }],
  ['loading', { icon: 'circle-notch', classes: 'fa-spin fa-regular' }],
  ['upgrade', { icon: 'arrow-circle-up', classes: '' }],
  ['degraded', { icon: 'check', classes: '' }],
  ['pending', { icon: 'pause-circle', classes: '' }],
])

export type StatusVariant = 'table' | 'header'

export interface IClusterStatusSpanProps {
  label?: string
  title?: string | JSX.Element
  status: IClusterStatus
  variant?: StatusVariant
  iconStatus?: boolean
  className?: any
  inverseStatus?: boolean
  rootClassName?: any
  iconPosition?: 'right' | 'left'
  showCircleIcon?: boolean
  children?: any
}

const getIconOrBubbleColor = (status: IClusterStatus, theme: Theme) =>
  ({
    ok: colors.teal[100],
    pause: theme.palette.yellow.main,
    fail: theme.palette.pink[800],
    error: theme.palette.pink[800],
    loading: theme.palette.blue[700],
    unknown: theme.palette.grey[150],
    upgrade: theme.palette.orange.main,
    degraded: theme.palette.orange.main,
    pending: theme.palette.orange.main,
  }[status] || theme.palette.grey[150])

const useStyles = makeStyles<Theme, IClusterStatusSpanProps>((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    borderRadius: '4px 0 0 4px',
    backgroundColor: ({ status, inverseStatus }) =>
      inverseStatus ? getIconOrBubbleColor(status, theme) : 'inherit',
  },
  label: {
    width: 50,
  },
  circle: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'minmax(22px, max-content) 1fr',
    whiteSpace: 'nowrap',
    gridGap: 5,
    justifyItems: 'center',
    '&:before': {
      content: '""',
      display: ({ iconStatus, inverseStatus }) =>
        !inverseStatus && iconStatus === true ? 'none' : 'inherit',
      height: ({ variant, inverseStatus }) =>
        variant === 'header' ? 14 : inverseStatus ? 'auto' : 12,
      width: ({ variant, inverseStatus }) =>
        variant === 'header' ? 14 : inverseStatus ? 'auto' : 12,
      borderRadius: ({ inverseStatus }) => (inverseStatus ? '0' : '50%'),
      backgroundColor: ({ status }) => getIconOrBubbleColor(status, theme),
    },
  },
  iconColor: {
    fontSize: ({ variant, showCircleIcon }) =>
      variant === 'header' ? '1rem' : showCircleIcon ? '20px' : theme.typography.body1.fontSize,
    color: ({ status }) => getIconOrBubbleColor(status, theme),
  },
}))

export const ClusterStatusSpan = (props: IClusterStatusSpanProps) => {
  const {
    label,
    title,
    children,
    status = 'unknown',
    iconStatus,
    className,
    rootClassName,
    iconPosition = 'left',
    showCircleIcon = false,
  } = props
  const { circle, label: labelCls, root, iconColor } = useStyles(props)
  const iconData = iconMap.get(status) || { icon: 'question-circle', classes: '' }

  // Dynamically modify classes based on `showCircleIcon` prop
  const iconClass = showCircleIcon
    ? `${iconData.classes} fa-circle-${iconData.icon}`
    : iconData.classes

  const icon =
    showCircleIcon && iconMap.get(status)?.icon === `check`
      ? `circle-${iconData.icon}`
      : iconData.icon

  const renderIcon = useCallback(
    () => <FontAwesomeIcon className={clsx(iconColor, iconClass)}>{icon}</FontAwesomeIcon>,
    [status, iconColor, showCircleIcon],
  )
  return (
    <div className={clsx(root, rootClassName)}>
      {label && <span className={labelCls}>{label}:</span>}
      <Tooltip message={title as string}>
        <Text
          className={clsx(circle, className)}
          variant={'body2'}
          component="div"
          onResize={undefined}
          onResizeCapture={undefined}
          nonce={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          placeholder={undefined}
        >
          {!!iconStatus && iconPosition === 'left' && renderIcon()}
          {children}
          {!!iconStatus && iconPosition === 'right' && renderIcon()}
        </Text>
      </Tooltip>
    </div>
  )
}

export default ClusterStatusSpan
