import React from 'react'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { FieldsForCardsProps } from 'core/components/InfoPanel'
import clsx from 'clsx'

export const TableRow = ({ label, value, labelClassName = '' }) => {
  const classes = useStyles()
  const valueComponent =
    typeof value === 'string' ? (
      <Text variant="caption1" className={classes.value}>
        {value}{' '}
      </Text>
    ) : (
      value
    )
  return (
    <div className={clsx(classes.row, 'property-row')}>
      <Text className={clsx(labelClassName, 'label')} variant="body2">
        {label}
      </Text>
      <Text variant="body2">:</Text>
      {valueComponent}
    </div>
  )
}

interface TableRowsProps {
  fields: FieldsForCardsProps
  labelClassName?: string
}

export const TableRows = ({ fields, labelClassName }: TableRowsProps) => {
  const classes = useStyles()
  return (
    <>
      {Object.entries(fields).map(([key, { value }]) => (
        <TableRow key={key} label={key} value={value} labelClassName={labelClassName} />
      ))}
    </>
  )
}

export const SubsectionHeader = ({ title }) => {
  const classes = useStyles()
  return (
    <div className={classes.subsection}>
      <Text variant="subtitle2">{title}</Text>
      <hr className={classes.divider} />
    </div>
  )
}

export const PropertiesTable = ({ fields }) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.table, 'properties-table')}>
      <TableRows fields={fields} />
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  table: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 12px 1fr',
  },
  value: {
    wordBreak: 'break-all',
  },
  subsection: {
    marginTop: theme.spacing(2),
  },
  divider: {
    height: 1,
    background: theme.components.card.border,
    border: 0,
    margin: theme.spacing(1, 0),
  },
}))
