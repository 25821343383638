import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import DeleteAction from 'core/actions/DeleteAction'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import { trackEvent } from 'utils/tracking'

const { capi } = ApiClient.getInstance()

export const csiDriverActions = ActionsSet.make<DataKeys.CSIDrivers>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.CSIDrivers],
  cacheKey: DataKeys.CSIDrivers,
})

export const listCSIDrivers = csiDriverActions.add(
  new ListAction<DataKeys.CSIDrivers, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get CSI Drivers', params)
    return capi.getCSIDrivers(params.clusterId)
  }),
)

listCSIDrivers.addDependency(DataKeys.PersistentVolumes)
listCSIDrivers.addDependency(DataKeys.PersistentVolumeClaims)

export const deleteCSIDrivers = csiDriverActions.add(
  new DeleteAction<DataKeys.CSIDrivers, { clusterId: string; name: string; id: string }>(
    async ({ clusterId, name, id }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete CSI driver', { id, clusterId, name })
      await capi.deleteCSIDriver(clusterId, name)
      trackEvent('Delete CSI Driver', { clusterId, name, id })
    },
  ),
)
