import ApiClient from 'api-client/ApiClient'
import { useAppSelector } from 'app/store'
import { clientActions } from 'core/client/clientReducers'
import DocumentMeta from 'core/components/DocumentMeta'
import OverviewActions from 'core/elements/overview/OverviewActions'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import useListAction from 'core/hooks/useListAction'
import useUpdateAction from 'core/hooks/useUpdateAction'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { routes } from 'core/utils/routes'
import jsYaml from 'js-yaml'
import { K8sPluginGlobalPrefs } from 'k8s/model'
import { isEmpty } from 'ramda'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import useReactRouter from 'use-react-router'
import { pathStrOr } from 'utils/fp'
import EntityYamlPage from '../../../common/entity/entity-yaml-page'
import DeletePersistentVolumeClaimDialog from '../DeletePersistentVolumeClaimDialog'
import { IPersistentVolumeClaimDetailsPageTabs } from '../model'
import { listPersistentVolumeClaims, updatePersistentVolumeClaim } from '../new-actions'
import { persistentVolumeClaimsSelector } from '../selectors'
import Overview from './Overview'

const { capi } = ApiClient.getInstance()

const actions = {
  rightActions: [
    {
      label: 'Delete',
      icon: 'trash-alt',
      DialogComponent: DeletePersistentVolumeClaimDialog,
    },
  ],
}

export default function PersistentVolumeClaimDetailsPage() {
  const dispatch = useDispatch()
  const { match } = useReactRouter()
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences<K8sPluginGlobalPrefs>(
    'k8sPluginGlobalParams',
  )

  const clusterId = k8sPluginGlobalPerfs?.clusterId || ''

  const { id } = match.params
  const { loading } = useListAction(listPersistentVolumeClaims, {
    params: {
      clusterId,
    },
  })

  const persistentVolumeClaims = useAppSelector(persistentVolumeClaimsSelector)
  const persistentVolumeClaim = useMemo(
    () => persistentVolumeClaims.find((plugin) => plugin.id === id),
    [id, persistentVolumeClaims],
  )

  const { update, updating } = useUpdateAction(updatePersistentVolumeClaim)

  useEffect(() => {
    dispatch(
      clientActions.updateBreadcrumbParams({
        clusterId: persistentVolumeClaim?.clusterName || clusterId,
        id: persistentVolumeClaim?.name || id,
      }),
    )
    return () => {
      dispatch(clientActions.resetBreadcrumbParams())
    }
  }, [persistentVolumeClaim?.clusterName, persistentVolumeClaim?.name, id, clusterId])

  const getPersistentVolumeClaimYaml = useCallback(async () => {
    if (isEmpty(persistentVolumeClaim)) return undefined
    return capi.getPersistentVolumeClaim(
      persistentVolumeClaim.clusterId,
      persistentVolumeClaim.namespace,
      persistentVolumeClaim.name,
    )
  }, [persistentVolumeClaim])

  const handlePersistentVolumeClaimUpdate = async (yaml) => {
    const body = jsYaml.load(yaml)
    const namespace = pathStrOr('', 'metadata.namespace', body)
    const name = pathStrOr('', 'metadata.name', body)
    await update({
      id,
      clusterId,
      namespace,
      name,
      body,
    })
  }

  return (
    <>
      <DocumentMeta title="Persistent Volume Claim" breadcrumbs />
      <div>
        <OverviewActions actions={actions} entity={persistentVolumeClaim} />
      </div>
      <Tabs route={routes.kubernetes.resources.persistentVolumeClaims.details}>
        <Tab label="Overview" value={IPersistentVolumeClaimDetailsPageTabs.Overview}>
          <Overview persistentVolumeClaim={persistentVolumeClaim} loading={loading} />
        </Tab>
        <Tab label="YAML" value={IPersistentVolumeClaimDetailsPageTabs.Yaml}>
          <EntityYamlPage
            entityType="Persistent Volume Claim"
            entityName={persistentVolumeClaim?.name}
            getYamlFn={getPersistentVolumeClaimYaml}
            handleUpdate={handlePersistentVolumeClaimUpdate}
            loading={loading || updating}
          />
        </Tab>
      </Tabs>
    </>
  )
}
